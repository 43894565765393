import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetProjectSettings } from '../../../Service';
import { toast } from 'react-toastify';
import './TestimonialPreview.css';
import TestimonialPreviewItem from './testimonialPreviewItem/TestimonialPreviewItem';

const TestimonialPreview = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ testimonialItem, setTestimonialItem ] = useState(null);
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const testimonialId = match.params.testimonialId;

	useEffect(() => {
		loadProjectSettings();
	}, []);

	const loadProjectSettings = async () => {
		let tempProjectData = {};

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				tempProjectData = settings;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData) {
			tempProjectData = projectData;

			let item = tempProjectData.testimonials.find((x) => x.guid === testimonialId);

			setTestimonialItem(item);
		}
	};

	return (
		<Fragment>
			<TestimonialPreviewItem item={testimonialItem} />
		</Fragment>
	);
};

export default TestimonialPreview;
