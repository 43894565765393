import { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from './../../../StateProvider';
import { actionTypes } from '../../../reducer';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import { DownloadDPImage, OptimizeImage, SaveProjectSettings } from '../../../Service';
import Spinner from '../../spinner/Spinner';
import { useEffect } from 'react';
import { webServiceUrl } from '../../../CommonFunctions';

const ImagesPurchaseDialog = ({ closeDialog, onPublish }) => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ processCompleted, setProcessCompleted ] = useState(false);
	const [ unlicensedImages, setUnlicensedImages ] = useState([]);
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(() => {
		showUnlicensedImages();
	}, []);

	const showUnlicensedImages = () => {
		if (projectData) {
			let tempUnlicensedImages = [];
			let dpKeyword = 'depositphotos.com';

			//hero
			if (
				projectData.hero &&
				projectData.hero.image &&
				projectData.hero.imageData &&
				projectData.hero.image.includes(dpKeyword)
			) {
				if (!projectData.hero.imageData.licenseId) {
					tempUnlicensedImages.push({ id: projectData.hero.imageData.id, url: projectData.hero.image });
				}
			}

			//services
			if (projectData.services && projectData.services.length > 0) {
				for (let i = 0; i < projectData.services.length; i++) {
					let service = projectData.services[i];

					if (
						service &&
						service.heroImage &&
						service.heroImageData &&
						service.heroImage.includes(dpKeyword) &&
						!tempUnlicensedImages.includes(service.heroImage)
					) {
						if (!service.heroImageData.licenseId) {
							tempUnlicensedImages.push({ id: service.heroImageData.id, url: service.heroImage });
						}
					}

					if (
						service &&
						service.feat &&
						service.feat.image &&
						service.feat.imageData &&
						service.feat.image.includes(dpKeyword) &&
						!tempUnlicensedImages.includes(service.feat.image)
					) {
						if (!service.feat.image.licenseId) {
							tempUnlicensedImages.push({ id: service.feat.imageData.id, url: service.feat.image });
						}
					}
				}
			}

			//blog posts
			if (projectData.blogPosts && projectData.blogPosts.length > 0) {
				for (let i = 0; i < projectData.blogPosts.length; i++) {
					let blogpost = projectData.blogPosts[i];

					if (
						blogpost &&
						blogpost.image &&
						blogpost.imageData &&
						blogpost.image.includes(dpKeyword) &&
						!tempUnlicensedImages.includes(blogpost.image)
					) {
						if (!blogpost.imageData.licenseId) {
							tempUnlicensedImages.push({ id: blogpost.imageData.id, url: blogpost.image });
						}
					}
				}
			}

			//gallery
			if (projectData.gallery && projectData.gallery && projectData.gallery.length > 0) {
				for (let i = 0; i < projectData.gallery.length; i++) {
					let galleryImage = projectData.gallery[i];

					if (
						galleryImage &&
						galleryImage.urlBig &&
						galleryImage.urlBig.includes(dpKeyword) &&
						!tempUnlicensedImages.includes(galleryImage.urlBig)
					) {
						if (!galleryImage.licenseId) {
							tempUnlicensedImages.push({ id: galleryImage.dpid, url: galleryImage.urlBig });
						}
					}
				}
			}

			setUnlicensedImages(tempUnlicensedImages);

			for (let i = 0; i < tempUnlicensedImages.length; i++) {
				console.log(tempUnlicensedImages[i]);
			}
		}
	};

	const downloadImages = async () => {
		if (projectData) {
			setIsSaving(true);

			let tempProjectData = projectData;
			let dpKeyword = 'depositphotos.com';

			//hero
			if (
				tempProjectData.hero &&
				tempProjectData.hero.image &&
				tempProjectData.hero.imageData &&
				tempProjectData.hero.image.includes(dpKeyword)
			) {
				if (tempProjectData.hero.imageData.id && !tempProjectData.hero.imageData.licenseId) {
					//purchase the image
					let data = { Key: 'd8f3d4af64bd433fa95f33f24469e317', Id: tempProjectData.hero.imageData.id };
					const response = await DownloadDPImage(data);

					if (response.IsSuccessful) {
						let tempData = JSON.parse(response.Data);

						if (tempData) {
							tempProjectData.hero.imageData.licenseId = tempData.LicenseId;

							//optimize the image
							let dataOptimization = { ImageName: tempProjectData.hero.imageData.id };
							const responseOptimization = await OptimizeImage(dataOptimization);

							if (responseOptimization.IsSuccessful) {
								let optimizedUrl =
									webServiceUrl() +
									'/assets/downloads/depositphotos/optimized/' +
									tempProjectData.hero.imageData.id +
									'.jpg';

								tempProjectData.hero.image = optimizedUrl;
								tempProjectData.hero.imageData.optimized = true;
							}
						}
					}
				}
			}

			//services
			if (tempProjectData.services && tempProjectData.services.length > 0) {
				for (let i = 0; i < tempProjectData.services.length; i++) {
					let service = tempProjectData.services[i];

					if (
						service &&
						service.heroImage &&
						service.heroImageData &&
						service.heroImage.includes(dpKeyword)
					) {
						if (!service.heroImageData.licenseId) {
							//purchase the image
							let data = { Key: 'd8f3d4af64bd433fa95f33f24469e317', Id: service.heroImageData.id };
							const response = true; //await DownloadDPImage(data);

							if (response.IsSuccessful) {
								let tempData = JSON.parse(response.Data);

								if (tempData) {
									service.heroImageData.licenseId = tempData.LicenseId;

									//optimize the image
									let dataOptimization = { ImageName: service.heroImageData.id };
									const responseOptimization = await OptimizeImage(dataOptimization);

									if (responseOptimization.IsSuccessful) {
										let optimizedUrl =
											webServiceUrl() +
											'/assets/downloads/depositphotos/optimized/' +
											service.heroImageData.id +
											'.jpg';

										service.heroImage = optimizedUrl;
										service.heroImageData.optimized = true;
									}
								}
							}
						}
					}

					if (
						service &&
						service.feat.image &&
						service.feat.imageData &&
						service.feat.image.includes(dpKeyword)
					) {
						if (!service.feat.image.licenseId) {
							//purchase the image
							let data = { Key: 'd8f3d4af64bd433fa95f33f24469e317', Id: service.feat.imageData.id };
							const response = true; //await DownloadDPImage(data);

							if (response.IsSuccessful) {
								let tempData = JSON.parse(response.Data);

								if (tempData) {
									service.feat.imageData.licenseId = tempData.LicenseId;

									//optimize the image
									let dataOptimization = { ImageName: service.feat.imageData.id };
									const responseOptimization = await OptimizeImage(dataOptimization);

									if (responseOptimization.IsSuccessful) {
										let optimizedUrl =
											webServiceUrl() +
											'/assets/downloads/depositphotos/optimized/' +
											service.feat.imageData.id +
											'.jpg';

										service.feat.image = optimizedUrl;
										service.feat.imageData.optimized = true;
									}
								}
							}
						}
					}
				}
			}

			//blog posts
			if (tempProjectData.blogPosts && tempProjectData.blogPosts.length > 0) {
				for (let i = 0; i < tempProjectData.blogPosts.length; i++) {
					let blogpost = tempProjectData.blogPosts[i];

					if (blogpost && blogpost.image && blogpost.imageData && blogpost.image.includes(dpKeyword)) {
						if (!blogpost.imageData.licenseId) {
							//purchase the image
							let data = { Key: 'd8f3d4af64bd433fa95f33f24469e317', Id: blogpost.imageData.id };
							const response = true; //await DownloadDPImage(data);

							if (response.IsSuccessful) {
								let tempData = JSON.parse(response.Data);

								if (tempData) {
									blogpost.imageData.licenseId = tempData.LicenseId;

									//optimize the image
									let dataOptimization = { ImageName: blogpost.imageData.id };
									const responseOptimization = await OptimizeImage(dataOptimization);

									if (responseOptimization.IsSuccessful) {
										let optimizedUrl =
											webServiceUrl() +
											'/assets/downloads/depositphotos/optimized/' +
											blogpost.imageData.id +
											'.jpg';

										blogpost.image = optimizedUrl;
										blogpost.imageData.optimized = true;
									}
								}
							}
						}
					}
				}
			}

			//gallery
			if (tempProjectData.gallery && tempProjectData.gallery && tempProjectData.gallery.length > 0) {
				for (let i = 0; i < tempProjectData.gallery.length; i++) {
					let galleryImage = tempProjectData.gallery[i];

					if (
						galleryImage &&
						galleryImage.urlBig &&
						galleryImage.urlBig.includes(dpKeyword) &&
						!galleryImage.licenseId
					) {
						//purchase the image
						let data = { Key: 'd8f3d4af64bd433fa95f33f24469e317', Id: galleryImage.id };
						const response = true; //await DownloadDPImage(data);

						if (response.IsSuccessful) {
							let tempData = JSON.parse(response.Data);

							if (tempData) {
								galleryImage.licenseId = tempData.LicenseId;

								//optimize the image
								let dataOptimization = { ImageName: galleryImage.id };
								const responseOptimization = await OptimizeImage(dataOptimization);

								if (responseOptimization.IsSuccessful) {
									let optimizedUrl =
										webServiceUrl() +
										'/assets/downloads/depositphotos/optimized/' +
										galleryImage.id +
										'.jpg';

									galleryImage.image = optimizedUrl;
									galleryImage.optimized = true;
								}
							}
						}
					}
				}
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});

			//save settings
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(tempProjectData);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
			}

			setIsSaving(false);
			setProcessCompleted(true);
		}
	};

	const downloadImagesOLD = async () => {
		if (unlicensedImages && unlicensedImages.length > 0) {
			if (projectData) {
				let tempProjectData = projectData;

				setIsSaving(true);

				for (let i = 0; i < unlicensedImages.length; i++) {
					let image = unlicensedImages[i];

					if (image && image.id) {
						if (i === 0) {
							//purchase the image
							let data = { Key: 'd8f3d4af64bd433fa95f33f24469e317', Id: image.id };
							const response = await DownloadDPImage(data);
							if (response.IsSuccessful) {
								let tempData = JSON.parse(response.Data);

								if (tempData && tempData.LocalPath) {
								}
							}
						}
					}
				}

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});

				setIsSaving(false);
				setProcessCompleted(true);
			} else {
				console.log('projectData is null');
			}
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<form autoComplete="off">
					<ModalDialogContent align="start">
						<h2 className="mb-4">Purchase Images</h2>
						{isSaving ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : processCompleted ? (
							<h5>Process completed successfully!</h5>
						) : (
							<div className="row mb-3 px-2">
								Would you like to purchase the licenses and download the high res images for this
								project?
							</div>
						)}
					</ModalDialogContent>
					<ModalDialogButtons align="start" shaded={true}>
						{!processCompleted && (
							<button
								type="button"
								className="btn btn-primary btn-lg mr-4"
								onClick={downloadImages}
								disabled={isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<span>Yes</span>
									</Fragment>
								) : (
									<Fragment>
										<span>Downloading...</span>
									</Fragment>
								)}
							</button>
						)}
						<button type="button" className="btn btn-outline-primary btn-lg" onClick={closeDialog}>
							{processCompleted ? <span>Close</span> : <span>No</span>}
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default ImagesPurchaseDialog;
