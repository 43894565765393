import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetProjectSettings, SaveProjectSettings, UploadFile } from '../../Service';
import TinyMCEEditor from '../tinyMCEEditor/TinyMCEEditor';
import { aboutUsPreviewCss, webServiceUrl } from '../../CommonFunctions';
import { toast } from 'react-toastify';
import './AboutUsDetails.css';
import FormTitleContainer from '../formTitleContainer/FormTitleContainer';
import ImageSelect from '../imageSelect/ImageSelect';

const AboutUsDetails = () => {
	const [ { projectData, showImageSelect }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ editorText, setEditorText ] = useState('');
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(() => {
		loadProjectSettings();
	}, []);

	useEffect(
		() => {
			if (projectData) {
				let tempProjectData = aboutUsPreviewCss(projectData);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData && projectData.aboutUs) {
			setFormData({
				Title     : projectData.aboutUs.title ? projectData.aboutUs.title : '',
				Content   : projectData.aboutUs.content ? projectData.aboutUs.content : '',
				Completed : projectData.aboutUs.completed ? projectData.aboutUs.completed : false
			});

			setEditorText(projectData.aboutUs.content ? projectData.aboutUs.content : '');
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'Title') {
			tempProjectData.aboutUs.title = value;
		} else if (target === 'Completed') {
			tempProjectData.aboutUs.completed = checked;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});

		if (projectData) {
			tempProjectData = aboutUsPreviewCss(projectData);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;
		tempProjectData.aboutUs.title = formData.Title;
		tempProjectData.aboutUs.content = editorText;
		tempProjectData.aboutUs.completed = formData.Completed;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.aboutUs = tempProjectData.aboutUs;

			//update the json
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');
			}
		}

		setIsSaving(false);
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	const onImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeImageUpload = (e) => {
		e.preventDefault();

		if (projectData.aboutUs.imageUploaded) {
			let tempProjectData = projectData;

			delete tempProjectData.aboutUs.imageUploaded;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const onImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			let data = new FormData();
			data.append('ProjectId', tempProjectData.guid);
			data.append('FileName', 'aboutUs');
			data.append('UploadedFile', file);

			const response = await UploadFile(data);

			if (response.IsSuccessful) {
				let fileUrl = webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

				tempProjectData.aboutUs.imageUploaded = fileUrl;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const handleEditorContentChange = (editorContent) => {
		setEditorText(editorContent);

		if (projectData && projectData.aboutUs) {
			let tempProjectData = projectData;

			tempProjectData.aboutUs.content = editorContent;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const closeAboutUs = () => {
		dispatch({
			type               : actionTypes.SHOW_PROJECT_ABOUT_US,
			projectShowAboutUs : false
		});
	};

	return (
		<Fragment>
			{showImageSelect ? (
				<ImageSelect type="aboutUs" />
			) : (
				<Fragment>
					{formData && (
						<form onSubmit={handleSubmit}>
							<div className="form-fields-wrapper">
								<FormTitleContainer title="About Us Details" />
								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="Title">Title</label>
										<input
											type="text"
											className="form-control"
											name="Title"
											value={formData.Title}
											placeholder="Enter Title"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="Content">Content</label>
										<TinyMCEEditor
											id="editorContent"
											name="Content"
											value={formData.Content}
											onEditorChange={handleEditorContentChange}
										/>
									</div>
								</div>
								<div className="row mb-0 px-2">
									<label htmlFor="Image" className="mb-1">
										Image
									</label>
									<div className="col-10 pe-0">
										<div>
											<button
												onClick={openImages}
												className="btn btn-primary w-100"
												title="Select Image"
											>
												<span>Search</span>
											</button>
										</div>
									</div>
									<div className="col-2">
										{projectData && projectData.aboutUs && projectData.aboutUs.imageUploaded ? (
											<button
												type="button"
												onClick={removeImageUpload}
												className="btn btn-danger w-100"
											>
												<span>
													<i className="fas fa-trash-alt" />
												</span>
											</button>
										) : (
											<button
												type="button"
												onClick={onImageUpload}
												className="btn btn-primary w-100"
											>
												<span>
													<i className="fas fa-cloud-upload-alt" />
												</span>
											</button>
										)}
										<input
											type="file"
											onChange={onImageChange}
											className="d-none"
											id="uploadImage"
										/>
									</div>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div className="row mb-0 px-2">
									<div className="col-10">
										<button type="submit" className="btn btn-primary btn-lg me-3">
											<span>Save</span>
										</button>
										<button
											type="button"
											onClick={closeAboutUs}
											className="btn btn-outline-primary btn-lg me-3"
										>
											<span>Close</span>
										</button>
									</div>
									<div className="col-2 d-flex justify-content-end align-items-center">
										<input
											type="checkbox"
											name="Completed"
											className="form-check-input check-completed"
											defaultChecked={formData.Completed}
											onClick={handleChange}
											title="Is Completed?"
										/>
									</div>
								</div>
							</div>
						</form>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default AboutUsDetails;
