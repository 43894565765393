import { Fragment, useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetProjectSettings, UploadExportedFile } from '../../../Service';
import { createGuid, heroPreviewCss, createSlug } from '../../../CommonFunctions';
import FaviconPreview from '../favicon/FaviconPreview';
import AboutUsPreview from '../aboutUs/AboutUsPreview';
import ImageSVG from '../../imageUtil/ImageSVG';
import DomToImage from 'dom-to-image';
import { toast } from 'react-toastify';
import './ProjectPreview.css';
import TestimonialPreviewItem from '../testimonial/testimonialPreviewItem/TestimonialPreviewItem';
import AboutUsPreviewV3 from '../aboutUs/AboutUsPreviewV3';

const ProjectPreview = (props, ref) => {
	const [ { projectData }, dispatch ] = useStateValue();
	const logoPreview = useRef();
	const serviceHeroImagePreview = useRef();
	const serviceFeatImagePreview = useRef();
	const serviceTabPreview = useRef();
	const blogPostTabPreview = useRef();
	const blogPostImagePreview = useRef();
	const testimonialPreview = useRef();
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	useEffect(() => {
		if (projectData) {
			let tempProjectData = projectData;

			tempProjectData = heroPreviewCss(tempProjectData);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	}, []);

	const loadProjectSettings = async () => {
		let tempProjectData = {};

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				tempProjectData = settings;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const itemReadyToPublish = (itemName, tempProjectData) => {
		let publishItem = false;
		let itemToPublish = tempProjectData.itemsToPublish.find((o) => o.name === itemName);

		if (itemToPublish && itemToPublish.checked === true) {
			publishItem = true;
		}

		return publishItem;
	};

	useImperativeHandle(
		ref,
		() => ({
			async exportImages(isExport) {
				let tempProjectData = {};

				let data = {};
				data.ProjectId = projectId;
				const settings = await GetProjectSettings(data);

				if (settings !== null) {
					tempProjectData = settings;
				}

				if (tempProjectData && tempProjectData.itemsToPublish) {
					let exportContainer = document.getElementById('exportContainer');
					exportContainer.style.width = '100%';
					exportContainer.style.height = '100%';

					let exportContainerHtml = '';

					//favicon
					let favIconToPublish = itemReadyToPublish('FavIcon', tempProjectData);
					//console.log('favIconToPublish 1 :', favIconToPublish);
					if (isExport) {
						favIconToPublish = true;
					}

					if (favIconToPublish) {
						let faviconContainer = document.getElementById('favicon_' + projectId);

						if (faviconContainer) {
							setTimeout(() => {
								DomToImage.toBlob(faviconContainer)
									.then(async function(blob) {
										if (blob) {
											const file = new File([ blob ], 'favicon', { type: blob.type });

											if (file) {
												let data = new FormData();
												data.append('ProjectId', projectId);
												data.append('FileName', 'favicon.png');
												data.append('ExportPath', '');
												data.append('UploadedFile', file);

												const response = await UploadExportedFile(data);

												if (response.IsSuccessful) {
													//console.log('Favicon coverted successfully!');
												} else {
													console.log(
														'Could not convert favicon. Reason: ' + response.Message
													);
												}
											}
										} else {
											console.log('Could not convert favicon. Blob is null');
										}
									})
									.catch(function(error) {
										console.error('Could not convert favicon. ', error);
									});
							}, 500);
						}
					}

					//about us image
					let aboutUsToPublish = itemReadyToPublish('AboutUs', tempProjectData);
					//console.log('aboutUsToPublish 1 :', aboutUsToPublish);
					if (isExport) {
						aboutUsToPublish = true;
					}

					if (aboutUsToPublish) {
						let aboutUsContainer = document.getElementById('aboutUsPreview_' + projectId);

						if (aboutUsContainer) {
							setTimeout(() => {
								DomToImage.toBlob(aboutUsContainer)
									.then(async function(blob) {
										if (blob) {
											const file = new File([ blob ], 'about-us', { type: blob.type });

											if (file) {
												let data = new FormData();
												data.append('ProjectId', projectId);
												data.append('FileName', 'about-us-image.jpg');
												data.append('ExportPath', 'about-us');
												data.append('UploadedFile', file);

												const response = await UploadExportedFile(data);

												if (response.IsSuccessful) {
													//console.log('"about-us" image coverted successfully!');
												} else {
													console.log(
														'Could not convert "about-us" image. Reason: ' +
															response.Message
													);
												}
											}
										} else {
											console.log('Could not convert "about-us" image. Blob is null');
										}
									})
									.catch(function(error) {
										console.error('Could not convert "about-us" image. ', error);
									});
							}, 500);
						}
					}

					//services (build preview html)
					if (tempProjectData.services && tempProjectData.services.length > 0) {
						for (let i = 0; i < tempProjectData.services.length; i++) {
							let service = tempProjectData.services[i];

							if (service && service.feat) {
								let serviceTabContainer = document.getElementById('nav-service-' + service.guid);

								if (serviceTabContainer) {
									serviceTabContainer.classList.add('active');
									serviceTabContainer.classList.add('show');
									serviceTabContainer.style.width = '100%';
									serviceTabContainer.style.height = '100%';

									let serviceTabHtml = serviceTabContainer.outerHTML;

									//change service Id in html to avoid conflicts
									serviceTabHtml = serviceTabHtml.replaceAll(service.guid, service.guid + '_preview');

									exportContainerHtml += serviceTabHtml;
								}
							}
						}
					}

					//blog posts (build preview html)
					if (tempProjectData.blogPosts && tempProjectData.blogPosts.length > 0) {
						for (let i = 0; i < tempProjectData.blogPosts.length; i++) {
							let blogPost = tempProjectData.blogPosts[i];

							if (blogPost && blogPost.image) {
								let blogPostTabContainer = document.getElementById('nav-blogpost-' + blogPost.guid);

								if (blogPostTabContainer) {
									blogPostTabContainer.classList.add('active');
									blogPostTabContainer.classList.add('show');
									blogPostTabContainer.style.width = '100%';
									blogPostTabContainer.style.height = '100%';

									let blogPostTabHtml = blogPostTabContainer.outerHTML;

									//change blogPost Id in html to avoid conflicts
									blogPostTabHtml = blogPostTabHtml.replaceAll(
										blogPost.guid,
										blogPost.guid + '_preview'
									);

									exportContainerHtml += blogPostTabHtml;
								}
							}
						}
					}

					//testimonials (build preview html)
					if (tempProjectData.testimonials && tempProjectData.testimonials.length > 0) {
						for (let i = 0; i < tempProjectData.testimonials.length; i++) {
							let testimonial = tempProjectData.testimonials[i];

							if (testimonial && testimonial.image) {
								let testimonialContainer = document.getElementById(
									'nav-testimonial-' + testimonial.guid
								);

								if (testimonialContainer) {
									testimonialContainer.classList.add('active');
									testimonialContainer.classList.add('show');
									testimonialContainer.style.width = '100%';
									testimonialContainer.style.height = '100%';

									let testimonialHtml = testimonialContainer.outerHTML;

									//change testimonial Id in html to avoid conflicts
									testimonialHtml = testimonialHtml.replaceAll(
										testimonial.guid,
										testimonial.guid + '_preview'
									);

									exportContainerHtml += testimonialHtml;
								}
							}
						}
					}

					exportContainer.innerHTML = exportContainerHtml;

					//services (upload images)
					if (tempProjectData.services && tempProjectData.services.length > 0) {
						tempProjectData.services.map((service, index) => {
							let serviceName = service.name ? service.name : service.guid;
							let serviceSlug = createSlug(serviceName);

							let serviceToPublish = itemReadyToPublish('Service_' + serviceSlug, tempProjectData);
							console.log('Service_' + serviceSlug + '; ', index + '; ', serviceToPublish);
							if (isExport) {
								serviceToPublish = true;
							}

							if (serviceToPublish) {
								let heroImageContainer = document.getElementById(
									'projectServiceHeroPreview_' + service.guid + '_preview'
								);

								if (heroImageContainer) {
									setTimeout(() => {
										DomToImage.toBlob(heroImageContainer)
											.then(async function(blob) {
												if (blob) {
													const file = new File([ blob ], 'service-hero-image', {
														type : blob.type
													});

													if (file) {
														let data = new FormData();
														data.append('ProjectId', projectId);
														data.append('FileName', 'service-hero-' + serviceSlug + '.jpg');
														data.append('ExportPath', 'services/' + serviceName);
														data.append('UploadedFile', file);

														const response = await UploadExportedFile(data);

														if (response.IsSuccessful) {
														}
													}
												}
											})
											.catch(function(error) {
												console.error(
													'Could not convert feat image for service ' + service.guid + '. ',
													error
												);
											});
									}, 500);
								}

								let featImageContainer = document.getElementById(
									'projectServiceFeatPreview_' + service.guid + '_preview'
								);

								if (featImageContainer) {
									setTimeout(() => {
										DomToImage.toBlob(featImageContainer)
											.then(async function(blob) {
												if (blob) {
													const file = new File([ blob ], 'service-feat-image', {
														type : blob.type
													});

													if (file) {
														let data = new FormData();
														data.append('ProjectId', projectId);
														data.append(
															'FileName',
															'service-featured-' + serviceSlug + '.jpg'
														);
														data.append('ExportPath', 'services/' + serviceName);
														data.append('UploadedFile', file);

														const response = await UploadExportedFile(data);

														if (response.IsSuccessful) {
														}
													}
												}
											})
											.catch(function(error) {
												console.error(
													'Could not convert feat image for service ' + service.guid + '. ',
													error
												);
											});
									}, 500);
								}
							}
						});
					}

					//blog posts (upload images)
					if (tempProjectData.blogPosts && tempProjectData.blogPosts.length > 0) {
						tempProjectData.blogPosts.map((blogPost, index) => {
							let blogPostName = blogPost.title ? blogPost.title : blogPost.guid;
							let blogPostSlug = createSlug(blogPostName);

							let blogPostToPublish = itemReadyToPublish('BlogPost_' + blogPostSlug, tempProjectData);
							console.log('BlogPost_' + blogPostSlug + '; ', index + '; ', blogPostToPublish);
							if (isExport) {
								blogPostToPublish = true;
							}

							if (blogPostToPublish) {
								let bpImageContainer = document.getElementById(
									'projectBlogPostPreview_' + blogPost.guid + '_preview'
								);

								if (bpImageContainer) {
									setTimeout(() => {
										DomToImage.toBlob(bpImageContainer)
											.then(async function(blob) {
												if (blob) {
													const file = new File([ blob ], 'blog-post-image', {
														type : blob.type
													});

													if (file) {
														let data = new FormData();
														data.append('ProjectId', projectId);
														data.append(
															'FileName',
															'blogpost-hero-' + blogPostSlug + '.jpg'
														);
														data.append('ExportPath', 'blogposts/' + blogPostName);
														data.append('UploadedFile', file);

														const response = await UploadExportedFile(data);

														if (response.IsSuccessful) {
														}
													}
												}
											})
											.catch(function(error) {
												console.error(
													'Could not convert feat image for blog post ' +
														blogPost.guid +
														'. ',
													error
												);
											});
									}, 500);
								}
							}
						});
					}

					//testimonials (upload images)
					if (tempProjectData.testimonials && tempProjectData.testimonials.length > 0) {
						tempProjectData.testimonials.map((testimonial, index) => {
							let testimonialName = testimonial.title ? testimonial.title : testimonial.guid;
							let testimonialSlug = createSlug(testimonialName);

							let testimonialToPublish = itemReadyToPublish(
								'Testimonial_' + testimonialSlug,
								tempProjectData
							);

							if (isExport) {
								testimonialToPublish = true;
							}

							if (testimonialToPublish) {
								let testimonialImageContainer = document.getElementById(
									'projectTestimonialPreview_' + testimonial.guid + '_preview'
								);

								if (testimonialImageContainer) {
									setTimeout(() => {
										DomToImage.toBlob(testimonialImageContainer)
											.then(async function(blob) {
												if (blob) {
													const file = new File([ blob ], 'testimonial-image', {
														type : blob.type
													});

													if (file) {
														let data = new FormData();
														data.append('ProjectId', projectId);
														data.append(
															'FileName',
															'testimonial-hero-' + testimonialSlug + '.jpg'
														);
														data.append('ExportPath', 'testimonials/' + testimonialName);
														data.append('UploadedFile', file);

														const response = await UploadExportedFile(data);

														if (response.IsSuccessful) {
														}
													}
												}
											})
											.catch(function(error) {
												console.error(
													'Could not convert feat image for testimonial ' +
														testimonial.guid +
														'. ',
													error
												);
											});
									}, 500);
								}
							}
						});
					}
				}
			}
		}),
		[]
	);

	return (
		<Fragment>
			<div className="export-container" id="exportContainer" />
			<nav>
				<div className="nav nav-tabs" id="navTabProject" role="tablist">
					<button
						className="nav-link active"
						id="nav-hero-tab"
						data-bs-toggle="tab"
						data-bs-target="#nav-hero"
						type="button"
						role="tab"
						aria-controls="nav-hero"
						aria-selected="true"
					>
						Hero Section
					</button>
					<button
						className="nav-link"
						id="nav-service-tab"
						data-bs-toggle="tab"
						data-bs-target="#nav-service"
						type="button"
						role="tab"
						aria-controls="nav-service"
						aria-selected="false"
					>
						Services
					</button>
					<button
						className="nav-link"
						id="nav-blogpost-tab"
						data-bs-toggle="tab"
						data-bs-target="#nav-blogpost"
						type="button"
						role="tab"
						aria-controls="nav-blogpost"
						aria-selected="false"
					>
						Blog Posts
					</button>
					<button
						className="nav-link"
						id="nav-testimonial-tab"
						data-bs-toggle="tab"
						data-bs-target="#nav-testimonial"
						type="button"
						role="tab"
						aria-controls="nav-testimonial"
						aria-selected="false"
					>
						Testimonials
					</button>
				</div>
			</nav>
			<div className="tab-content project-tab" id="tabProject">
				<div className="tab-pane fade active show" id="nav-hero" role="tabpanel" aria-labelledby="nav-hero-tab">
					{projectData && (
						<Fragment>
							<div className="project-hero-preview-container">
								<Fragment>
									<div className="container-fluid container-topbar px-0">
										<div className="container">
											<nav className="navbar navbar-expand-lg navbar-light">
												<div className="container position-relative">
													{projectData.logo &&
													projectData.logo.layout && (
														<div className="p-2">
															<div
																className={`project-logo-preview-area ${projectData.logo
																	.layout}`}
																id="projectLogoPreview"
																ref={logoPreview}
															>
																<div
																	className="icon-preview"
																	style={
																		projectData.logo.iconUploaded ? (
																			{ padding: 0 }
																		) : (
																			projectData.logo.cssStyleIcon
																		)
																	}
																>
																	{projectData.logo.layout === 'horizontal' ? (
																		<Fragment>
																			{projectData.logo.iconUploaded ? (
																				<img
																					src={`${projectData.logo
																						.iconUploaded}?v=${createGuid()}`}
																					style={{
																						filter : 'unset'
																					}}
																					crossOrigin="anonymous"
																				/>
																			) : projectData.logo.horizontal
																				.iconPreview ? (
																				<img
																					src={`${projectData.logo.horizontal
																						.iconPreview}?v=${createGuid()}`}
																					style={
																						projectData.logo
																							.cssStyleIconSize
																					}
																					crossOrigin="anonymous"
																				/>
																			) : projectData.logo.horizontal.icon ? (
																				<img
																					src={`${projectData.logo.horizontal
																						.icon}?v=${createGuid()}`}
																					style={
																						projectData.logo
																							.cssStyleIconSize
																					}
																					crossOrigin="anonymous"
																				/>
																			) : (
																				''
																			)}
																		</Fragment>
																	) : (
																		<Fragment>
																			{projectData.logo.iconUploaded ? (
																				<img
																					src={`${projectData.logo
																						.iconUploaded}?v=${createGuid()}`}
																					style={{
																						filter : 'unset'
																					}}
																					crossOrigin="anonymous"
																				/>
																			) : projectData.logo.vertical
																				.iconPreview ? (
																				<img
																					src={`${projectData.logo.vertical
																						.iconPreview}?v=${createGuid()}`}
																					style={
																						projectData.logo
																							.cssStyleIconSize
																					}
																					crossOrigin="anonymous"
																				/>
																			) : projectData.logo.vertical.icon ? (
																				<img
																					src={`${projectData.logo.vertical
																						.icon}?v=${createGuid()}`}
																					style={
																						projectData.logo
																							.cssStyleIconSize
																					}
																					crossOrigin="anonymous"
																				/>
																			) : (
																				''
																			)}
																		</Fragment>
																	)}
																</div>
																{!projectData.logo.iconUploaded && (
																	<Fragment>
																		{projectData.logo.fontTypePreview ? (
																			<div
																				className="text-preview"
																				style={{
																					...projectData.logo.cssStyleText,
																					...{
																						fontFamily :
																							projectData.logo
																								.fontTypePreview
																					}
																				}}
																			>
																				{projectData.company}
																			</div>
																		) : (
																			<div
																				className="text-preview"
																				style={projectData.logo.cssStyleText}
																			>
																				{projectData.company}
																			</div>
																		)}
																	</Fragment>
																)}
																<ImageSVG
																	id="logoColorTransformFilter"
																	hexColor={projectData.primaryColor}
																/>
															</div>
														</div>
													)}
												</div>
											</nav>
										</div>
									</div>
									{projectData.hero && (
										<div
											className="container-fluid project-section-hero backdrop px-0 py-3"
											style={
												projectData.hero.imageUploaded ? (
													{
														backgroundImage : `url(${projectData.hero.imagePreview
															? projectData.hero.imagePreview
															: projectData.hero.imageUploaded})`
													}
												) : (
													{
														backgroundImage : `url(${projectData.hero.imagePreview
															? projectData.hero.imagePreview
															: projectData.hero.image})`
													}
												)
											}
										>
											<div className="container">
												<div className="row">
													<div className="col-sm-12 align-self-center text-center">
														{projectData.hero.headlineFontTypePreview ? (
															<h1
																className="headline"
																style={{
																	...projectData.hero.cssHeadlineStyle,
																	...{
																		fontFamily :
																			projectData.hero.headlineFontTypePreview
																	}
																}}
																dangerouslySetInnerHTML={{
																	__html : projectData.hero.headline
																}}
															/>
														) : (
															<h1
																className="headline"
																style={projectData.hero.cssHeadlineStyle}
																dangerouslySetInnerHTML={{
																	__html : projectData.hero.headline
																}}
															/>
														)}

														<div className="hero-buttons text-center">
															<a
																className="btn btn-lg btn-primary cta-button"
																style={{
																	backgroundColor : projectData.primaryColor
																		? projectData.primaryColor
																		: '#000000'
																}}
															>
																<span>
																	<i className="fas fa-phone-square-alt me-2" aria-hidden="true" />Call:
																	(800) 555-5555
																</span>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
								</Fragment>
							</div>
						</Fragment>
					)}
				</div>
				<div className="tab-pane fade" id="nav-service" role="tabpanel" aria-labelledby="nav-service-tab">
					{projectData &&
					projectData.services &&
					projectData.services.length > 0 && (
						<Fragment>
							<nav>
								<div className="nav nav-tabs mt-3" id="navTabServices" role="tablist">
									{projectData.services.map((serviceItem, index) => (
										<button
											key={serviceItem.guid}
											className={`nav-link ${index === 0 ? 'active' : ''}`}
											id={`nav-service-tab-${serviceItem.guid}`}
											data-bs-toggle="tab"
											data-bs-target={`#nav-service-${serviceItem.guid}`}
											type="button"
											role="tab"
											aria-controls={`nav-service-${serviceItem.guid}`}
											aria-selected={`${index === 0 ? 'true' : 'false'}`}
										>
											{serviceItem.name}
										</button>
									))}
								</div>
							</nav>
							<div className="tab-content project-services-tab" id="tabServices">
								{projectData.services.map((serviceItem, index) => (
									<div
										key={serviceItem.guid}
										className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
										id={`nav-service-${serviceItem.guid}`}
										role="tabpanel"
										aria-labelledby={`nav-service-tab-${serviceItem.guid}`}
										ref={serviceTabPreview}
									>
										<div className="project-service-preview-container px-5 pt-4 mt-4 h-100">
											<div
												className="container-fluid project-section-service backdrop px-0 py-3 mb-4"
												id={`projectServiceHeroPreview_${serviceItem.guid}`}
												ref={serviceHeroImagePreview}
												style={
													serviceItem.inheritHeroImage &&
													serviceItem.inheritHeroImage === true &&
													projectData &&
													projectData.hero ? projectData.hero.imageUploaded ? (
														{
															backgroundImage : `url(${projectData.hero.imageUploaded})`
														}
													) : (
														{
															backgroundImage : `url(${projectData.hero.image})`
														}
													) : serviceItem.heroImageUploaded ? (
														{
															backgroundImage : `url(${serviceItem.heroImagePreview
																? serviceItem.heroImagePreview
																: serviceItem.heroImageUploaded})`
														}
													) : (
														{
															backgroundImage : `url(${serviceItem.heroImagePreview
																? serviceItem.heroImagePreview
																: serviceItem.heroImage})`
														}
													)
												}
											>
												<div className="container">
													<div className="row">
														<div className="col-sm-12 align-self-center text-center">
															{serviceItem.title && (
																<h1
																	className="headline"
																	dangerouslySetInnerHTML={{
																		__html : serviceItem.title
																	}}
																/>
															)}
														</div>
													</div>
												</div>
											</div>
											<div className="container-fluid project-section-service section-feat-image p-0">
												<div
													className="feat-image-wrapper"
													id={`projectServiceFeatPreview_${serviceItem.guid}`}
													ref={serviceFeatImagePreview}
													style={
														serviceItem.feat &&
														(serviceItem.feat.inheritHeroImage &&
														serviceItem.feat.inheritHeroImage === true &&
														projectData &&
														projectData.hero
															? projectData.hero.imageUploaded
																? {
																		...{
																			backgroundImage : `url(${projectData.hero
																				.imageUploaded})`
																		},
																		...serviceItem.feat.cssStyleOverlay
																	}
																: {
																		...{
																			backgroundImage : `url(${projectData.hero
																				.image})`
																		},
																		...serviceItem.feat.cssStyleOverlay
																	}
															: serviceItem.feat.imageUploaded
																? {
																		...{
																			backgroundImage : `url(${serviceItem.feat
																				.imagePreview
																				? serviceItem.feat.imagePreview
																				: serviceItem.feat.imageUploaded})`
																		},
																		...serviceItem.feat.cssStyleOverlay
																	}
																: {
																		...{
																			backgroundImage : `url(${serviceItem.feat
																				.imagePreview
																				? serviceItem.feat.imagePreview
																				: serviceItem.feat.image})`
																		},
																		...serviceItem.feat.cssStyleOverlay
																	})
													}
												>
													{serviceItem.feat &&
													serviceItem.feat.showIcon && (
														<div
															className="icon-preview"
															style={serviceItem.feat.cssStyleIcon}
														>
															{serviceItem.feat.iconPreview ? (
																<img
																	src={`${serviceItem.feat
																		.iconPreview}?v=${createGuid()}`}
																	style={serviceItem.feat.cssStyleIconSize}
																	crossOrigin="anonymous"
																/>
															) : serviceItem.feat.icon ? (
																<img
																	src={`${serviceItem.feat.icon}?v=${createGuid()}`}
																	style={serviceItem.feat.cssStyleIconSize}
																	crossOrigin="anonymous"
																/>
															) : (
																''
															)}
															<ImageSVG
																id={`serviceColorTransformFilter_${serviceItem.guid}`}
																hexColor="#ffffff"
															/>
														</div>
													)}
												</div>
											</div>
											<div
												className="container-fluid px-2 py-3 mt-3 w-50"
												dangerouslySetInnerHTML={{
													__html : serviceItem.contentPreview
														? serviceItem.contentPreview
														: serviceItem.content
												}}
											/>
										</div>
									</div>
								))}
							</div>
						</Fragment>
					)}
				</div>
				<div className="tab-pane fade" id="nav-blogpost" role="tabpanel" aria-labelledby="nav-blogpost-tab">
					{projectData &&
					projectData.blogPosts &&
					projectData.blogPosts.length > 0 && (
						<Fragment>
							<nav>
								<div className="nav nav-tabs mt-3" id="navTabBlogPosts" role="tablist">
									{projectData.blogPosts.map((blogPostItem, index) => (
										<button
											key={blogPostItem.guid}
											className={`nav-link ${index === 0 ? 'active' : ''}`}
											id={`nav-blogpost-tab-${blogPostItem.guid}`}
											data-bs-toggle="tab"
											data-bs-target={`#nav-blogpost-${blogPostItem.guid}`}
											type="button"
											role="tab"
											aria-controls={`nav-blogpost-${blogPostItem.guid}`}
											aria-selected={`${index === 0 ? 'true' : 'false'}`}
										>
											{blogPostItem.title}
										</button>
									))}
								</div>
							</nav>
							<div className="tab-content project-blogposts-tab" id="tabBlogPosts">
								{projectData.blogPosts.map((blogPostItem, index) => (
									<div
										key={blogPostItem.guid}
										className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
										id={`nav-blogpost-${blogPostItem.guid}`}
										role="tabpanel"
										aria-labelledby={`nav-blogpost-tab-${blogPostItem.guid}`}
										ref={blogPostTabPreview}
									>
										<div className="project-blogpost-preview-container px-5 pt-4 mt-4 h-100">
											<div
												className="container-fluid project-section-blogpost backdrop px-0 py-3 mb-3"
												id={`projectBlogPostPreview_${blogPostItem.guid}`}
												ref={blogPostImagePreview}
												style={
													blogPostItem.inheritHeroImage &&
													blogPostItem.inheritHeroImage === true &&
													projectData &&
													projectData.hero ? projectData.hero.imageUploaded ? (
														{
															backgroundImage : `url(${projectData.hero.imageUploaded})`
														}
													) : (
														{
															backgroundImage : `url(${projectData.hero.image})`
														}
													) : blogPostItem.imageUploaded ? (
														{
															backgroundImage : `url(${blogPostItem.imagePreview
																? blogPostItem.imagePreview
																: blogPostItem.imageUploaded})`
														}
													) : (
														{
															backgroundImage : `url(${blogPostItem.imagePreview
																? blogPostItem.imagePreview
																: blogPostItem.image})`
														}
													)
												}
											>
												<div className="container">
													<div className="row">
														<div className="col-sm-12 align-self-center text-center">
															{blogPostItem.title && (
																<h1
																	className="headline"
																	dangerouslySetInnerHTML={{
																		__html : blogPostItem.title
																	}}
																/>
															)}
														</div>
													</div>
												</div>
											</div>
											<div
												className="container-fluid px-2 py-3 w-50"
												dangerouslySetInnerHTML={{
													__html : blogPostItem.contentPreview
														? blogPostItem.contentPreview
														: blogPostItem.content
												}}
											/>
										</div>
									</div>
								))}
							</div>
						</Fragment>
					)}
				</div>
				<div
					className="tab-pane fade"
					id="nav-testimonial"
					role="tabpanel"
					aria-labelledby="nav-testimonial-tab"
				>
					{projectData &&
					projectData.testimonials &&
					projectData.testimonials.length > 0 && (
						<Fragment>
							<nav>
								<div className="nav nav-tabs mt-3" id="navTabTestimonials" role="tablist">
									{projectData.testimonials.map((testimonialItem, index) => (
										<button
											key={testimonialItem.guid}
											className={`nav-link ${index === 0 ? 'active' : ''}`}
											id={`nav-testimonial-tab-${testimonialItem.guid}`}
											data-bs-toggle="tab"
											data-bs-target={`#nav-testimonial-${testimonialItem.guid}`}
											type="button"
											role="tab"
											aria-controls={`nav-testimonial-${testimonialItem.guid}`}
											aria-selected={`${index === 0 ? 'true' : 'false'}`}
										>
											{testimonialItem.title}
										</button>
									))}
								</div>
							</nav>
							<div className="tab-content project-testimonials-tab" id="tabTestimonials">
								{projectData.testimonials.map((testimonialItem, index) => (
									<div
										key={testimonialItem.guid}
										className={`tab-pane fade ${index === 0 ? 'active show' : ''}`}
										id={`nav-testimonial-${testimonialItem.guid}`}
										role="tabpanel"
										aria-labelledby={`nav-testimonial-tab-${testimonialItem.guid}`}
										ref={testimonialPreview}
									>
										<div className="project-testimonial-preview-container px-5 pt-4 mt-4 h-100">
											<TestimonialPreviewItem item={testimonialItem} />
										</div>
									</div>
								))}
							</div>
						</Fragment>
					)}
				</div>
			</div>
			<div className="export-container">
				<FaviconPreview />
			</div>
			<div className="export-container about-us">
				<AboutUsPreview iconColor="#ffffff" />
			</div>
		</Fragment>
	);
};

export default forwardRef(ProjectPreview);
