import { Fragment, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { toast } from 'react-toastify';
import { GetProjectSettings, SaveProjectSettings } from '../../../Service';
import { UploadFile } from '../../../Service';
import TestimonialPreview from '../../preview/testimonial/TestimonialPreview';
import './TestimonialDetails.css';
import { webServiceUrl } from '../../../CommonFunctions';
import ImageSelect from '../../imageSelect/ImageSelect';
import FormTitleContainer from '../../formTitleContainer/FormTitleContainer';

const TestimonialDetails = () => {
	const [ { projectData, showImageSelect }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ testimonialItem, setTestimonialItem ] = useState(null);
	const [ formData, setFormData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const testimonialId = match.params.testimonialId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ testimonialId ]
	);

	useEffect(
		() => {
			if (projectData && projectData.testimonials) {
				let item = projectData.testimonials.find((x) => x.guid === testimonialId);

				setTestimonialItem(item);

				setFormData({
					Title     : item.title,
					Stars     : item.stars,
					Message   : item.message,
					Position  : item.position,
					Location  : item.location,
					Completed : item.completed
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.testimonials) {
			let item = tempProjectData.testimonials.find((x) => x.guid === testimonialId);

			if (target === 'Title') {
				item.title = value;
			} else if (target === 'Message') {
				item.message = value;
			} else if (target === 'Stars') {
				item.stars = value;
			} else if (target === 'Position') {
				item.position = value;
			} else if (target === 'Location') {
				item.location = checked;
			} else if (target === 'Completed') {
				item.completed = checked;
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = {};

		if (projectData) {
			tempProjectData = projectData;
		} else {
			let data = {};
			data.ProjectId = projectId;
			tempProjectData = await GetProjectSettings(data);
		}

		if (tempProjectData !== null) {
			if (tempProjectData && tempProjectData.testimonials) {
				let item = tempProjectData.testimonials.find((x) => x.guid === testimonialId);

				if (item) {
					item.title = formData.Title;
					item.name = formData.Title;
					item.message = formData.Message;
					item.stars = formData.Stars;
					item.position = formData.Position;
					item.location = formData.Location;
					item.completed = formData.Completed;

					//update the json
					let data = {};
					data.ProjectId = projectId;
					data.ProjectJson = JSON.stringify(tempProjectData);

					const serviceResponse = await SaveProjectSettings(data);

					if (serviceResponse.IsSuccessful) {
						toast.success('Settings updated successfully.');
					}
				}
			}
		}

		setIsSaving(false);
	};

	const onImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeImageUpload = (e) => {
		e.preventDefault();

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.testimonials) {
			let item = tempProjectData.testimonials.find((x) => x.guid === testimonialId);

			if (item && item.imageUploaded) {
				delete item.imageUploaded;

				setTestimonialItem(item);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const onImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.testimonials) {
				let item = tempProjectData.testimonials.find((x) => x.guid === testimonialId);

				if (item) {
					let data = new FormData();
					data.append('ProjectId', tempProjectData.guid);
					data.append('FileName', 'testimonial-' + tempProjectData.guid);
					data.append('UploadedFile', file);

					const response = await UploadFile(data);

					if (response.IsSuccessful) {
						let imageUrl =
							webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

						item.imageUploaded = imageUrl;

						delete item.image;
						delete item.imagePreview;

						dispatch({
							type        : actionTypes.SET_PROJECT_DATA,
							projectData : tempProjectData
						});
					}
				}
			}
		}
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	return (
		<Fragment>
			<div className="left-container">
				{formData && (
					<Fragment>
						{showImageSelect ? (
							<ImageSelect type="testimonial" />
						) : (
							<Fragment>
								<form onSubmit={handleSubmit}>
									<div className="form-fields-wrapper">
										<FormTitleContainer title="Testimonial Details" />
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Title">Name</label>
												<input
													type="text"
													className="form-control"
													name="Title"
													value={formData.Title}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											{/* <div className="col-10 pe-0">
												<button
													type="button"
													onClick={openImages}
													className="btn btn-primary w-100"
													title="Select Image"
												>
													<span>Image</span>
												</button>
											</div> */}
											<div className="col-12">
												{testimonialItem && testimonialItem.imageUploaded ? (
													<button
														type="button"
														onClick={removeImageUpload}
														className="btn btn-danger w-100"
													>
														<span>
															<i className="fas fa-trash-alt" />
														</span>
													</button>
												) : (
													<button
														type="button"
														onClick={onImageUpload}
														className="btn btn-primary w-100"
													>
														<span>
															<i className="fas fa-cloud-upload-alt" />
														</span>
													</button>
												)}
												<input
													type="file"
													onChange={onImageChange}
													className="d-none"
													id="uploadImage"
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<label htmlFor="Stars">Number Of Stars</label>
											<div className="col-6">
												<input
													type="range"
													className="form-range mt-2"
													name="Stars"
													min="1"
													max="5"
													value={formData.Stars}
													onChange={handleChange}
												/>
											</div>
											<div className="col-1 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="StarsValue"
													value={formData.Stars}
													disabled
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Message">Message</label>
												<textarea
													className="form-control"
													name="Message"
													value={formData.Message}
													placeholder="Enter message"
													onChange={handleChange}
													rows="3"
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Position">Job Title</label>
												<input
													type="text"
													className="form-control"
													name="Position"
													value={formData.Position}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Location">Location</label>
												<input
													type="text"
													className="form-control"
													name="Location"
													value={formData.Location}
													onChange={handleChange}
												/>
											</div>
										</div>
									</div>
									<div className="form-buttons-wrapper">
										<div className="row mb-0 px-2">
											<div className="col-10">
												<button type="submit" className="btn btn-primary btn-lg me-3">
													Save
												</button>
												<Link
													to={`/project/${projectId}`}
													className="btn btn-outline-primary btn-lg me-3"
												>
													<span>Close</span>
												</Link>
											</div>
											<div className="col-2 d-flex justify-content-end align-items-center">
												<input
													type="checkbox"
													name="Completed"
													className="form-check-input check-completed"
													defaultChecked={formData.Completed}
													onClick={handleChange}
													title="Is Testimonial Completed?"
												/>
											</div>
										</div>
									</div>
								</form>
							</Fragment>
						)}
					</Fragment>
				)}
			</div>
			<div className="right-container">
				<TestimonialPreview />
			</div>
		</Fragment>
	);
};

export default TestimonialDetails;
