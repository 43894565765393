import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetProjectSettings, SaveProjectSettings } from '../../Service';
import { toast } from 'react-toastify';
import './CompanyDetails.css';
import FormTitleContainer from '../formTitleContainer/FormTitleContainer';

const CompanyDetails = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	useEffect(
		() => {
			if (projectData) {
				let tempProjectData = projectData;

				if (!tempProjectData.companyInfo) {
					tempProjectData.companyInfo = {};
				}

				setFormData({
					CompanyName : tempProjectData.company,
					Address     : tempProjectData.companyInfo.address,
					City        : tempProjectData.companyInfo.city,
					State       : tempProjectData.companyInfo.state,
					PostalCode  : tempProjectData.companyInfo.postalCode,
					ServiceArea : tempProjectData.companyInfo.serviceArea,
					Phone       : tempProjectData.companyInfo.phone,
					Fax         : tempProjectData.companyInfo.fax,
					Completed   : tempProjectData.companyInfo.completed
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'CompanyName') {
			tempProjectData.company = value;
		} else if (target === 'Address') {
			tempProjectData.companyInfo.address = value;
		} else if (target === 'City') {
			tempProjectData.companyInfo.city = value;
		} else if (target === 'State') {
			tempProjectData.companyInfo.state = value;
		} else if (target === 'PostalCode') {
			tempProjectData.companyInfo.postalCode = value;
		} else if (target === 'ServiceArea') {
			tempProjectData.companyInfo.serviceArea = value;
		} else if (target === 'Phone') {
			tempProjectData.companyInfo.phone = value;
		} else if (target === 'Fax') {
			tempProjectData.companyInfo.fax = value;
		} else if (target === 'Completed') {
			tempProjectData.companyInfo.completed = checked;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;
		tempProjectData.company = formData.CompanyName;

		if (!tempProjectData.companyInfo) {
			tempProjectData.companyInfo = {};
		}

		tempProjectData.companyInfo.name = formData.CompanyName;
		tempProjectData.companyInfo.address = formData.Address;
		tempProjectData.companyInfo.city = formData.City;
		tempProjectData.companyInfo.state = formData.State;
		tempProjectData.companyInfo.postalCode = formData.PostalCode;
		tempProjectData.companyInfo.serviceArea = formData.ServiceArea;
		tempProjectData.companyInfo.phone = formData.Phone;
		tempProjectData.companyInfo.fax = formData.Fax;
		tempProjectData.companyInfo.completed = formData.Completed;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.company = tempProjectData.company;
			settings.companyInfo = tempProjectData.companyInfo;

			//update the json
			data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}

		setIsSaving(false);
	};

	const closeCompanyDetailsPage = (e) => {
		dispatch({
			type                   : actionTypes.SHOW_PROJECT_COMPANY_INFO,
			projectShowCompanyInfo : false
		});
	};

	return (
		<Fragment>
			{formData && (
				<form onSubmit={handleSubmit}>
					<div className="form-fields-wrapper">
						<FormTitleContainer title="Company Details" />
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="CompanyName">Company Name</label>
								<input
									type="text"
									className="form-control"
									name="CompanyName"
									value={formData.CompanyName}
									placeholder="Enter Company Name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="Address">Address</label>
								<input
									type="text"
									className="form-control"
									name="Address"
									value={formData.Address}
									placeholder="Enter Address"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="City">City</label>
								<input
									type="text"
									className="form-control"
									name="City"
									value={formData.City}
									placeholder="Enter City"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="State">State</label>
								<input
									type="text"
									className="form-control"
									name="State"
									value={formData.State}
									placeholder="Enter State"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="PostalCode">Postal Code</label>
								<input
									type="text"
									className="form-control"
									name="PostalCode"
									value={formData.PostalCode}
									placeholder="Enter PostalCode"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="ServiceArea">Service Area</label>
								<input
									type="text"
									className="form-control"
									name="ServiceArea"
									value={formData.ServiceArea}
									placeholder="Enter ServiceArea"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="Phone">Phone Number</label>
								<input
									type="text"
									className="form-control"
									name="Phone"
									value={formData.Phone}
									placeholder="Enter Phone Number"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="Fax">Fax Number</label>
								<input
									type="text"
									className="form-control"
									name="Fax"
									value={formData.Fax}
									placeholder="Enter Fax Number"
									onChange={handleChange}
								/>
							</div>
						</div>
					</div>
					<div className="form-buttons-wrapper">
						<div className="row mb-0 px-2">
							<div className="col-10">
								<button type="submit" className="btn btn-primary btn-lg me-3">
									<span>Save</span>
								</button>
								<button
									type="button"
									className="btn btn-outline-primary btn-lg me-3"
									onClick={closeCompanyDetailsPage}
								>
									<span>Close</span>
								</button>
							</div>
							<div className="col-2 d-flex justify-content-end align-items-center">
								<input
									type="checkbox"
									name="Completed"
									className="form-check-input check-completed"
									defaultChecked={formData.Completed}
									onClick={handleChange}
									title="Completed?"
								/>
							</div>
						</div>
					</div>
				</form>
			)}
		</Fragment>
	);
};

export default CompanyDetails;
