import { useEffect, useState } from 'react';
import './ModalDialogButtons.css';

const ModalDialogButtons = ({ children, align, shaded }) => {
	const [ alignment, setAlignment ] = useState('left');
	const [ shadedClass, setShadedClass ] = useState('');

	useEffect(
		() => {
			if (align) {
				setAlignment(align);
			}
		},
		[ align ]
	);

	useEffect(
		() => {
			if (shaded) {
				setShadedClass('shaded');
			} else {
				setShadedClass('');
			}
		},
		[ shaded ]
	);

	return <div className={`modal-dialog-buttons text-${alignment} ${shadedClass}`}>{children}</div>;
};

export default ModalDialogButtons;
