import { Fragment, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import NoDataMessage from '../../noDataMessage/NoDataMessage';
import AddServiceDialog from '../serviceNew/AddServiceDialog';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import './ServicesList.css';

const ServicesList = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);
	const [ servicesList, setServicesList ] = useState([]);
	const [ addServiceDialog, setAddServiceDialog ] = useState(false);
	const [ showServiceConfirmDelete, setShowServiceConfirmDelete ] = useState(false);
	const [ serviceIdForDelete, setServiceIdForDelete ] = useState(null);
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			getServices();
		},
		[ projectData ]
	);

	const getServices = async () => {
		if (projectData && projectData.services) {
			let tempServicesList = projectData.services;
			setServicesList(tempServicesList);
		}

		setIsLoading(false);
	};

	const showAddServiceDialog = () => {
		setAddServiceDialog(true);
	};

	const hideAddServiceDialog = () => {
		setAddServiceDialog(false);
	};

	const showServiceDeleteConfirmation = (e) => {
		setShowServiceConfirmDelete(true);

		let selectedServiceId = e.currentTarget.dataset.key;
		setServiceIdForDelete(selectedServiceId);
	};

	const hideServiceDeleteConfirmation = (e) => {
		setShowServiceConfirmDelete(false);
		setServiceIdForDelete(null);
	};

	const deleteService = () => {
		if (projectData && projectData.services && serviceIdForDelete) {
			setShowServiceConfirmDelete(false);
			setIsSaving(true);
			setIsDeleting(true);

			let tempProjectData = projectData;

			tempProjectData.services = tempProjectData.services.filter((x) => x.guid != serviceIdForDelete);

			setServicesList(tempProjectData.services);
			setServiceIdForDelete(null);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}

		setIsSaving(false);
		setIsDeleting(false);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					<div className="container-services-list p-3">
						<div className="row mb-0">
							<div className="col-lg-6 pb-2 ps-0">
								<a className="btn btn-link py-0 ps-0" onClick={showAddServiceDialog}>
									<span>Add Service</span>
								</a>
							</div>
						</div>
						<div className="col-12 text-center">
							<Fragment>
								{servicesList && servicesList.length > 0 ? (
									<Fragment>
										<div className="services-list">
											<div className="table-services-list rw-table">
												<div className="rw-table-header">
													<div className="rw-th completed" />
													<div className="rw-th name">Name</div>
													<div className="rw-th action" />
												</div>
												<div className="rw-table-data">
													{servicesList.map((service) => (
														<div className="rw-row" key={service.guid}>
															<div className="rw-td completed ps-3">
																<input
																	className="check-completed list mt-2 disabled"
																	type="checkbox"
																	defaultChecked={service.completed}
																	title="Completed"
																/>
															</div>
															<div className="rw-td name text-truncate">
																<span>{service.name}</span>
															</div>
															<div className="rw-td action">
																<Link
																	to={`/project/${projectId}/services/${service.guid}`}
																	className="btn btn-link p-0 pe-2"
																	title="Edit"
																>
																	<i className="fas fa-pencil-alt" />
																</Link>
																<a
																	className="btn btn-link p-0"
																	onClick={showServiceDeleteConfirmation}
																	data-key={service.guid}
																	title="Delete"
																>
																	<i className="fas fa-trash-alt" />
																</a>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</Fragment>
								) : (
									<NoDataMessage message="No services found" />
								)}
							</Fragment>
						</div>
					</div>
					{addServiceDialog && <AddServiceDialog closeDialog={hideAddServiceDialog} callback={getServices} />}
					{showServiceConfirmDelete && (
						<ConfirmationDialog
							onConfirm={deleteService}
							onCancel={hideServiceDeleteConfirmation}
							confirmBtnClassName="danger"
						>
							<h5 className="rw-container-title">
								<span>Delete Confirmation</span>
							</h5>
							<span>Are you sure you want to delete this service?</span>
						</ConfirmationDialog>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default ServicesList;
