import { useEffect, useState } from 'react';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { animationDefaults } from '../../AnimConfig';
import Spinner from '../spinner/Spinner';
import { ValidateUser } from '../../Service';
import './Login.css';

const Login = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const history = useHistory();

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		if (target === 'email') {
			setEmail(value);
		} else if (target === 'password') {
			setPassword(value);
		}
	};

	const loginCallback = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		let data = { Email: email, Password: password, BrandId: 1 };
		const response = await ValidateUser(data);

		if (response.IsSuccessful) {
			let tempUser = JSON.parse(response.Data);

			dispatch({
				type : actionTypes.SET_USER,
				user : tempUser
			});

			localStorage.setItem('ff_app', email);

			history.push('/');
		} else {
			toast.error(response.Message);
			setIsLoading(false);
		}
	};

	return (
		<div className="main-app">
			<div className="main-app-container">
				<div className="app-login-container">
					<div className="login-column-container">
						<div
							className={`login-box shadow-large ${animationDefaults.animInClassName}`}
							xyz={animationDefaults.xyzDefaultSettings}
						>
							<h3>Login</h3>

							<div className="login-form">
								<form onSubmit={loginCallback}>
									<div className="form-group mt-3">
										<label htmlFor="email" className="form-control-label">
											Email
										</label>
										<input
											id="email"
											type="email"
											name="email"
											className="form-control form-control-lg"
											value={email}
											onChange={handleChange}
											disabled={isLoading}
										/>
									</div>
									<div className="form-group mt-3">
										<label htmlFor="password" className="form-control-label">
											Password
										</label>
										<input
											id="password"
											type="password"
											name="password"
											className="form-control form-control-lg"
											value={password}
											onChange={handleChange}
											disabled={isLoading}
										/>
									</div>
									<button
										id="btnLogin"
										type="submit"
										className="btn btn-primary btn-lg btn-block mt-3"
										disabled={isLoading}
									>
										{isLoading ? <Spinner /> : <span>Login</span>}
									</button>
								</form>
							</div>
						</div>
						{/* <Link to="/forgot-password" className="btn btn-link btn-block mt-3">
							Forgot Your Password?
						</Link> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
