import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetProjectSettings, SaveProjectSettings, UploadFile } from '../../Service';
import { webServiceUrl, callToActionPreviewCss } from '../../CommonFunctions';
import { toast } from 'react-toastify';
import './CallToAction.css';
import FormTitleContainer from '../formTitleContainer/FormTitleContainer';
import ImageSelect from '../imageSelect/ImageSelect';

const CallToAction = () => {
	const [ { projectData, showImageSelect }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(() => {
		loadProjectSettings();
	}, []);

	useEffect(
		() => {
			if (projectData) {
				let tempProjectData = callToActionPreviewCss(projectData);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData && projectData.callToAction) {
			setFormData({
				Headline       : projectData.callToAction.headline ? projectData.callToAction.headline : '',
				ButtonText     : projectData.callToAction.buttonText ? projectData.callToAction.buttonText : '',
				ButtonUrl      : projectData.callToAction.buttonUrl ? projectData.callToAction.buttonUrl : '',
				OverlayColor   : projectData.callToAction.overlayColor ? projectData.callToAction.overlayColor : '',
				OverlayOpacity : projectData.callToAction.overlayOpacity ? projectData.callToAction.overlayOpacity : '',
				Completed      : projectData.callToAction.completed ? projectData.callToAction.completed : false
			});
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'Headline') {
			tempProjectData.callToAction.headline = value;
		} else if (target === 'ButtonText') {
			tempProjectData.callToAction.buttonText = value;
		} else if (target === 'ButtonUrl') {
			tempProjectData.callToAction.buttonUrl = value;
		} else if (target === 'OverlayColor') {
			tempProjectData.callToAction.overlayColor = value;
		} else if (target === 'OverlayOpacity') {
			tempProjectData.callToAction.overlayOpacity = value;
		} else if (target === 'Completed') {
			tempProjectData.callToAction.completed = checked;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});

		if (projectData) {
			tempProjectData = callToActionPreviewCss(projectData);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;
		tempProjectData.callToAction.headline = formData.Headline;
		tempProjectData.callToAction.buttonText = formData.ButtonText;
		tempProjectData.callToAction.buttonUrl = formData.ButtonUrl;
		tempProjectData.callToAction.overlayColor = formData.OverlayColor;
		tempProjectData.callToAction.overlayOpacity = formData.OverlayOpacity;
		tempProjectData.callToAction.completed = formData.Completed;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.callToAction = tempProjectData.callToAction;

			//update the json
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');
			}
		}

		setIsSaving(false);
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	const onImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeImageUpload = (e) => {
		e.preventDefault();

		if (projectData.callToAction.imageUploaded) {
			let tempProjectData = projectData;

			delete tempProjectData.callToAction.imageUploaded;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const onImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			let data = new FormData();
			data.append('ProjectId', tempProjectData.guid);
			data.append('FileName', 'callToAction');
			data.append('UploadedFile', file);

			const response = await UploadFile(data);

			if (response.IsSuccessful) {
				let fileUrl = webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

				tempProjectData.callToAction.imageUploaded = fileUrl;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const closeCallToAction = () => {
		dispatch({
			type                    : actionTypes.SHOW_PROJECT_CALL_TO_ACTION,
			projectShowCallToAction : false
		});
	};

	return (
		<Fragment>
			{showImageSelect ? (
				<ImageSelect type="callToAction" />
			) : (
				<Fragment>
					{formData && (
						<form onSubmit={handleSubmit}>
							<div className="form-fields-wrapper">
								<FormTitleContainer title="Call To Action Details" />
								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="Headline">Headline</label>
										<input
											type="text"
											className="form-control"
											name="Headline"
											value={formData.Headline}
											placeholder="Enter Headline"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="ButtonText">Button Text</label>
										<input
											type="text"
											className="form-control"
											name="ButtonText"
											value={formData.ButtonText}
											placeholder="Enter Button Text"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="ButtonUrl">Button Url</label>
										<input
											type="text"
											className="form-control"
											name="ButtonUrl"
											value={formData.ButtonUrl}
											placeholder="Enter Button Url"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<label htmlFor="Image" className="mb-1">
										Image
									</label>
									<div className="col-10 pe-0">
										<div>
											<button
												onClick={openImages}
												className="btn btn-primary w-100"
												title="Select Hero Image"
											>
												<span>Search</span>
											</button>
										</div>
									</div>
									<div className="col-2">
										{projectData &&
										projectData.callToAction &&
										projectData.callToAction.imageUploaded ? (
											<button
												type="button"
												onClick={removeImageUpload}
												className="btn btn-danger w-100"
											>
												<span>
													<i className="fas fa-trash-alt" />
												</span>
											</button>
										) : (
											<button
												type="button"
												onClick={onImageUpload}
												className="btn btn-primary w-100"
											>
												<span>
													<i className="fas fa-cloud-upload-alt" />
												</span>
											</button>
										)}
										<input
											type="file"
											onChange={onImageChange}
											className="d-none"
											id="uploadImage"
										/>
									</div>
								</div>
								<div className="row mb-0 px-2">
									<div className="col-6 pe-0">
										<label htmlFor="OverlayColor">Overlay Color</label>
									</div>
									<div className="col-6 pe-0">
										<label htmlFor="OverlayOpacity">Overlay Opacity</label>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-3 pe-0">
										<input
											type="color"
											className="form-control form-control-color w-100"
											name="OverlayColor"
											value={formData.OverlayColor || '#000000'}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 ps-1 d-flex align-items-end">
										<label htmlFor="OverlayColorOutput" />
										<input
											type="text"
											className="form-control"
											name="OverlayColorOutput"
											value={formData.OverlayColor || '#000000'}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 pe-0">
										<input
											type="range"
											className="form-range mt-2"
											name="OverlayOpacity"
											min={0}
											max={100}
											value={formData.OverlayOpacity || '35'}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 ps-1 d-flex align-items-end">
										<label htmlFor="OverlayOpacityOutput" />
										<input
											type="text"
											className="form-control"
											name="OverlayOpacityOutput"
											value={formData.OverlayOpacity || '35'}
											onChange={handleChange}
										/>
									</div>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div className="row mb-0 px-2">
									<div className="col-10">
										<button type="submit" className="btn btn-primary btn-lg me-3">
											<span>Save</span>
										</button>
										<button
											type="button"
											onClick={closeCallToAction}
											className="btn btn-outline-primary btn-lg me-3"
										>
											<span>Close</span>
										</button>
									</div>
									<div className="col-2 d-flex justify-content-end align-items-center">
										<input
											type="checkbox"
											name="Completed"
											className="form-check-input check-completed"
											defaultChecked={formData.Completed}
											onClick={handleChange}
											title="Is Completed?"
										/>
									</div>
								</div>
							</div>
						</form>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default CallToAction;
