import { Fragment, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import TinyMCEEditor from '../../tinyMCEEditor/TinyMCEEditor';
import './BlogPostContent.css';

const BlogPostContent = () => {
	const [ { projectData, blogPostShowContent }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ blogPostContent, setBlogPostContent ] = useState('');
	const [ formData, setFormData ] = useState('');
	const match = useRouteMatch();
	const blogPostId = match.params.blogPostId;

	useEffect(() => {
		getContent();
	}, []);

	const getContent = () => {
		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.blogPosts) {
				let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

				if (item) {
					if (item.content) {
						setFormData({
							Content : item.content
						});

						setBlogPostContent(item.content);
					}

					// dispatch({
					// 	type        : actionTypes.SET_PROJECT_DATA,
					// 	projectData : tempProjectData
					// });
				}
			}
		}
	};

	const applyContent = (e) => {
		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.blogPosts) {
				let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

				if (item) {
					if (item.contentPreview) {
						item.content = item.contentPreview;
						delete item.contentPreview;
					}

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
					dispatch({
						type                : actionTypes.SHOW_BLOGPOST_CONTENT,
						blogPostShowContent : false
					});
					dispatch({
						type                  : actionTypes.SHOW_BLOGPOST_TAB_IMAGES,
						blogPostShowTabImages : false
					});
				}
			}
		}
	};

	const cancelContent = (e) => {
		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.blogPosts) {
			let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

			if (item) {
				if (item.contentPreview) {
					delete item.contentPreview;
				}

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
				dispatch({
					type                : actionTypes.SHOW_BLOGPOST_CONTENT,
					blogPostShowContent : false
				});
				dispatch({
					type                  : actionTypes.SHOW_BLOGPOST_TAB_IMAGES,
					blogPostShowTabImages : false
				});
			}
		}
	};

	const handleContentChange = (editorContent) => {
		setBlogPostContent(editorContent);

		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.blogPosts) {
				let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

				if (item) {
					item.contentPreview = editorContent;

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
				}
			}
		}
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					<div className="content-wrapper">
						<div className="row mb-3 px-0">
							<div className="col-12">
								<label htmlFor="Title">Blog Post Content</label>
								<TinyMCEEditor
									id="editorBlogPostContent"
									name="BlogPostContent"
									value={formData.Content}
									onEditorChange={handleContentChange}
									height="calc(100vh - 225px)"
								/>
							</div>
						</div>
					</div>
					<div className="form-buttons-wrapper">
						<div className="row mb-0 px-2">
							<div className="col-12">
								<button type="button" className="btn btn-primary btn-lg me-3" onClick={applyContent}>
									Apply
								</button>
								<button
									type="button"
									className="btn btn-outline-primary btn-lg me-3"
									onClick={cancelContent}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default BlogPostContent;
