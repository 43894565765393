import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetProjectSettings, SaveProjectSettings, UploadFile } from '../../Service';
import { createGuid, webServiceUrl } from '../../CommonFunctions';
import { toast } from 'react-toastify';
import './SEODetails.css';
import FormTitleContainer from '../formTitleContainer/FormTitleContainer';
import ImageSelect from '../imageSelect/ImageSelect';

const SEODetails = () => {
	const [ { projectData, projectShowSEO, showImageSelect }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	useEffect(
		() => {
			if (projectData) {
				let tempProjectData = projectData;

				if (!tempProjectData.seo) {
					tempProjectData.seo = {};
				}

				setFormData({
					MetaTitle       : tempProjectData.seo.metaTitle,
					MetaDescription : tempProjectData.seo.metaDescription,
					MetaKeywords    : tempProjectData.seo.metaKeywords,
					ShareImage      : tempProjectData.seo.shareImage ? tempProjectData.seo.shareImage : '',
					Completed       : tempProjectData.seo.completed
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'MetaTitle') {
			tempProjectData.seo.metaTitle = value;
		} else if (target === 'MetaDescription') {
			tempProjectData.seo.metaDescription = value;
		} else if (target === 'MetaKeywords') {
			tempProjectData.seo.metaKeywords = value;
		} else if (target === 'Completed') {
			tempProjectData.seo.completed = checked;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;

		if (!tempProjectData.seo) {
			tempProjectData.seo = {};
		}

		tempProjectData.seo.metaTitle = formData.MetaTitle;
		tempProjectData.seo.metaDescription = formData.MetaDescription;
		tempProjectData.seo.metaKeywords = formData.MetaKeywords;
		tempProjectData.seo.completed = formData.Completed;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.seo = tempProjectData.seo;

			//update the json
			data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}

		setIsSaving(false);
	};

	const closeSEODetailsPage = (e) => {
		dispatch({
			type           : actionTypes.SHOW_PROJECT_SEO,
			projectShowSEO : false
		});
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	const onShareImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadShareImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeShareImageUpload = (e) => {
		e.preventDefault();

		if (projectData.seo.shareImageUploaded) {
			let tempProjectData = projectData;

			delete tempProjectData.seo.shareImageUploaded;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const onShareImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadShareImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			let data = new FormData();
			data.append('ProjectId', tempProjectData.guid);
			data.append('FileName', 'share-image');
			data.append('UploadedFile', file);

			const response = await UploadFile(data);

			if (response.IsSuccessful) {
				let fileUrl = webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

				tempProjectData.seo.shareImageUploaded = fileUrl;

				delete tempProjectData.seo.shareImage;
				delete tempProjectData.seo.shareImagePreview;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	return (
		<Fragment>
			{showImageSelect ? (
				<ImageSelect type="share" />
			) : (
				<Fragment>
					{formData && (
						<form onSubmit={handleSubmit}>
							<div className="form-fields-wrapper">
								<FormTitleContainer title="SEO Details" />
								<div className="row mb-4 px-2">
									<div className="col-12">
										<label htmlFor="MetaTitle">Meta Title</label>
										<input
											type="text"
											className="form-control"
											name="MetaTitle"
											value={formData.MetaTitle}
											placeholder="Enter Meta Title"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-12">
										<label htmlFor="MetaDescription">MetaDescription</label>
										<textarea
											className="form-control"
											name="MetaDescription"
											value={formData.MetaDescription}
											placeholder="Enter Meta Description"
											onChange={handleChange}
											rows="3"
										/>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-12">
										<label htmlFor="MetaKeywords">MetaKeywords</label>
										<input
											type="text"
											className="form-control"
											name="MetaKeywords"
											value={formData.MetaKeywords}
											placeholder="Enter Meta Keywords"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<label htmlFor="ShareImage" className="mb-1">
										Share Image
									</label>
									<div className="col-5 pe-0">
										<div className="share-image-thumb">
											{projectData && projectData.seo && projectData.seo.shareImageUploaded ? (
												<img
													className="form-field-image"
													src={`${projectData.seo.shareImageUploaded}?v=${createGuid()}`}
													style={{ width: '90%' }}
												/>
											) : projectData && projectData.seo && projectData.seo.shareImage ? (
												<img
													className="form-field-image"
													src={`${projectData.seo.shareImage}?v=${createGuid()}`}
													style={{ width: '90%' }}
												/>
											) : (
												<img
													className="form-field-image"
													src="/img/no_image.png"
													style={{ width: '90%' }}
												/>
											)}
										</div>
									</div>
									<div className="col-5 pe-0">
										<div>
											<button
												onClick={openImages}
												className="btn btn-primary w-100"
												title="Select Share Image"
											>
												<span>Search</span>
											</button>
										</div>
									</div>
									<div className="col-2">
										{projectData && projectData.seo && projectData.seo.shareImageUploaded ? (
											<button
												type="button"
												onClick={removeShareImageUpload}
												className="btn btn-danger w-100"
											>
												<span>
													<i className="fas fa-trash-alt" />
												</span>
											</button>
										) : (
											<button
												type="button"
												onClick={onShareImageUpload}
												className="btn btn-primary w-100"
											>
												<span>
													<i className="fas fa-cloud-upload-alt" />
												</span>
											</button>
										)}
										<input
											type="file"
											onChange={onShareImageChange}
											className="d-none"
											id="uploadShareImage"
										/>
									</div>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div className="row mb-0 px-2">
									<div className="col-10">
										<button type="submit" className="btn btn-primary btn-lg me-3">
											<span>Save</span>
										</button>
										<button
											type="button"
											className="btn btn-outline-primary btn-lg me-3"
											onClick={closeSEODetailsPage}
										>
											<span>Close</span>
										</button>
									</div>
									<div className="col-2 d-flex justify-content-end align-items-center">
										<input
											type="checkbox"
											name="Completed"
											className="form-check-input check-completed"
											defaultChecked={formData.Completed}
											onClick={handleChange}
											title="Completed?"
										/>
									</div>
								</div>
							</div>
						</form>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default SEODetails;
