import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { aboutUsPreviewCss } from '../../../CommonFunctions';
import './AboutUsPreviewV3.css';

const AboutUsPreviewV3 = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ tempProjectData, setTempProjectData ] = useState({});
	const match = useRouteMatch();

	useEffect(
		() => {
			if (projectData) {
				setTempProjectData(projectData);

				let tempProjectData1 = aboutUsPreviewCss(projectData, null);

				setTempProjectData(tempProjectData1);
			}
		},
		[ projectData, tempProjectData ]
	);

	return (
		<Fragment>
			{tempProjectData &&
			tempProjectData.aboutUs && (
				<div className="aboutus-preview-container h-100 d-flex justify-content-center align-items-center">
					<Fragment>
						<div className="container-fluid section-aboutus p-0">
							<div className="row m-0">
								<div className="col-lg-6 col-sm-12 p-0 overflow-hidden">
									<div
										className="about-us-background iframe-fluid"
										style={
											tempProjectData.aboutUs.selectedImageDataset ? (
												{
													backgroundImage : `url(${tempProjectData.aboutUs
														.selectedImageDataset.thumbMax})`
												}
											) : tempProjectData.aboutUs.imageUploaded ? (
												{
													backgroundImage : `url(${tempProjectData.aboutUs.imageUploaded})`
												}
											) : tempProjectData.aboutUs.image ? (
												{
													backgroundImage : `url(${tempProjectData.aboutUs.image})`
												}
											) : tempProjectData.hero && tempProjectData.hero.image ? (
												{
													backgroundImage : `url(${tempProjectData.hero.image})`
												}
											) : (
												{}
											)
										}
									/>
								</div>
								<div className="col-lg-6 col-sm-12 text-left d-flex align-items-center">
									<div className="about-us-details mr-auto">
										<div className="container-fluid text-left">
											<div>
												{tempProjectData.aboutUs.title && (
													<h2>{tempProjectData.aboutUs.title}</h2>
												)}
												{tempProjectData.aboutUs.content && (
													<div
														dangerouslySetInnerHTML={{
															__html : projectData.aboutUs.content
														}}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				</div>
			)}
		</Fragment>
	);
};

export default AboutUsPreviewV3;
