import { Fragment, useState, useEffect } from 'react';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import TinyMCEEditor from '../../tinyMCEEditor/TinyMCEEditor';
import Spinner from '../../spinner/Spinner';
import { GetProjectSettings, SaveProjectSettings } from '../../../Service';
import { toast } from 'react-toastify';
import './ProjectAddNoteDialog.css';

const ProjectAddNoteDialog = ({ note, closeDialog, callback }) => {
	const [ { projectIdNote }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ projectNote, setProjectNote ] = useState({});

	useEffect(() => {
		if (note) {
			setFormData({
				ProjectNote : note
			});
		}
	}, []);

	const handleProjectNoteChange = (editorContent) => {
		setProjectNote(editorContent);
	};

	const handleSubmit = async (e) => {
		if (projectIdNote) {
			let data = {};
			data.ProjectId = projectIdNote;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				let tempProjectData = settings;

				setIsSaving(true);

				tempProjectData.note = projectNote;

				//update the json
				let data = {};
				data.ProjectId = projectIdNote;
				data.ProjectJson = JSON.stringify(tempProjectData);

				const response = await SaveProjectSettings(data);

				if (response.IsSuccessful) {
					toast.success('Project updated successfully.');
				}

				if (callback) {
					callback();
				}

				closeDialog();

				setIsSaving(false);
			} else {
				toast.error('Could not load project settings.');
			}
		} else {
			toast.error('Project Id is null.');
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent align="start">
					<h2 className="mb-4">Add Project Note</h2>
					<div className="row mb-3 px-2">
						<div className="col-12">
							<label htmlFor="ProjectNote">Note</label>
							<TinyMCEEditor
								id="editorProjectNote"
								name="ProjectNote"
								value={formData.ProjectNote}
								onEditorChange={handleProjectNoteChange}
								height="250px"
							/>
						</div>
					</div>
				</ModalDialogContent>
				<ModalDialogButtons align="start" shaded={true}>
					<button
						type="button"
						onClick={handleSubmit}
						className="btn btn-primary btn-lg mr-4"
						disabled={isSaving}
					>
						{!isSaving ? (
							<Fragment>
								<span>Save</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<span>Saving...</span>
							</Fragment>
						)}
					</button>
					<button
						type="button"
						className="btn btn-outline-primary btn-lg"
						disabled={isSaving}
						onClick={closeDialog}
					>
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default ProjectAddNoteDialog;
