import { Fragment, useState, useEffect } from 'react';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetGoogleFonts } from '../../../Service';
import Spinner from '../../spinner/Spinner';
import './LogoFonts.css';

const LogoFonts = () => {
	const [ { projectData, logoShowFonts }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ fontsList, setFontsList ] = useState(null);
	const [ searchTerm, setSearchTerm ] = useState('');

	useEffect(
		() => {
			getGoogleFonts();
		},
		[ searchTerm ]
	);

	const getGoogleFonts = async () => {
		setIsLoading(true);

		const response = await GetGoogleFonts();

		if (response && response.items) {
			let tempFontsList = response.items;

			if (searchTerm !== undefined && searchTerm !== null && searchTerm !== '') {
				tempFontsList = tempFontsList.filter((fontItem) => {
					return fontItem.family.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
				});
			}

			//for (let i = 0; i < tempFontsList.length; i++) {
			for (let i = 0; i < 100; i++) {
				let font = tempFontsList[i];

				if (font) {
					let url = 'https://fonts.googleapis.com/css?family=';
					url += font.family.replace(' ', '+');
					// url += ':' + font.weights.join(',');
					// url.replace('regular', '400');
					let link = document.createElement('link');
					link.href = url;
					link.rel = 'stylesheet';
					link.type = 'text/css';
					document.head.appendChild(link);
				}
			}

			setFontsList(tempFontsList);
		}

		setIsLoading(false);
	};

	const selectFont = (e) => {
		let selectedFont = e.currentTarget.id.replace('font', '').replace('_', ' ');

		let tempProjectData = projectData;
		tempProjectData.logo.fontTypePreview = selectedFont;

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});
	};

	const handleSubmit = (e) => {
		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData.logo.fontTypePreview) {
				tempProjectData.logo.fontType = tempProjectData.logo.fontTypePreview;
				delete tempProjectData.logo.fontTypePreview;
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});

			dispatch({
				type          : actionTypes.SHOW_LOGO_FONTS,
				logoShowFonts : false
			});
		}
	};

	const cancelFont = (e) => {
		let tempProjectData = projectData;

		if (tempProjectData.logo.fontTypePreview) {
			delete tempProjectData.logo.fontTypePreview;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});

		dispatch({
			type          : actionTypes.SHOW_LOGO_FONTS,
			logoShowFonts : false
		});
	};

	const onSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const onSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			setSearchTerm(e.target.value);
		}
	};

	return (
		<Fragment>
			{fontsList && (
				<Fragment>
					<div className="search-list-wrapper">
						<div className="search-fonts-wrapper input-group mb-3">
							<input
								type="search"
								className="form-control border-end-0 border"
								onChange={onSearchChange}
								onKeyDown={onSearchKeyDown}
								value={searchTerm}
								placeholder="Search fonts"
							/>
							<span className="input-group-append">
								<button
									type="button"
									className="btn btn-primary border btn-search"
									onClick={getGoogleFonts}
									title="Search Fonts"
								>
									<i className="fa fa-search" />
								</button>
							</span>
						</div>
						{isLoading ? (
							<div className="text-center">
								<Spinner />
							</div>
						) : (
							<Fragment>
								<div className="fonts-list">
									{fontsList.slice(0, 100).map((font) => (
										<a
											className="font-card"
											onClick={selectFont}
											key={font.family}
											id={`font${font.family.replace(' ', '_')}`}
										>
											<div className="font-card-wrapper">
												<div className="font-name">{font.family}</div>
												<div className="font-preview" style={{ fontFamily: font.family }}>
													<span>{projectData.company}</span>
												</div>
											</div>
										</a>
									))}
								</div>
							</Fragment>
						)}
					</div>
					<form onSubmit={handleSubmit}>
						<div className="form-buttons-wrapper">
							<div className="row mb-0 px-2">
								<div className="col-12">
									<button type="submit" className="btn btn-primary btn-lg me-3">
										Apply
									</button>
									<button
										type="button"
										className="btn btn-outline-primary btn-lg me-3"
										onClick={cancelFont}
									>
										Cancel
									</button>
								</div>
							</div>
						</div>
					</form>
				</Fragment>
			)}
		</Fragment>
	);
};

export default LogoFonts;
