import { Fragment, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import NoDataMessage from '../../noDataMessage/NoDataMessage';
import AddTestimonialDialog from '../testimonialNew/AddTestimonialDialog';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import './TestimonialsList.css';

const TestimonialsList = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);
	const [ testimonialsList, setTestimonialsList ] = useState([]);
	const [ addTestimonialDialog, setAddTestimonialDialog ] = useState(false);
	const [ showTestimonialConfirmDelete, setShowTestimonialConfirmDelete ] = useState(false);
	const [ testimonialIdForDelete, setTestimonialIdForDelete ] = useState(null);
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			getTestimonials();
		},
		[ projectData ]
	);

	const getTestimonials = async () => {
		if (projectData && projectData.testimonials) {
			let tempTestimonialsList = projectData.testimonials;
			setTestimonialsList(tempTestimonialsList);
		}

		setIsLoading(false);
	};

	const showAddTestimonialDialog = () => {
		setAddTestimonialDialog(true);
	};

	const hideAddTestimonialDialog = () => {
		setAddTestimonialDialog(false);
	};

	const showTestimonialDeleteConfirmation = (e) => {
		setShowTestimonialConfirmDelete(true);

		let selectedTestimonialId = e.currentTarget.dataset.key;
		setTestimonialIdForDelete(selectedTestimonialId);
	};

	const hideTestimonialDeleteConfirmation = (e) => {
		setShowTestimonialConfirmDelete(false);
		setTestimonialIdForDelete(null);
	};

	const deleteTestimonial = () => {
		if (projectData && projectData.testimonials && testimonialIdForDelete) {
			setShowTestimonialConfirmDelete(false);
			setIsSaving(true);
			setIsDeleting(true);

			let tempProjectData = projectData;

			tempProjectData.testimonials = tempProjectData.testimonials.filter((x) => x.guid != testimonialIdForDelete);

			setTestimonialsList(tempProjectData.testimonials);
			setTestimonialIdForDelete(null);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}

		setIsSaving(false);
		setIsDeleting(false);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					<div className="container-services-list p-3">
						<div className="row mb-0">
							<div className="col-lg-6 pb-2 ps-0">
								<a className="btn btn-link py-0 ps-0" onClick={showAddTestimonialDialog}>
									<span>Add Testimonial</span>
								</a>
							</div>
						</div>
						<div className="col-12 text-center">
							<Fragment>
								{testimonialsList && testimonialsList.length > 0 ? (
									<Fragment>
										<div className="testimonials-list">
											<div className="table-testimonials-list rw-table">
												<div className="rw-table-header">
													<div className="rw-th completed" />
													<div className="rw-th name">Title</div>
													<div className="rw-th action" />
												</div>
												<div className="rw-table-data">
													{testimonialsList.map((testimonial) => (
														<div className="rw-row" key={testimonial.guid}>
															<div className="rw-td completed ps-3">
																<input
																	className="check-completed list mt-2 disabled"
																	type="checkbox"
																	defaultChecked={testimonial.completed}
																	title="Completed"
																/>
															</div>
															<div className="rw-td name text-truncate">
																<span>{testimonial.title}</span>
															</div>
															<div className="rw-td action">
																<Link
																	to={`/project/${projectId}/testimonials/${testimonial.guid}`}
																	className="btn btn-link p-0 pe-2"
																	title="Edit"
																>
																	<i className="fas fa-pencil-alt" />
																</Link>
																<a
																	className="btn btn-link p-0"
																	onClick={showTestimonialDeleteConfirmation}
																	data-key={testimonial.guid}
																	title="Delete"
																>
																	<i className="fas fa-trash-alt" />
																</a>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</Fragment>
								) : (
									<NoDataMessage message="No testimonials found" />
								)}
							</Fragment>
						</div>
					</div>
					{addTestimonialDialog && (
						<AddTestimonialDialog closeDialog={hideAddTestimonialDialog} callback={getTestimonials} />
					)}
					{showTestimonialConfirmDelete && (
						<ConfirmationDialog
							onConfirm={deleteTestimonial}
							onCancel={hideTestimonialDeleteConfirmation}
							confirmBtnClassName="danger"
						>
							<h5 className="rw-container-title">
								<span>Delete Confirmation</span>
							</h5>
							<span>Are you sure you want to delete this testimonial?</span>
						</ConfirmationDialog>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default TestimonialsList;
