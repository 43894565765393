import { Fragment, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import NoDataMessage from '../../noDataMessage/NoDataMessage';
import AddBlogPostDialog from '../blogPostNew/AddBlogPostDialog';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import './BlogPostsList.css';

const BlogPostsList = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);
	const [ blogPostsList, setBlogPostsList ] = useState([]);
	const [ addBlogPostDialog, setAddBlogPostDialog ] = useState(false);
	const [ showBlogPostConfirmDelete, setShowBlogPostConfirmDelete ] = useState(false);
	const [ blogPostIdForDelete, setBlogPostIdForDelete ] = useState(null);
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			getBlogPosts();
		},
		[ projectData ]
	);

	const getBlogPosts = async () => {
		if (projectData && projectData.blogPosts) {
			let tempBlogPostsList = projectData.blogPosts;
			setBlogPostsList(tempBlogPostsList);
		}

		setIsLoading(false);
	};

	const showAddBlogPostDialog = () => {
		setAddBlogPostDialog(true);
	};

	const hideAddBlogPostDialog = () => {
		setAddBlogPostDialog(false);
	};

	const showBlogPostDeleteConfirmation = (e) => {
		setShowBlogPostConfirmDelete(true);

		let selectedBlogPostId = e.currentTarget.dataset.key;
		setBlogPostIdForDelete(selectedBlogPostId);
	};

	const hideBlogPostDeleteConfirmation = (e) => {
		setShowBlogPostConfirmDelete(false);
		setBlogPostIdForDelete(null);
	};

	const deleteBlogPost = () => {
		if (projectData && projectData.blogPosts && blogPostIdForDelete) {
			setShowBlogPostConfirmDelete(false);
			setIsSaving(true);
			setIsDeleting(true);

			let tempProjectData = projectData;

			tempProjectData.blogPosts = tempProjectData.blogPosts.filter((x) => x.guid != blogPostIdForDelete);

			setBlogPostsList(tempProjectData.blogPosts);
			setBlogPostIdForDelete(null);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}

		setIsSaving(false);
		setIsDeleting(false);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					<div className="container-services-list p-3">
						<div className="row mb-0">
							<div className="col-lg-6 pb-2 ps-0">
								<a className="btn btn-link py-0 ps-0" onClick={showAddBlogPostDialog}>
									<span>Add Blog Post</span>
								</a>
							</div>
						</div>
						<div className="col-12 text-center">
							<Fragment>
								{blogPostsList && blogPostsList.length > 0 ? (
									<Fragment>
										<div className="blog-posts-list">
											<div className="table-blog-posts-list rw-table">
												<div className="rw-table-header">
													<div className="rw-th completed" />
													<div className="rw-th name">Title</div>
													<div className="rw-th action" />
												</div>
												<div className="rw-table-data">
													{blogPostsList.map((blogPost) => (
														<div className="rw-row" key={blogPost.guid}>
															<div className="rw-td completed ps-3">
																<input
																	className="check-completed list mt-2 disabled"
																	type="checkbox"
																	defaultChecked={blogPost.completed}
																	title="Completed"
																/>
															</div>
															<div className="rw-td name text-truncate">
																<span>{blogPost.title}</span>
															</div>
															<div className="rw-td action">
																<Link
																	to={`/project/${projectId}/blog-posts/${blogPost.guid}`}
																	className="btn btn-link p-0 pe-2"
																	title="Edit"
																>
																	<i className="fas fa-pencil-alt" />
																</Link>
																<a
																	className="btn btn-link p-0"
																	onClick={showBlogPostDeleteConfirmation}
																	data-key={blogPost.guid}
																	title="Delete"
																>
																	<i className="fas fa-trash-alt" />
																</a>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</Fragment>
								) : (
									<NoDataMessage message="No blog posts found" />
								)}
							</Fragment>
						</div>
					</div>
					{addBlogPostDialog && (
						<AddBlogPostDialog closeDialog={hideAddBlogPostDialog} callback={getBlogPosts} />
					)}
					{showBlogPostConfirmDelete && (
						<ConfirmationDialog
							onConfirm={deleteBlogPost}
							onCancel={hideBlogPostDeleteConfirmation}
							confirmBtnClassName="danger"
						>
							<h5 className="rw-container-title">
								<span>Delete Confirmation</span>
							</h5>
							<span>Are you sure you want to delete this blog post?</span>
						</ConfirmationDialog>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default BlogPostsList;
