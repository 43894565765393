import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import './Header.css';

const Header = () => {
	const [ { user }, dispatch ] = useStateValue();
	const history = useHistory();

	const logout = () => {
		localStorage.removeItem('ff_app');

		history.push('/login');
	};

	// const handleChatOpen = () => {
	// 	if (window.LC_API) {
	// 		//setChatDetails();

	// 		setTimeout(
	// 			() => {
	// 				window.LC_API.open_chat_window();
	// 			},
	// 			[ 100 ]
	// 		);
	// 	}
	// };

	return (
		<div className="container-fluid header">
			<div className="row w-100">
				<div className="col-6 text-start">
					<Link to="/">
						<Fragment>
							<img src="/img/logo.png" alt="" />
						</Fragment>
					</Link>
				</div>
				<div className="col-6 text-end">
					<ul className="header-nav">
						<li>
							<a className="btn btn-link" onClick={logout}>
								Logout
							</a>
						</li>
						{/* <li>
							<a className="btn btn-outline-primary" onClick={handleChatOpen}>
								<span>Chat</span>
							</a>
						</li> */}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Header;
