import './App.css';
import { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/login/Login';
import MainApp from './components/mainApp/MainApp';
import SessionLoader from './components/sessionLoader/SessionLoader';
import Spinner from './components/spinner/Spinner';
import CenteredContainer from './components/centeredContainer/CenteredContainer';

function App() {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ sessionLoaded, setSessionLoaded ] = useState(false);
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-right');

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(
		() => {
			if (sessionLoaded) {
				setIsLoading(false);
			}
		},
		[ sessionLoaded ]
	);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('top-center');
		} else {
			setToastifyPosition('bottom-right');
		}
	};

	return (
		<div className="App">
			<Router basename="/">
				<Fragment>
					<Fragment>
						<SessionLoader setSessionLoaded={setSessionLoaded} />
					</Fragment>
					{isLoading ? (
						<CenteredContainer>
							<Spinner />
						</CenteredContainer>
					) : (
						<Fragment>
							<Switch>
								<Route path="/login">
									<Login />
								</Route>
								<Route path="/">
									<MainApp />
								</Route>
							</Switch>
						</Fragment>
					)}
					<ToastContainer
						position={toastifyPosition}
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
					/>
				</Fragment>
			</Router>
		</div>
	);
}

export default App;
