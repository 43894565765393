import { Fragment, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetDPImages } from '../../Service';
import Spinner from '../spinner/Spinner';
import { toast } from 'react-toastify';
import { createGuid } from '../../CommonFunctions';

const ImageSelect = ({ type, subtype = '' }) => {
	const [ { projectData, showImageSelect }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ imagesList, setImagesList ] = useState(null);
	const [ searchTerm, setSearchTerm ] = useState('');
	const match = useRouteMatch();
	const objectId =
		type === 'blogPost'
			? match.params.blogPostId
			: type === 'service' ? match.params.serviceId : type === 'testimonial' ? match.params.testimonialId : '';

	useEffect(() => {
		getImages();
	}, []);

	const getImages = async () => {
		if (searchTerm && searchTerm !== '') {
			setIsLoading(true);

			let data = {};
			data.Key = 'd8f3d4af64bd433fa95f33f24469e317';
			data.SearchTerm = searchTerm;
			data.Limit = 200;
			data.Orientation = 'horizontal';

			const response = await GetDPImages(data);

			if (response.IsSuccessful) {
				let tempImagesList = JSON.parse(response.Data);

				setImagesList(tempImagesList);
			} else {
				toast.error('Error: ' + response.Message);
			}

			setIsLoading(false);
		}
	};

	const selectImage = (e) => {
		Array.from(document.querySelectorAll('.image-card')).forEach(function(el) {
			el.classList.remove('active');
		});

		e.currentTarget.classList.add('active');

		let selectedImage = e.currentTarget.querySelector('.image-preview img');

		let tempProjectData = projectData;

		switch (type) {
			case 'hero':
				tempProjectData.hero.imagePreview = selectedImage.dataset.thumbMax;
				tempProjectData.hero.imagePreviewId = selectedImage.dataset.dpid;
				break;
			case 'aboutUs':
				tempProjectData.aboutUs.selectedImageDataset = selectedImage.dataset;
				break;
			case 'callToAction':
				tempProjectData.callToAction.imagePreview = selectedImage.dataset.thumbMax;
				tempProjectData.callToAction.imagePreviewId = selectedImage.dataset.dpid;
				break;
			case 'blogPost':
				if (tempProjectData && tempProjectData.blogPosts) {
					let item = tempProjectData.blogPosts.find((x) => x.guid === objectId);

					if (item) {
						item.imagePreview = selectedImage.dataset.thumbMax;
						item.imagePreviewId = selectedImage.dataset.dpid;
					}
				}
				break;
			case 'service':
				if (tempProjectData && tempProjectData.services) {
					let serviceItem = tempProjectData.services.find((x) => x.guid === objectId);

					if (serviceItem) {
						if (subtype === 'hero') {
							serviceItem.heroImagePreview = selectedImage.dataset.thumbMax;
							serviceItem.heroImagePreviewId = selectedImage.dataset.dpid;
						} else if (subtype === 'featured') {
							serviceItem.feat.imagePreview = selectedImage.dataset.thumbMax;
							serviceItem.feat.imagePreviewId = selectedImage.dataset.dpid;
						}
					}
				}
				break;
			case 'testimonial':
				if (tempProjectData && tempProjectData.testimonials) {
					let item = tempProjectData.testimonials.find((x) => x.guid === objectId);

					if (item) {
						item.imagePreview = selectedImage.dataset.thumbMax;
						item.imagePreviewId = selectedImage.dataset.dpid;
					}
				}
				break;
			case 'gallery':
				tempProjectData.gallerySelectedImageDataset = selectedImage.dataset;
				tempProjectData.gallerySelectedImageDataset.guid = createGuid();
				break;
			case 'share':
				tempProjectData.seo.shareImagePreview = selectedImage.dataset.thumbMax;
				tempProjectData.seo.shareImagePreviewId = selectedImage.dataset.dpid;
				break;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});
	};

	const applyImage = (e) => {
		if (projectData) {
			let tempProjectData = projectData;

			switch (type) {
				case 'hero':
					if (tempProjectData.hero.imagePreview) {
						tempProjectData.hero.image = tempProjectData.hero.imagePreview;

						if (!tempProjectData.hero.imageData) {
							tempProjectData.hero.imageData = {};
						}

						tempProjectData.hero.imageData.id = tempProjectData.hero.imagePreviewId;

						delete tempProjectData.hero.imagePreview;
						delete tempProjectData.hero.imagePreviewId;
					}

					//remove uploaded image
					delete tempProjectData.hero.imageUploaded;
					break;
				case 'aboutUs':
					if (tempProjectData.aboutUs.selectedImageDataset) {
						tempProjectData.aboutUs.image = tempProjectData.aboutUs.selectedImageDataset.thumbMax;

						if (!tempProjectData.aboutUs.imageData) {
							tempProjectData.aboutUs.imageData = {};
						}

						tempProjectData.aboutUs.imageData.id = tempProjectData.aboutUs.selectedImageDataset.dpid;

						delete tempProjectData.aboutUs.selectedImageDataset;
					}

					//remove uploaded image
					delete tempProjectData.hero.imageUploaded;
					break;
				case 'callToAction':
					if (tempProjectData.callToAction.imagePreview) {
						tempProjectData.callToAction.image = tempProjectData.callToAction.imagePreview;

						if (!tempProjectData.callToAction.imageData) {
							tempProjectData.callToAction.imageData = {};
						}

						tempProjectData.callToAction.imageData.id = tempProjectData.callToAction.imagePreviewId;

						delete tempProjectData.callToAction.imagePreview;
						delete tempProjectData.callToAction.imagePreviewId;
					}

					//remove uploaded image
					delete tempProjectData.callToAction.imageUploaded;
					break;
				case 'blogPost':
					if (tempProjectData && tempProjectData.blogPosts) {
						let item = tempProjectData.blogPosts.find((x) => x.guid === objectId);

						if (item) {
							if (item.imagePreview) {
								item.image = item.imagePreview;

								if (!item.imageData) {
									item.imageData = {};
								}

								item.imageData.id = item.imagePreviewId;

								delete item.imagePreview;
								delete item.imagePreviewId;
								delete item.imageUploaded;
							}
						}
					}
					break;
				case 'service':
					if (tempProjectData && tempProjectData.services) {
						let serviceItem = tempProjectData.services.find((x) => x.guid === objectId);

						if (serviceItem) {
							if (subtype === 'hero') {
								if (serviceItem.heroImagePreview) {
									serviceItem.heroImage = serviceItem.heroImagePreview;

									if (!serviceItem.heroImageData) {
										serviceItem.heroImageData = {};
									}

									serviceItem.heroImageData.Id = serviceItem.heroImagePreviewId;

									delete serviceItem.heroImagePreview;
									delete serviceItem.heroImagePreviewId;
									delete serviceItem.heroImageUploaded;
								}
							} else if (subtype === 'featured') {
								if (serviceItem.feat.imagePreview) {
									serviceItem.feat.image = serviceItem.feat.imagePreview;

									if (!serviceItem.feat.imageData) {
										serviceItem.feat.imageData = {};
									}

									serviceItem.feat.imageData.id = serviceItem.feat.imagePreviewId;

									delete serviceItem.feat.imagePreview;
									delete serviceItem.feat.imagePreviewId;
									delete serviceItem.feat.imageUploaded;
								}
							}
						}
					}
					break;
				case 'testimonial':
					if (tempProjectData && tempProjectData.testimonials) {
						let item = tempProjectData.testimonials.find((x) => x.guid === objectId);

						if (item) {
							if (item.imagePreview) {
								item.image = item.imagePreview;

								if (!item.imageData) {
									item.imageData = {};
								}

								item.imageData.id = item.imagePreviewId;

								delete item.imagePreview;
								delete item.imagePreviewId;
								delete item.imageUploaded;
							}
						}
					}
					break;
				case 'gallery':
					if (tempProjectData.gallerySelectedImageDataset) {
						if (tempProjectData.gallery instanceof Array === false) {
							tempProjectData.gallery = [];
						}

						//check if the image is already selected
						let imageExists = tempProjectData.gallery.length
							? tempProjectData.gallery.find(
									(x) => x.dpid === tempProjectData.gallerySelectedImageDataset.dpid
								)
							: false;

						if (imageExists) {
							toast.error('Image already exists.');
							return;
						}

						tempProjectData.gallery.push(tempProjectData.gallerySelectedImageDataset);

						delete tempProjectData.gallerySelectedImageDataset;
					}
					break;
				case 'share':
					if (tempProjectData.seo.shareImagePreview) {
						tempProjectData.seo.shareImage = tempProjectData.seo.shareImagePreview;

						if (!tempProjectData.seo.shareImageData) {
							tempProjectData.seo.shareImageData = {};
						}

						tempProjectData.seo.shareImageData.id = tempProjectData.seo.shareImagePreviewId;

						delete tempProjectData.seo.shareImagePreview;
						delete tempProjectData.seo.shareImagePreviewId;
					}

					//remove uploaded image
					delete tempProjectData.seo.shareImageUploaded;
					break;
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});

			dispatch({
				type            : actionTypes.SHOW_IMAGE_SELECT,
				showImageSelect : false
			});
		}
	};

	const cancelImage = (e) => {
		let tempProjectData = projectData;

		switch (type) {
			case 'hero':
				if (tempProjectData.hero.imagePreview) {
					delete tempProjectData.hero.imagePreview;
					delete tempProjectData.hero.imagePreviewId;
				}
				break;
			case 'aboutUs':
				if (tempProjectData.aboutUs.imagePreview) {
					delete tempProjectData.aboutUs.imagePreview;
					delete tempProjectData.aboutUs.imagePreviewId;
				}
				break;
			case 'callToAction':
				if (tempProjectData.callToAction.imagePreview) {
					delete tempProjectData.callToAction.imagePreview;
					delete tempProjectData.callToAction.imagePreviewId;
				}
				break;
			case 'blogPost':
				if (tempProjectData && tempProjectData.blogPosts) {
					let item = tempProjectData.blogPosts.find((x) => x.guid === objectId);

					if (item) {
						if (item.imagePreview) {
							delete item.imagePreview;
							delete item.imagePreviewId;
						}
					}
				}
				break;
			case 'service':
				if (tempProjectData && tempProjectData.services) {
					let serviceItem = tempProjectData.services.find((x) => x.guid === objectId);

					if (serviceItem) {
						if (serviceItem.heroImagePreview) {
							delete serviceItem.heroImagePreview;
							delete serviceItem.heroImagePreviewId;
						}
						if (serviceItem.feat.imagePreview) {
							delete serviceItem.feat.imagePreview;
							delete serviceItem.feat.imagePreviewId;
						}
					}
				}
				break;
			case 'testimonial':
				if (tempProjectData && tempProjectData.testimonials) {
					let item = tempProjectData.testimonials.find((x) => x.guid === objectId);

					if (item) {
						if (item.imagePreview) {
							delete item.imagePreview;
							delete item.imagePreviewId;
						}
					}
				}
				break;
			case 'gallery':
				if (tempProjectData.gallerySelectedImageDataset) {
					delete tempProjectData.gallerySelectedImageDataset;
				}
				break;
			case 'share':
				if (tempProjectData.seo.shareImagePreview) {
					delete tempProjectData.seo.shareImagePreview;
					delete tempProjectData.seo.shareImagePreviewId;
				}
				break;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});

		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : false
		});
	};

	const onSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const onSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			setSearchTerm(e.target.value);
			getImages();
		}
	};

	return (
		<Fragment>
			<div className="search-list-wrapper">
				<div className="search-images-wrapper input-group mb-3">
					<input
						type="search"
						className="form-control border-end-0 border"
						onKeyDown={onSearchKeyDown}
						onChange={onSearchChange}
						value={searchTerm}
						placeholder="Enter search term"
					/>
					<span className="input-group-append">
						<button
							type="button"
							className="btn btn-primary border btn-search-images"
							onClick={getImages}
							title="Search Images"
						>
							<i className="fa fa-search" />
						</button>
					</span>
				</div>
				{isLoading ? (
					<div className="text-center">
						<Spinner />
					</div>
				) : (
					<Fragment>
						<div className="images-list">
							{imagesList && (
								<Fragment>
									{imagesList.map((image) => (
										<a
											className="image-card"
											onClick={selectImage}
											key={image.Id}
											id={`image${image.Id}`}
										>
											<div className="image-preview text-center">
												<img
													src={image.ThumbLarge}
													data-dpid={image.Id}
													data-title={image.Title}
													data-thumb={image.Thumb}
													data-thumb-large={image.ThumbLarge}
													data-thumb-max={image.ThumbMax}
													data-url-big={image.UrlBig}
												/>
											</div>
										</a>
									))}
								</Fragment>
							)}
						</div>
					</Fragment>
				)}
			</div>
			<div className="form-buttons-wrapper">
				<div className="row mb-0 px-2">
					<div className="col-12">
						<button type="button" className="btn btn-primary btn-lg me-3" onClick={applyImage}>
							Apply
						</button>
						<button type="button" className="btn btn-outline-primary btn-lg me-3" onClick={cancelImage}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ImageSelect;
