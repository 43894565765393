export const logoPreviewCss = (tempProjectData, type) => {
	if (tempProjectData && tempProjectData.logo) {
		if (!tempProjectData.logo.horizontal) {
			tempProjectData.logo.horizontal = {};
		}

		if (!tempProjectData.logo.vertical) {
			tempProjectData.logo.vertical = {};
		}

		if (!tempProjectData.favicon) {
			tempProjectData.favicon = {};
		}

		let color = '#ffffff';
		if (type === 'logo') {
			color = tempProjectData.primaryColor;
		}

		let layout = tempProjectData.logo.layout ? tempProjectData.logo.layout : 'horizontal';

		let fontType = tempProjectData.logo.fontTypePreview
			? tempProjectData.logo.fontTypePreview
			: tempProjectData.logo.fontType;

		let fontSize =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.fontSize ? tempProjectData.logo.horizontal.fontSize : '24'
				: tempProjectData.logo.vertical.fontSize ? tempProjectData.logo.vertical.fontSize : '16';

		let fontWeight =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.fontWeight ? tempProjectData.logo.horizontal.fontWeight : 'normal'
				: tempProjectData.logo.vertical.fontWeight ? tempProjectData.logo.vertical.fontWeight : 'normal';

		let xTextPosition =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.xTextPosition ? tempProjectData.logo.horizontal.xTextPosition : '0'
				: tempProjectData.logo.vertical.xTextPosition ? tempProjectData.logo.vertical.xTextPosition : '0';

		let yTextPosition =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.yTextPosition ? tempProjectData.logo.horizontal.yTextPosition : '0'
				: tempProjectData.logo.vertical.yTextPosition ? tempProjectData.logo.vertical.yTextPosition : '0';

		let borderType =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.borderType ? tempProjectData.logo.horizontal.borderType : 'None'
				: tempProjectData.logo.vertical.borderType ? tempProjectData.logo.vertical.borderType : 'None';

		let borderWidth =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.borderWidth ? tempProjectData.logo.horizontal.borderWidth : '1'
				: tempProjectData.logo.vertical.borderWidth ? tempProjectData.logo.vertical.borderWidth : '1';

		let borderPadding =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.borderPadding ? tempProjectData.logo.horizontal.borderPadding : '0'
				: tempProjectData.logo.vertical.borderPadding ? tempProjectData.logo.vertical.borderPadding : '0';

		let iconSize =
			layout === 'horizontal'
				? tempProjectData.logo.horizontal.iconSize ? tempProjectData.logo.horizontal.iconSize : '64'
				: tempProjectData.logo.vertical.iconSize ? tempProjectData.logo.vertical.iconSize : '128';

		//add font link in header
		if (fontType) {
			let url = 'https://fonts.googleapis.com/css?family=';
			url += fontType.replace(' ', '+');
			let link = document.createElement('link');
			link.href = url;
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.crossOrigin = 'anonymous';
			document.head.appendChild(link);
		}

		let textStyle = {
			color      : color,
			fontFamily : fontType,
			fontSize   : fontSize + 'px',
			fontWeight : fontWeight,
			lineHeight : fontSize + 'px',
			marginTop  : yTextPosition + 'px',
			marginLeft : xTextPosition + 'px'
		};

		let borderTypeStyle = {};
		switch (borderType) {
			case 'None':
				borderTypeStyle = { border: 'none' };
				break;
			case 'Squared':
				borderTypeStyle = {
					border  : `${borderWidth}px solid ${color}`,
					padding : `${borderPadding}px`
				};
				break;
			case 'Rounded':
				borderTypeStyle = {
					border       : `${borderWidth}px solid ${color}`,
					borderRadius : '5px',
					padding      : `${borderPadding}px`
				};
				break;
			case 'Circled':
				borderTypeStyle = {
					border       : `${borderWidth}px solid ${color}`,
					borderRadius : '50%',
					padding      : `${borderPadding}px`
				};
				break;
			default:
				break;
		}

		if (type === 'logo') {
			tempProjectData.logo.cssStyleText = textStyle;
			tempProjectData.logo.cssStyleIcon = borderTypeStyle;
			tempProjectData.logo.cssStyleIconSize = { width: iconSize + 'px' };
		} else if (type === 'about-us') {
			let rgbaColor = hex2rgba(tempProjectData.primaryColor, 0.8);

			tempProjectData.aboutUs.cssStyleOverlay = { boxShadow: `inset 2000px 0 0 0 ${rgbaColor}` };
			tempProjectData.aboutUs.cssStyleText = textStyle;
			tempProjectData.aboutUs.cssStyleIcon = borderTypeStyle;
		} else if (type === 'call-to-action') {
			// let rgbaColor = hex2rgba(tempProjectData.primaryColor, 0.8);
			// tempProjectData.callToAction.cssStyleOverlay = { boxShadow: `inset 2000px 0 0 0 ${rgbaColor}` };
			// tempProjectData.callToAction.cssStyleText = textStyle;
			// tempProjectData.callToAction.cssStyleIcon = borderTypeStyle;
		}
	}

	return tempProjectData;
};

export const heroPreviewCss = (tempProjectData) => {
	if (tempProjectData) {
		tempProjectData = logoPreviewCss(tempProjectData, 'logo');

		if (!tempProjectData) {
			tempProjectData = {};
		}

		if (!tempProjectData.hero) {
			tempProjectData.hero = {
				image            : '',
				headline         : 'This Is The Headline For This Website',
				headlineFontType : 'Roboto',
				contentFontType  : 'Roboto'
			};
		}

		if (!tempProjectData.favicon) {
			tempProjectData.favicon = {};
		}

		let headlineFontType = tempProjectData.hero.headlineFontTypePreview
			? tempProjectData.hero.headlineFontTypePreview
			: tempProjectData.hero.headlineFontType;
		let contentFontType = tempProjectData.hero.contentFontTypePreview
			? tempProjectData.hero.contentFontTypePreview
			: tempProjectData.hero.contentFontType;

		//add font link in header
		if (headlineFontType) {
			let url = 'https://fonts.googleapis.com/css?family=';
			url += headlineFontType.replace(' ', '+');
			let link = document.createElement('link');
			link.href = url;
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.crossOrigin = 'anonymous';
			document.head.appendChild(link);
		}

		if (contentFontType) {
			let url = 'https://fonts.googleapis.com/css?family=';
			url += contentFontType.replace(' ', '+');
			let link = document.createElement('link');
			link.href = url;
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.crossOrigin = 'anonymous';
			document.head.appendChild(link);
		}

		let headlineStyle = {
			fontFamily : headlineFontType,
			fontSize   : '48px'
		};

		let contentStyle = {
			fontFamily : contentFontType,
			fontSize   : '28px'
		};

		tempProjectData.hero.cssHeadlineStyle = headlineStyle;
		tempProjectData.hero.cssContentStyle = contentStyle;
	}

	return tempProjectData;
};

export const aboutUsPreviewCss = (tempProjectData, color) => {
	if (tempProjectData) {
		if (!tempProjectData.aboutUs) {
			tempProjectData.aboutUs = {
				image   : '',
				title   : '',
				content : ''
			};
		}

		tempProjectData = logoPreviewCss(tempProjectData, 'about-us');

		return tempProjectData;
	}
};

export const callToActionPreviewCss = (tempProjectData, color) => {
	if (tempProjectData) {
		if (!tempProjectData.callToAction) {
			tempProjectData.callToAction = {
				headline   : '',
				buttonText : '',
				buttonUrl  : '',
				image      : ''
			};
		}

		//tempProjectData = logoPreviewCss(tempProjectData, 'call-to-action');

		return tempProjectData;
	}
};

export const servicePreviewCss = (tempProjectData, serviceItem) => {
	if (tempProjectData && serviceItem) {
		if (!serviceItem.feat) {
			serviceItem.feat = { borderType: 'None', borderWidth: '1' };
		}

		let borderTypeStyle = {};
		switch (serviceItem.feat.borderType) {
			case 'None':
				borderTypeStyle = { border: 'none' };
				break;
			case 'Squared':
				borderTypeStyle = {
					border : `${serviceItem.feat.borderWidth}px solid #ffffff`
				};
				break;
			case 'Rounded':
				borderTypeStyle = {
					border       : `${serviceItem.feat.borderWidth}px solid #ffffff`,
					borderRadius : '5px'
				};
				break;
			case 'Circled':
				borderTypeStyle = {
					border       : `${serviceItem.feat.borderWidth}px solid #ffffff`,
					borderRadius : '50%'
				};
				break;
			default:
				break;
		}

		serviceItem.feat.cssStyleOverlay = {};
		if (serviceItem.feat.showOverlay && serviceItem.feat.overlayColor) {
			let rgbaColor;

			if (serviceItem.feat.overlayOpacity) {
				rgbaColor = hex2rgba(serviceItem.feat.overlayColor, parseInt(serviceItem.feat.overlayOpacity) / 100);
			} else {
				rgbaColor = hex2rgba(serviceItem.feat.overlayColor, 0.8);
			}

			serviceItem.feat.cssStyleOverlay = { boxShadow: `inset 2000px 0 0 0 ${rgbaColor}` };
		}

		serviceItem.feat.cssStyleIcon = borderTypeStyle;
		serviceItem.feat.cssStyleIconSize = {
			width  : (serviceItem.feat.iconSize ? serviceItem.feat.iconSize : '128') + 'px',
			filter : 'url(#serviceColorTransformFilter_' + serviceItem.guid + ')'
		};
	}

	return serviceItem;
};

export const createGuid = () => {
	let guid = 'xxxxxxxx4xxxyxxx'.replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});

	return guid;
};

const hex2rgba = (hex, alpha = 1) => {
	const [ r, g, b ] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
	return `rgba(${r},${g},${b},${alpha})`;
};

export const setSVGFilter = (hexColor) => {
	if (hexColor) {
		let colorMatch = hexColor.match(/\w\w/g);

		if (colorMatch) {
			const [ r, g, b ] = colorMatch.map((x) => parseInt(x, 16));

			const rScaled = r / 255.0;
			const gScaled = g / 255.0;
			const bScaled = b / 255.0;

			if (!isNaN(rScaled) && !isNaN(gScaled) && !isNaN(bScaled)) {
				return `0 0 0 0 ${rScaled}
						0 0 0 0 ${gScaled}
						0 0 0 0 ${bScaled}
						0 0 0 1 0`;
			}
		}
	}
};

export const getCurrentDate = () => {
	var today = new Date();
	var dd = today.getDate();

	var mm = today.getMonth() + 1;
	var yyyy = today.getFullYear();
	if (dd < 10) {
		dd = '0' + dd;
	}

	if (mm < 10) {
		mm = '0' + mm;
	}

	today = mm + '/' + dd + '/' + yyyy;

	return today;
};

export const findArrayNestedItem = (arr, itemId, nestingKey) =>
	arr.reduce((a, item) => {
		if (a) return a;
		if (item.id === itemId) return item;
		if (item[nestingKey]) return findArrayNestedItem(item[nestingKey], itemId, nestingKey);
	}, null);

export const findNested = (obj, key, value) => {
	if (obj[key] === value) {
		return obj;
	} else {
		for (var i = 0, len = Object.keys(obj).length; i < len; i++) {
			if (typeof obj[i] === 'object') {
				var found = findNested(obj[i], key, value);
				if (found) {
					// If the object was found in the recursive call, bubble it up.
					return found;
				}
			}
		}
	}
};

export const createSlug = (name) => {
	let slug = name;
	slug = slug.replaceAll('&', 'and').toLowerCase();
	slug = slug.replaceAll(' ', '-').toLowerCase();
	slug = slug.replace(/[^0-9a-zA-Z-]+/g, '');

	return slug;
};

export const webServiceUrl = () => {
	return 'https://fulfillmentws.realwebsite.com';
};
