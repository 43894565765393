import { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import Spinner from '../../spinner/Spinner';
import { toast } from 'react-toastify';
import { webServiceUrl } from '../../../CommonFunctions';

const ProjectExportDialog = ({ closeDialog }) => {
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	return (
		<Fragment>
			<ModalDialog>
				<form autoComplete="off">
					<ModalDialogContent align="start">
						<h2 className="mb-4">Project Assets Download</h2>
						<div className="row mb-3 px-2">Project files exported successfully!</div>
					</ModalDialogContent>
					<ModalDialogButtons align="start" shaded={true}>
						<Link
							to={`${webServiceUrl()}/projects/${projectId}/assets/${projectId}.zip`}
							className="btn btn-primary btn-lg mr-4"
							target="_blank"
						>
							<span>Download</span>
						</Link>
						<button type="button" className="btn btn-outline-primary btn-lg" onClick={closeDialog}>
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default ProjectExportDialog;
