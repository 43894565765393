import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetProjectSettings } from '../../../Service';
import ImageSVG from '../../imageUtil/ImageSVG';
import { createGuid, heroPreviewCss } from '../../../CommonFunctions';
import { toast } from 'react-toastify';
import './HeroPreview.css';

const HeroPreview = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const logoColorTransformFilter = 'logoColorTransformFilter';

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		let tempProjectData = {};

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				tempProjectData = settings;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData) {
			tempProjectData = projectData;

			tempProjectData = heroPreviewCss(tempProjectData);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	return (
		<Fragment>
			{projectData && (
				<div className="hero-preview-container">
					<Fragment>
						<div className="container-fluid container-topbar px-0">
							<div className="container">
								<nav className="navbar navbar-expand-lg navbar-light">
									<div className="container">
										{projectData.logo &&
										projectData.logo.layout && (
											<Fragment>
												<div className={`logo-preview-area ${projectData.logo.layout}`}>
													<div
														className="icon-preview"
														style={
															projectData.logo.iconUploaded ? (
																{ padding: 0 }
															) : (
																projectData.logo.cssStyleIcon
															)
														}
													>
														{projectData.logo.layout === 'horizontal' ? (
															<Fragment>
																{projectData.logo.iconUploaded ? (
																	<img
																		src={`${projectData.logo
																			.iconUploaded}?v=${createGuid()}`}
																	/>
																) : projectData.logo.horizontal.iconPreview ? (
																	<img
																		src={`${projectData.logo.horizontal
																			.iconPreview}?v=${createGuid()}`}
																		style={projectData.logo.cssStyleIconSize}
																	/>
																) : projectData.logo.horizontal.icon ? (
																	<img
																		src={`${projectData.logo.horizontal
																			.icon}?v=${createGuid()}`}
																		style={projectData.logo.cssStyleIconSize}
																	/>
																) : (
																	''
																)}
															</Fragment>
														) : (
															<Fragment>
																{projectData.logo.iconUploaded ? (
																	<img
																		src={`${projectData.logo
																			.iconUploaded}?v=${createGuid()}`}
																	/>
																) : projectData.logo.vertical.iconPreview ? (
																	<img
																		src={`${projectData.logo.vertical
																			.iconPreview}?v=${createGuid()}`}
																		style={projectData.logo.cssStyleIconSize}
																	/>
																) : projectData.logo.vertical.icon ? (
																	<img
																		src={`${projectData.logo.vertical
																			.icon}?v=${createGuid()}`}
																		style={projectData.logo.cssStyleIconSize}
																	/>
																) : (
																	''
																)}
															</Fragment>
														)}
													</div>
													{!projectData.logo.iconUploaded && (
														<Fragment>
															{projectData.logo.fontTypePreview ? (
																<div
																	className="text-preview"
																	style={{
																		...projectData.logo.cssStyleText,
																		...{
																			fontFamily :
																				projectData.logo.fontTypePreview
																		}
																	}}
																>
																	{projectData.company}
																</div>
															) : (
																<div
																	className="text-preview"
																	style={projectData.logo.cssStyleText}
																>
																	{projectData.company}
																</div>
															)}
														</Fragment>
													)}
												</div>
											</Fragment>
										)}
									</div>
								</nav>
							</div>
						</div>
						{projectData.hero && (
							<div
								className="container-fluid section-hero backdrop px-0 py-3"
								style={
									projectData.hero.imageUploaded ? (
										{
											backgroundImage : `url(${projectData.hero.imagePreview
												? projectData.hero.imagePreview
												: projectData.hero.imageUploaded})`
										}
									) : (
										{
											backgroundImage : `url(${projectData.hero.imagePreview
												? projectData.hero.imagePreview
												: projectData.hero.image})`
										}
									)
								}
							>
								<div className="container">
									<div className="row">
										<div className="col-sm-12 align-self-center text-center">
											{projectData.hero.headlineFontTypePreview ? (
												<h1
													className="headline"
													style={{
														...projectData.hero.cssHeadlineStyle,
														...{ fontFamily: projectData.hero.headlineFontTypePreview }
													}}
													dangerouslySetInnerHTML={{
														__html : projectData.hero.headline
													}}
												/>
											) : (
												<h1
													className="headline"
													style={projectData.hero.cssHeadlineStyle}
													dangerouslySetInnerHTML={{
														__html : projectData.hero.headline
													}}
												/>
											)}

											<div className="hero-buttons text-center">
												<a
													className="btn btn-lg btn-primary cta-button"
													style={{
														backgroundColor : projectData.primaryColor
															? projectData.primaryColor
															: '#000000'
													}}
												>
													<span>
														<i
															className="fas fa-phone-square-alt me-2"
															aria-hidden="true"
														/>Call: (800) 555-5555
													</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<ImageSVG id={logoColorTransformFilter} hexColor={projectData.primaryColor} />
					</Fragment>
				</div>
			)}
		</Fragment>
	);
};

export default HeroPreview;
