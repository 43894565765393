import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetProjectSettings } from '../../../Service';
import { toast } from 'react-toastify';
import './BlogPostPreview.css';

const BlogPostPreview = () => {
	const [ { projectData, blogPostShowTabImages }, dispatch ] = useStateValue();
	const [ blogPostItem, setBlogPostItem ] = useState(null);
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const blogPostId = match.params.blogPostId;

	useEffect(() => {
		loadProjectSettings();
	}, []);

	const loadProjectSettings = async () => {
		let tempProjectData = {};

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				tempProjectData = settings;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData) {
			tempProjectData = projectData;

			let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

			setBlogPostItem(item);
		}
	};

	return (
		<Fragment>
			{blogPostItem && (
				<Fragment>
					<nav>
						<div className="nav nav-tabs" id="nav-tab" role="tablist">
							<button
								className={`nav-link ${blogPostShowTabImages ? '' : 'active'}`}
								id="nav-content-tab"
								data-bs-toggle="tab"
								data-bs-target="#nav-content"
								type="button"
								role="tab"
								aria-controls="nav-content"
								aria-selected="true"
							>
								Content
							</button>
							<button
								className={`nav-link ${blogPostShowTabImages ? 'active' : ''}`}
								id="nav-images-tab"
								data-bs-toggle="tab"
								data-bs-target="#nav-images"
								type="button"
								role="tab"
								aria-controls="nav-images"
								aria-selected="false"
							>
								Images
							</button>
						</div>
					</nav>
					<div className="tab-content blogposts-tab" id="tabBlogPostPreview">
						<div
							className={`tab-pane fade ${blogPostShowTabImages ? '' : 'active show'}`}
							id="nav-content"
							role="tabpanel"
							aria-labelledby="nav-content-tab"
						>
							<div className="blogpost-preview-container p-3">
								<div
									className="container-fluid px-2 py-4 w-50"
									dangerouslySetInnerHTML={{
										__html : blogPostItem.contentPreview
											? blogPostItem.contentPreview
											: blogPostItem.content
									}}
								/>
							</div>
						</div>
						<div
							className={`tab-pane fade ${!blogPostShowTabImages ? '' : 'active show'}`}
							id="nav-images"
							role="tabpanel"
							aria-labelledby="nav-images-tab"
						>
							<div className="blogpost-preview-container px-5 pt-4 pb-5">
								<div
									className="container-fluid section-blogpost backdrop px-0 py-3 mb-4"
									style={
										blogPostItem.inheritHeroImage &&
										blogPostItem.inheritHeroImage === true &&
										projectData &&
										projectData.hero ? projectData.hero.imageUploaded ? (
											{
												backgroundImage : `url(${projectData.hero.imageUploaded})`
											}
										) : (
											{
												backgroundImage : `url(${projectData.hero.image})`
											}
										) : blogPostItem.imageUploaded ? (
											{
												backgroundImage : `url(${blogPostItem.imagePreview
													? blogPostItem.imagePreview
													: blogPostItem.imageUploaded})`
											}
										) : (
											{
												backgroundImage : `url(${blogPostItem.imagePreview
													? blogPostItem.imagePreview
													: blogPostItem.image})`
											}
										)
									}
								>
									<div className="container">
										<div className="row">
											<div className="col-sm-12 align-self-center text-center">
												{blogPostItem.title && (
													<h1
														className="headline"
														dangerouslySetInnerHTML={{
															__html : blogPostItem.title
														}}
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default BlogPostPreview;
