import { Fragment, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from './../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { toast } from 'react-toastify';
import { GetProjectSettings, SaveProjectSettings } from '../../../Service';
import { UploadFile } from '../../../Service';
import BlogPostContent from '../blogPostContent/BlogPostContent';
import BlogPostImagePreview from '../../preview/blogPost/BlogPostPreview';
import './BlogPostDetails.css';
import { webServiceUrl } from '../../../CommonFunctions';
import ImageSelect from '../../imageSelect/ImageSelect';
import FormTitleContainer from '../../formTitleContainer/FormTitleContainer';

const BlogPostDetails = () => {
	const [ { projectData, showImageSelect, blogPostShowContent }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ blogPostItem, setBlogPostItem ] = useState(null);
	const [ formData, setFormData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const blogPostId = match.params.blogPostId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ blogPostId ]
	);

	useEffect(
		() => {
			if (projectData && projectData.blogPosts) {
				let item = projectData.blogPosts.find((x) => x.guid === blogPostId);

				setBlogPostItem(item);

				setFormData({
					Title              : item.title,
					Keywords           : item.keywords,
					SourceArticleURL   : item.sourceArticleURL,
					ContentOrderID     : item.contentOrderID,
					ContentOrderStatus : item.contentOrderStatus,
					InheritHeroImage   : item.inheritHeroImage,
					Completed          : item.completed
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if ((target === 'InheritHeroImage' || target === 'Completed') && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.blogPosts) {
			let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

			if (target === 'Title') {
				item.title = value;
			} else if (target === 'Keywords') {
				item.keywords = value;
			} else if (target === 'SourceArticleURL') {
				item.sourceArticleURL = value;
			} else if (target === 'ContentOrderID') {
				item.contentOrderID = value;
			} else if (target === 'ContentOrderStatus') {
				item.contentOrderStatus = value;
			} else if (target === 'InheritHeroImage') {
				item.inheritHeroImage = checked;
			} else if (target === 'Completed') {
				item.completed = checked;
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = {};

		if (projectData) {
			tempProjectData = projectData;
		} else {
			let data = {};
			data.ProjectId = projectId;
			tempProjectData = await GetProjectSettings(data);
		}

		if (tempProjectData !== null) {
			if (tempProjectData && tempProjectData.blogPosts) {
				let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

				if (item) {
					item.title = formData.Title;
					item.name = formData.Title;
					item.keywords = formData.Keywords;
					item.sourceArticleURL = formData.SourceArticleURL;
					item.contentOrderID = formData.ContentOrderID;
					item.contentOrderStatus = formData.ContentOrderStatus;
					item.inheritHeroImage = formData.InheritHeroImage;
					item.completed = formData.Completed;

					//update the json
					let data = {};
					data.ProjectId = projectId;
					data.ProjectJson = JSON.stringify(tempProjectData);

					const serviceResponse = await SaveProjectSettings(data);

					if (serviceResponse.IsSuccessful) {
						toast.success('Settings updated successfully.');
					}
				}
			}
		}

		setIsSaving(false);
	};

	const onImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeImageUpload = (e) => {
		e.preventDefault();

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.blogPosts) {
			let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

			if (item && item.imageUploaded) {
				delete item.imageUploaded;

				setBlogPostItem(item);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const onImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.blogPosts) {
				let item = tempProjectData.blogPosts.find((x) => x.guid === blogPostId);

				if (item) {
					let data = new FormData();
					data.append('ProjectId', tempProjectData.guid);
					data.append('FileName', 'blogpost-hero-' + tempProjectData.guid);
					data.append('UploadedFile', file);

					const response = await UploadFile(data);

					if (response.IsSuccessful) {
						let imageUrl =
							webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

						item.imageUploaded = imageUrl;

						delete item.image;
						delete item.imagePreview;

						dispatch({
							type        : actionTypes.SET_PROJECT_DATA,
							projectData : tempProjectData
						});
					}
				}
			}
		}
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
		dispatch({
			type                  : actionTypes.SHOW_BLOGPOST_TAB_IMAGES,
			blogPostShowTabImages : true
		});
	};

	const openContent = () => {
		dispatch({
			type                : actionTypes.SHOW_BLOGPOST_CONTENT,
			blogPostShowContent : true
		});
	};

	return (
		<Fragment>
			<div className="left-container">
				{formData && (
					<Fragment>
						{showImageSelect ? (
							<ImageSelect type="blogPost" />
						) : blogPostShowContent ? (
							<BlogPostContent />
						) : (
							<Fragment>
								<form onSubmit={handleSubmit}>
									<div className="form-fields-wrapper">
										<FormTitleContainer title="Blog Post Details" />
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Title">Title</label>
												<textarea
													className="form-control"
													name="Title"
													value={formData.Title}
													placeholder="Enter title"
													onChange={handleChange}
													rows="3"
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Keywords">Keywords</label>
												<textarea
													className="form-control"
													name="Keywords"
													value={formData.Keywords}
													placeholder="Enter keywords (one per line)"
													onChange={handleChange}
													rows="3"
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="SourceArticleURL">Source Article URL</label>
												<input
													type="text"
													className="form-control"
													name="SourceArticleURL"
													value={formData.SourceArticleURL}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="ContentOrderID">Content Order ID</label>
												<input
													type="text"
													className="form-control"
													name="ContentOrderID"
													value={formData.ContentOrderID}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="ContentOrderStatus">Content Order Status</label>
												<select
													name="ContentOrderStatus"
													className="form-select"
													value={formData.ContentOrderStatus}
													onChange={handleChange}
												>
													<option defaultValue />
													<option value="In Progress">In Progress</option>
													<option value="Completed">Completed</option>
												</select>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<div className="form-check">
													<input
														type="checkbox"
														name="InheritHeroImage"
														className="form-check-input"
														defaultChecked={formData.InheritHeroImage}
														onClick={handleChange}
													/>
													<label htmlFor="InheritHeroImage" className="form-check-label">
														Inherit Home Hero Image
													</label>
												</div>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-10 pe-0">
												<button
													type="button"
													onClick={openImages}
													className="btn btn-primary w-100"
													title="Select Image"
												>
													<span>Image</span>
												</button>
											</div>
											<div className="col-2">
												{blogPostItem && blogPostItem.imageUploaded ? (
													<button
														type="button"
														onClick={removeImageUpload}
														className="btn btn-danger w-100"
													>
														<span>
															<i className="fas fa-trash-alt" />
														</span>
													</button>
												) : (
													<button
														type="button"
														onClick={onImageUpload}
														className="btn btn-primary w-100"
													>
														<span>
															<i className="fas fa-cloud-upload-alt" />
														</span>
													</button>
												)}
												<input
													type="file"
													onChange={onImageChange}
													className="d-none"
													id="uploadImage"
												/>
											</div>
										</div>
										<div className="row mb-0 px-2">
											<div className="col-12">
												<button
													type="button"
													onClick={openContent}
													className="btn btn-primary w-100"
													title="Build Content"
												>
													<span>Content</span>
												</button>
											</div>
										</div>
									</div>
									<div className="form-buttons-wrapper">
										<div className="row mb-0 px-2">
											<div className="col-10">
												<button type="submit" className="btn btn-primary btn-lg me-3">
													Save
												</button>
												<Link
													to={`/project/${projectId}`}
													className="btn btn-outline-primary btn-lg me-3"
												>
													<span>Close</span>
												</Link>
											</div>
											<div className="col-2 d-flex justify-content-end align-items-center">
												<input
													type="checkbox"
													name="Completed"
													className="form-check-input check-completed"
													defaultChecked={formData.Completed}
													onClick={handleChange}
													title="Is Blog Post Completed?"
												/>
											</div>
										</div>
									</div>
								</form>
							</Fragment>
						)}
					</Fragment>
				)}
			</div>
			<div className="right-container">
				<BlogPostImagePreview />
			</div>
		</Fragment>
	);
};

export default BlogPostDetails;
