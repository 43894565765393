import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import HeroForm from '../heroForm/HeroForm';
import HeroPreview from '../../preview/heroPreview/HeroPreview';
import { GetProjectSettings } from '../../../Service';
import { toast } from 'react-toastify';
import './HeroMainPage.css';

const HeroMainPage = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	const loadProjectSettings = async () => {
		let tempProjectData = {};

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				tempProjectData = settings;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	return (
		<Fragment>
			{projectData &&
			projectData.hero &&
			projectData.logo && (
				<Fragment>
					<div className="left-container">
						<HeroForm />
					</div>
					<div className="right-container">
						<HeroPreview />
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default HeroMainPage;
