import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from '../login/Login';
import Header from '../header/Header';
import DefaultPage from '../defaultPage/DefaultPage';
import ProjectDetails from '../projects/projectDetails/ProjectDetails';
import CompanyDetails from '../company/CompanyDetails';
import LogoMainPage from '../logo/logoMainPage/LogoMainPage';
import HeroMainPage from '../hero/heroMainPage/HeroMainPage';
import ServiceDetails from '../services/serviceDetails/ServiceDetails';
import BlogPostDetails from '../blogPosts/blogPostDetails/BlogPostDetails';
import TestimonialDetails from '../testimonials/testimonialDetails/TestimonialDetails';
import Page404 from '../404/Page404';
import './MainApp.css';

const MainApp = () => {
	return (
		<Fragment>
			<div className="main-app">
				<div className="main-app-container">
					<Header />
					<div className="content-container">
						<Switch>
							<Route path="/login">
								<Login />
							</Route>
							<Route path="/project/:projectId/blog-posts/:blogPostId">
								<BlogPostDetails />
							</Route>
							<Route path="/project/:projectId/services/:serviceId">
								<ServiceDetails />
							</Route>
							<Route path="/project/:projectId/testimonials/:testimonialId">
								<TestimonialDetails />
							</Route>
							<Route path="/project/:projectId" exact>
								<ProjectDetails />
							</Route>
							{/* <Route path="/project/:projectId/company-info">
									<CompanyDetails />
								</Route> */}
							<Route path="/project/:projectId/logo">
								<LogoMainPage />
							</Route>
							<Route path="/project/:projectId/hero">
								<HeroMainPage />
							</Route>
							{/* <Route path="/project/:projectId/seo">
									<SEODetails />
								</Route> */}
							<Route path="/" exact>
								<DefaultPage />
							</Route>
							<Route path="*">
								<Page404 />
							</Route>
						</Switch>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default MainApp;
