import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetProjectSettings } from '../../../Service';
import { SaveProjectSettings } from '../../../Service';
import Spinner from '../../spinner/Spinner';
import { logoPreviewCss, createGuid } from '../../../CommonFunctions';
import { toast } from 'react-toastify';
import './LogoForm.css';
import FormTitleContainer from '../../formTitleContainer/FormTitleContainer';

const LogoForm = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ primaryColorOutput, setPrimaryColorOutput ] = useState('');
	const [ secondaryColorOutput, setSecondaryColorOutput ] = useState('');
	const [ formData, setFormData ] = useState({});
	const [ layout, setLayout ] = useState('horizontal');
	const history = useHistory();
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	useEffect(
		() => {
			setIsLoading(true);

			if (projectData) {
				setFormData({
					CompanyName          : projectData.company,
					Color                : projectData.primaryColor,
					ColorOutput          : primaryColorOutput,
					SecondaryColor       : projectData.secondaryColor,
					SecondaryColorOutput : secondaryColorOutput,
					Completed            : projectData.logo.completed,
					Layout               : projectData.logo.layout ? projectData.logo.layout : 'horizontal',
					FontType             : projectData.logo.fontType,
					FontSizeH            :
						projectData.logo.horizontal && projectData.logo.horizontal.fontSize
							? projectData.logo.horizontal.fontSize
							: '24',
					FontWeightH          :
						projectData.logo.horizontal && projectData.logo.horizontal.fontWeight
							? projectData.logo.horizontal.fontWeight
							: 'normal',
					IconH                :
						projectData.logo.horizontal && projectData.logo.horizontal.icon
							? projectData.logo.horizontal.icon
							: '',
					IconSizeH            :
						projectData.logo.horizontal && projectData.logo.horizontal.iconSize
							? projectData.logo.horizontal.iconSize
							: '64',
					BorderTypeH          :
						projectData.logo.horizontal && projectData.logo.horizontal.borderType
							? projectData.logo.horizontal.borderType
							: 'None',
					BorderWidthH         :
						projectData.logo.horizontal && projectData.logo.horizontal.borderWidth
							? projectData.logo.horizontal.borderWidth
							: '1',
					BorderPaddingH       :
						projectData.logo.horizontal && projectData.logo.horizontal.borderPadding
							? projectData.logo.horizontal.borderPadding
							: '0',
					XTextPositionH       :
						projectData.logo.horizontal && projectData.logo.horizontal.xTextPosition
							? projectData.logo.horizontal.xTextPosition
							: '0',
					YTextPositionH       :
						projectData.logo.horizontal && projectData.logo.horizontal.yTextPosition
							? projectData.logo.horizontal.yTextPosition
							: '0',
					FontSizeV            :
						projectData.logo.vertical && projectData.logo.vertical.fontSize
							? projectData.logo.vertical.fontSize
							: '16',
					FontWeightV          :
						projectData.logo.vertical && projectData.logo.vertical.fontWeight
							? projectData.logo.vertical.fontWeight
							: 'normal',
					IconV                :
						projectData.logo.vertical && projectData.logo.vertical.icon
							? projectData.logo.vertical.icon
							: '',
					IconSizeV            :
						projectData.logo.vertical && projectData.logo.vertical.iconSize
							? projectData.logo.vertical.iconSize
							: '128',
					BorderTypeV          :
						projectData.logo.vertical && projectData.logo.vertical.borderType
							? projectData.logo.vertical.borderType
							: 'None',
					BorderWidthV         :
						projectData.logo.vertical && projectData.logo.vertical.borderWidth
							? projectData.logo.vertical.borderWidth
							: '1',
					BorderPaddingV       :
						projectData.logo.vertical && projectData.logo.vertical.borderPadding
							? projectData.logo.vertical.borderPadding
							: '0',
					XTextPositionV       :
						projectData.logo.vertical && projectData.logo.vertical.xTextPosition
							? projectData.logo.vertical.xTextPosition
							: '0',
					YTextPositionV       :
						projectData.logo.vertical && projectData.logo.vertical.yTextPosition
							? projectData.logo.vertical.yTextPosition
							: '0'
				});

				setPrimaryColorOutput(projectData.primaryColor);
				setSecondaryColorOutput(projectData.secondaryColor);
				setLayout(projectData.logo.layout ? projectData.logo.layout : 'horizontal');
			}

			setIsLoading(false);
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			setIsLoading(true);

			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}

			setIsLoading(false);
		}
	};

	useEffect(
		() => {
			if (projectData) {
				let tempProjectData = logoPreviewCss(projectData, 'logo');

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		},
		[ projectData ]
	);

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value,
				Icon     : projectData.logo.icon
			});
		}

		let tempProjectData = projectData;

		if (target === 'Color' || target === 'ColorOutput') {
			tempProjectData.primaryColor = value;
			setPrimaryColorOutput(value);
		} else if (target === 'SecondaryColor' || target === 'SecondaryColorOutput') {
			tempProjectData.secondaryColor = value;
			setSecondaryColorOutput(value);
		} else if (target === 'CompanyName') {
			tempProjectData.company = value;
		} else if (target === 'Completed') {
			tempProjectData.logo.completed = checked;
		} else if (target === 'FontType') {
			tempProjectData.logo.fontType = value;
		} else if (target === 'Layout') {
			tempProjectData.logo.layout = value;
			setLayout(value);
			setIsLoading(true);
		} else if (target === 'FontSizeH') {
			tempProjectData.logo.horizontal.fontSize = value;
		} else if (target === 'FontWeightH') {
			tempProjectData.logo.horizontal.fontWeight = value;
		} else if (target === 'IconH') {
			tempProjectData.logo.horizontal.icon = value;
		} else if (target === 'IconSizeH') {
			tempProjectData.logo.horizontal.iconSize = value;
		} else if (target === 'BorderTypeH') {
			tempProjectData.logo.horizontal.borderType = value;
		} else if (target === 'BorderWidthH') {
			tempProjectData.logo.horizontal.borderWidth = value;
		} else if (target === 'BorderPaddingH') {
			tempProjectData.logo.horizontal.borderPadding = value;
		} else if (target === 'XTextPositionH') {
			tempProjectData.logo.horizontal.xTextPosition = value;
		} else if (target === 'YTextPositionH') {
			tempProjectData.logo.horizontal.yTextPosition = value;
		} else if (target === 'FontSizeV') {
			tempProjectData.logo.vertical.fontSize = value;
		} else if (target === 'FontWeightV') {
			tempProjectData.logo.vertical.fontWeight = value;
		} else if (target === 'IconV') {
			tempProjectData.logo.vertical.icon = value;
		} else if (target === 'IconSizeV') {
			tempProjectData.logo.vertical.iconSize = value;
		} else if (target === 'BorderTypeV') {
			tempProjectData.logo.vertical.borderType = value;
		} else if (target === 'BorderWidthV') {
			tempProjectData.logo.vertical.borderWidth = value;
		} else if (target === 'BorderPaddingV') {
			tempProjectData.logo.vertical.borderPadding = value;
		} else if (target === 'XTextPositionV') {
			tempProjectData.logo.vertical.xTextPosition = value;
		} else if (target === 'YTextPositionV') {
			tempProjectData.logo.vertical.yTextPosition = value;
		}

		//remove uploaded image
		if (tempProjectData.logo.horizontal) {
			delete tempProjectData.logo.horizontal.iconUploaded;
		}

		if (tempProjectData.logo.vertical) {
			delete tempProjectData.logo.vertical.iconUploaded;
		}

		tempProjectData = logoPreviewCss(tempProjectData, 'logo');

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});
	};

	const onFontSizeHInput = (e) => {
		document.getElementById('fontSizeHOutput').value = e.currentTarget.value + 'px';
	};

	const onIconSizeHInput = (e) => {
		document.getElementById('iconSizeHOutput').value = e.currentTarget.value + 'px';
	};

	const onBorderWidthHInput = (e) => {
		document.getElementById('borderWidthHOutput').value = e.currentTarget.value + 'px';
	};

	const onBorderPaddingHInput = (e) => {
		document.getElementById('borderPaddingHOutput').value = e.currentTarget.value + 'px';
	};

	const onXTextPositionHInput = (e) => {
		document.getElementById('xTextPositionHOutput').value = e.currentTarget.value + 'px';
	};

	const onYTextPositionHInput = (e) => {
		document.getElementById('yTextPositionHOutput').value = e.currentTarget.value + 'px';
	};

	const onFontSizeVInput = (e) => {
		document.getElementById('fontSizeVOutput').value = e.currentTarget.value + 'px';
	};

	const onIconSizeVInput = (e) => {
		document.getElementById('iconSizeVOutput').value = e.currentTarget.value + 'px';
	};

	const onBorderWidthVInput = (e) => {
		document.getElementById('borderWidthVOutput').value = e.currentTarget.value + 'px';
	};

	const onBorderPaddingVInput = (e) => {
		document.getElementById('borderPaddingVOutput').value = e.currentTarget.value + 'px';
	};

	const onXTextPositionVInput = (e) => {
		document.getElementById('xTextPositionVOutput').value = e.currentTarget.value + 'px';
	};

	const onYTextPositionVInput = (e) => {
		document.getElementById('yTextPositionVOutput').value = e.currentTarget.value + 'px';
	};

	const openGoogleFonts = () => {
		dispatch({
			type          : actionTypes.SHOW_LOGO_FONTS,
			logoShowFonts : true
		});
	};

	const openIcons = () => {
		dispatch({
			type          : actionTypes.SHOW_LOGO_ICONS,
			logoShowIcons : true
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;
		tempProjectData.primaryColor = formData.Color;
		tempProjectData.secondaryColor = formData.SecondaryColor;
		tempProjectData.company = formData.CompanyName;
		tempProjectData.logo.completed = formData.Completed;
		tempProjectData.logo.fontType = formData.FontType;
		tempProjectData.logo.layout = formData.Layout;

		tempProjectData.logo.horizontal.fontSize = formData.FontSizeH;
		tempProjectData.logo.horizontal.fontWeight = formData.FontWeightH;
		tempProjectData.logo.horizontal.iconSize = formData.IconSizeH;
		tempProjectData.logo.horizontal.borderType = formData.BorderTypeH;
		tempProjectData.logo.horizontal.borderWidth = formData.BorderWidthH;
		tempProjectData.logo.horizontal.borderPadding = formData.BorderPaddingH;
		tempProjectData.logo.horizontal.xTextPositionH = formData.XTextPositionH;
		tempProjectData.logo.horizontal.yTextPositionH = formData.YTextPositionH;

		tempProjectData.logo.vertical.fontSize = formData.FontSizeV;
		tempProjectData.logo.vertical.fontWeight = formData.FontWeightV;
		tempProjectData.logo.vertical.iconSize = formData.IconSizeV;
		tempProjectData.logo.vertical.borderType = formData.BorderTypeV;
		tempProjectData.logo.vertical.borderWidth = formData.BorderWidthV;
		tempProjectData.logo.vertical.borderPadding = formData.BorderPaddingV;
		tempProjectData.logo.vertical.xTextPositionH = formData.XTextPositionV;
		tempProjectData.logo.vertical.yTextPositionH = formData.YTextPositionV;

		//set favicon completed
		if (!tempProjectData.favicon) {
			tempProjectData.favicon = {};
		}
		tempProjectData.favicon.completed = true;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.primaryColor = tempProjectData.primaryColor;
			settings.secondaryColor = tempProjectData.secondaryColor;
			settings.company = tempProjectData.company;
			settings.logo = tempProjectData.logo;

			//update the json
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');
			}
		}

		setIsSaving(false);
	};

	const closeLogoPage = (e) => {
		if (projectData) {
			let tempProjectData = projectData;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}

		history.push(`/project/${projectId}`);
	};

	return (
		<Fragment>
			{formData &&
			projectData &&
			projectData.logo && (
				<form onSubmit={handleSubmit}>
					<div className="form-fields-wrapper">
						<FormTitleContainer title="Logo Details" />
						<div className="row mb-0 px-2">
							<div className="col-6 pe-0">
								<label htmlFor="Color">Primary Color</label>
							</div>
							<div className="col-6 pe-0">
								<label htmlFor="Color">Secondary Color</label>
							</div>
						</div>
						<div className="row mb-3 px-2">
							<div className="col-3 pe-0">
								<input
									type="color"
									className="form-control form-control-color w-100"
									name="Color"
									value={formData.Color}
									onChange={handleChange}
								/>
							</div>
							<div className="col-3 ps-1 d-flex align-items-end">
								<label htmlFor="ColorOutput" />
								<input
									type="text"
									className="form-control"
									name="ColorOutput"
									value={primaryColorOutput}
									placeholder=""
									onChange={handleChange}
								/>
							</div>
							<div className="col-3 pe-0">
								<input
									type="color"
									className="form-control form-control-color w-100"
									name="SecondaryColor"
									value={formData.SecondaryColor}
									onChange={handleChange}
								/>
							</div>
							<div className="col-3 ps-1 d-flex align-items-end">
								<label htmlFor="SecondaryColorOutput" />
								<input
									type="text"
									className="form-control"
									name="SecondaryColorOutput"
									value={secondaryColorOutput}
									placeholder=""
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<div className="col-12">
								<label htmlFor="CompanyName">Company Name</label>
								<input
									type="text"
									className="form-control"
									name="CompanyName"
									value={formData.CompanyName}
									placeholder="Enter company name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<label htmlFor="FontType">Font Type</label>
							<div className="input-group mb-0">
								<input
									type="text"
									className="form-control"
									name="FontType"
									value={formData.FontType}
									onChange={handleChange}
								/>
								<button
									type="submit"
									className="btn btn-primary"
									onClick={openGoogleFonts}
									title="Select Font"
								>
									<i className="fas fa-font" />
								</button>
							</div>
						</div>
						<div className="row mb-4 px-2">
							<label htmlFor="FontWeight">Layout</label>
							<div className="col-12">
								<div className="form-check form-check-inline">
									<input
										className="form-check-input"
										type="radio"
										name="Layout"
										id="logoLayoutHorizontal"
										value="horizontal"
										checked={layout === 'horizontal'}
										onChange={handleChange}
									/>
									<label className="form-check-label" htmlFor="logoLayoutHorizontal">
										Horizontal
									</label>
								</div>
								<div className="form-check form-check-inline">
									<input
										className="form-check-input"
										type="radio"
										name="Layout"
										id="logoLayoutVertical"
										value="vertical"
										checked={layout === 'vertical'}
										onChange={handleChange}
									/>
									<label className="form-check-label" htmlFor="logoLayoutVertical">
										Vertical
									</label>
								</div>
							</div>
						</div>
						{layout === 'horizontal' ? (
							<Fragment>
								<div className="row mb-4 px-2">
									<div className="col-5">
										<label htmlFor="FontWeightH">Font Weight</label>
										<select
											name="FontWeightH"
											className="form-select"
											value={formData.FontWeightH}
											onChange={handleChange}
										>
											<option defaultValue>Choose...</option>
											<option value="normal">normal</option>
											<option value="bold">bold</option>
											<option value="bolder">bolder</option>
											<option value="lighter">lighter</option>
											<option value="100">100</option>
											<option value="200">200</option>
											<option value="300">300</option>
											<option value="400">400</option>
											<option value="500">500</option>
											<option value="600">600</option>
											<option value="700">700</option>
											<option value="800">800</option>
											<option value="900">900</option>
										</select>
									</div>
									<div className="col-7">
										<div className="row mb-0 px-2">
											<label htmlFor="FontSizeH">Font Size</label>
											<div className="col-9">
												<input
													type="range"
													className="form-range mt-2"
													name="FontSizeH"
													min="1"
													max="120"
													value={formData.FontSizeH}
													onChange={handleChange}
													onInput={onFontSizeHInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="fontSizeHOutput"
													value={`${formData.FontSizeH}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-5">
										<label htmlFor="Icon">Icon</label>
										<div>
											<a onClick={() => openIcons(layout)} title="Select Icon">
												{projectData.logo.horizontal &&
												projectData.logo.horizontal &&
												projectData.logo.horizontal.icon ? (
													<img
														className="form-field-image"
														src={`${projectData.logo.horizontal.icon}?v=${createGuid()}`}
														width="48px"
													/>
												) : (
													<img
														className="form-field-image"
														src="/img/no_image.png"
														width="48px"
													/>
												)}
											</a>
										</div>
									</div>
									<div className="col-7">
										<div className="row mb-3 px-2">
											<label htmlFor="IconSizeH">Icon Size</label>
											<div className="col-9">
												<input
													type="range"
													className="form-range mt-2"
													name="IconSizeH"
													min="20"
													max="200"
													value={formData.IconSizeH}
													onChange={handleChange}
													onInput={onIconSizeHInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="iconSizeHOutput"
													value={`${formData.IconSizeH}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-4">
										<label htmlFor="BorderTypeH">Border</label>
										<select
											name="BorderTypeH"
											className="form-select"
											value={formData.BorderTypeH}
											onChange={handleChange}
										>
											<option defaultValue>None</option>
											<option value="Squared">Squared</option>
											<option value="Rounded">Rounded</option>
											<option value="Circled">Circled</option>
										</select>
									</div>
									<div className="col-4">
										<div className="row mb-0 px-2">
											<label htmlFor="BorderWidthH" className="ps-1">
												Border Width
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="BorderWidthH"
													min="1"
													max="20"
													value={formData.BorderWidthH}
													onChange={handleChange}
													onInput={onBorderWidthHInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="borderWidthHOutput"
													value={`${formData.BorderWidthH}px`}
													disabled
												/>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="row mb-0 px-2">
											<label htmlFor="BorderPaddingH" className="ps-1">
												Padding
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="BorderPaddingH"
													min="0"
													max="50"
													value={formData.BorderPaddingH}
													onChange={handleChange}
													onInput={onBorderPaddingHInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="borderPaddingHOutput"
													value={`${formData.BorderPaddingH}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-6">
										<div className="row mb-0 px-2">
											<label htmlFor="XTextPositionH" className="ps-1">
												Text Position (X)
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="XTextPositionH"
													min="-300"
													max="300"
													value={formData.XTextPositionH}
													onChange={handleChange}
													onInput={onXTextPositionHInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="xTextPositionHOutput"
													value={`${formData.XTextPositionH}px`}
													disabled
												/>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="row mb-0 px-2">
											<label htmlFor="YTextPositionH" className="ps-1">
												Text Position (Y)
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="YTextPositionH"
													min="-300"
													max="300"
													value={formData.YTextPositionH}
													onChange={handleChange}
													onInput={onYTextPositionHInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="yTextPositionHOutput"
													value={`${formData.YTextPositionH}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						) : (
							<Fragment>
								<div className="row mb-4 px-2">
									<div className="col-5">
										<label htmlFor="FontWeightV">Font Weight</label>
										<select
											name="FontWeightV"
											className="form-select"
											value={formData.FontWeightV}
											onChange={handleChange}
										>
											<option defaultValue>Choose...</option>
											<option value="normal">normal</option>
											<option value="bold">bold</option>
											<option value="bolder">bolder</option>
											<option value="lighter">lighter</option>
											<option value="100">100</option>
											<option value="200">200</option>
											<option value="300">300</option>
											<option value="400">400</option>
											<option value="500">500</option>
											<option value="600">600</option>
											<option value="700">700</option>
											<option value="800">800</option>
											<option value="900">900</option>
										</select>
									</div>
									<div className="col-7">
										<div className="row mb-0 px-2">
											<label htmlFor="FontSizeV">Font Size</label>
											<div className="col-9">
												<input
													type="range"
													className="form-range mt-2"
													name="FontSizeV"
													min="1"
													max="120"
													value={formData.FontSizeV}
													onChange={handleChange}
													onInput={onFontSizeVInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="fontSizeVOutput"
													value={`${formData.FontSizeV}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-5">
										<label htmlFor="Icon">Icon</label>
										<div>
											<a onClick={() => openIcons(layout)} title="Select Icon">
												{projectData.logo.vertical &&
												projectData.logo.vertical &&
												projectData.logo.vertical.icon ? (
													<img
														className="form-field-image"
														src={`${projectData.logo.vertical.icon}?v=${createGuid()}`}
														width="48px"
													/>
												) : (
													<img
														className="form-field-image"
														src="/img/no_image.png"
														width="48px"
													/>
												)}
											</a>
										</div>
									</div>
									<div className="col-7">
										<div className="row mb-3 px-2">
											<label htmlFor="IconSizeV">Icon Size</label>
											<div className="col-9">
												<input
													type="range"
													className="form-range mt-2"
													name="IconSizeV"
													min="20"
													max="200"
													value={formData.IconSizeV}
													onChange={handleChange}
													onInput={onIconSizeVInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="iconSizeVOutput"
													value={`${formData.IconSizeV}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-4">
										<label htmlFor="BorderTypeV">Border</label>
										<select
											name="BorderTypeV"
											className="form-select"
											value={formData.BorderTypeV}
											onChange={handleChange}
										>
											<option defaultValue>None</option>
											<option value="Squared">Squared</option>
											<option value="Rounded">Rounded</option>
											<option value="Circled">Circled</option>
										</select>
									</div>
									<div className="col-4">
										<div className="row mb-0 px-2">
											<label htmlFor="BorderWidthV" className="ps-1">
												Border Width
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="BorderWidthV"
													min="1"
													max="20"
													value={formData.BorderWidthV}
													onChange={handleChange}
													onInput={onBorderWidthVInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="borderWidthVOutput"
													value={`${formData.BorderWidthV}px`}
													disabled
												/>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="row mb-0 px-2">
											<label htmlFor="BorderPaddingV" className="ps-1">
												Padding
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="BorderPaddingV"
													min="0"
													max="50"
													value={formData.BorderPaddingV}
													onChange={handleChange}
													onInput={onBorderPaddingVInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="borderPaddingVOutput"
													value={`${formData.BorderPaddingV}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-4 px-2">
									<div className="col-6">
										<div className="row mb-0 px-2">
											<label htmlFor="XTextPositionV" className="ps-1">
												Text Position (X)
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="XTextPositionV"
													min="-300"
													max="300"
													value={formData.XTextPositionV}
													onChange={handleChange}
													onInput={onXTextPositionVInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="xTextPositionVOutput"
													value={`${formData.XTextPositionV}px`}
													disabled
												/>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="row mb-0 px-2">
											<label htmlFor="YTextPositionV" className="ps-1">
												Text Position (Y)
											</label>
											<div className="col-9 ps-1">
												<input
													type="range"
													className="form-range mt-2"
													name="YTextPositionV"
													min="-300"
													max="300"
													value={formData.YTextPositionV}
													onChange={handleChange}
													onInput={onYTextPositionVInput}
												/>
											</div>
											<div className="col-3 p-0">
												<input
													type="text"
													className="form-control text-center px-0"
													id="yTextPositionVOutput"
													value={`${formData.YTextPositionV}px`}
													disabled
												/>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						)}
					</div>
					<div className="form-buttons-wrapper">
						<div className="row mb-0 px-2">
							<div className="col-10">
								<button type="submit" className="btn btn-primary btn-lg me-3">
									<span>Save</span>
								</button>
								<button
									type="button"
									className="btn btn-outline-primary btn-lg me-3"
									onClick={closeLogoPage}
								>
									<span>Close</span>
								</button>
							</div>
							<div className="col-2 d-flex justify-content-end align-items-center">
								<input
									type="checkbox"
									name="Completed"
									className="form-check-input check-completed"
									defaultChecked={formData.Completed}
									onClick={handleChange}
									title="Is Logo Completed?"
								/>
							</div>
						</div>
					</div>
				</form>
			)}
		</Fragment>
	);
};

export default LogoForm;
