import { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import { createGuid } from '../../../CommonFunctions';
import Spinner from '../../spinner/Spinner';
import { SaveProjectSettings } from '../../../Service';
import { toast } from 'react-toastify';
import './AddServiceDialog.css';

const AddServiceDialog = ({ closeDialog, callback }) => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	const handleSubmit = async (e) => {
		//e.preventDefault();

		if (projectData) {
			if (formData.ServiceName) {
				setIsSaving(true);

				let serviceNames = [];
				var lines = formData.ServiceName.split('\n');

				for (var i = 0; i < lines.length; i++) {
					let line = lines[i];

					if (line && line !== '') {
						let serviceObj = {};
						serviceObj.guid = createGuid();
						serviceObj.name = line;

						serviceNames.push(serviceObj);
					}
				}

				let tempProjectData = projectData;

				if (tempProjectData.services) {
					tempProjectData.services = tempProjectData.services.concat(serviceNames);
				} else {
					tempProjectData.services = serviceNames;
				}

				//update the json
				let data = {};
				data.ProjectId = projectId;
				data.ProjectJson = JSON.stringify(tempProjectData);

				const serviceResponse = await SaveProjectSettings(data);

				if (serviceResponse.IsSuccessful) {
					toast.success('Service(s) created successfully.');

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
				}

				if (callback) {
					callback();
				}

				closeDialog();

				setIsSaving(false);
			} else {
				toast.error("Service Name can't be empty.");
			}
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent align="start">
					<h2 className="mb-4">Add Service(s)</h2>
					<div className="row mb-3 px-2">
						<div className="col-12">
							<label htmlFor="ServiceName">Service(s) Name</label>
							<textarea
								className="form-control"
								name="ServiceName"
								value={formData.ServiceName}
								placeholder="Enter one service per line"
								onChange={handleChange}
								rows="5"
							/>
						</div>
					</div>
				</ModalDialogContent>
				<ModalDialogButtons align="start" shaded={true}>
					<button
						type="button"
						onClick={handleSubmit}
						className="btn btn-primary btn-lg mr-4"
						disabled={isSaving}
					>
						{!isSaving ? (
							<Fragment>
								<span>Save</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<span>Saving...</span>
							</Fragment>
						)}
					</button>
					<button
						type="button"
						className="btn btn-outline-primary btn-lg"
						disabled={isSaving}
						onClick={closeDialog}
					>
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default AddServiceDialog;
