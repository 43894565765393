import axios from 'axios';
import { toast } from 'react-toastify';

//const serviceHost = 'https://localhost:44387/Services/';
// const serviceHost = 'http://localhost:63929/Services/';
const serviceHost = 'https://fulfillmentws.realwebsite.com/Services/';
const RWServiceHost = 'https://dev.realwebsite.com';
const websiteApiEndpoint = '/rest-api/';

export const instance = axios.create({ baseURL: serviceHost });

instance.interceptors.response.use(
	async (response) => {
		return response;
	},
	(error) => {
		if (error.response) {
			const { data, status } = error.response;

			switch (status) {
				case 400:
					if (data.errors) {
						for (const key in data.errors) {
							if (data.errors[key]) {
								toast.error(data.errors[key]);
							}
						}
					} else {
						toast.error(data);
					}
					break;
				case 401:
					toast.error('Unauthorized');
					break;
				case 404:
					toast.error('Not found');
					break;
				case 500:
					toast.error('An unknown error has occurred');
					break;
				default:
					toast.error('An unknown error has occurred');
					break;
			}
		} else {
			toast.error(error);
		}

		return Promise.reject(error);
	}
);

export const HandleAxiosException = (err) => {
	var customErrorMessage = 'An unknown error has occurred';

	let fullErrorMessage =
		err && err.response && err.response.data && err.response.data.Message
			? err.response.data.Message
			: err.response && err.response.data && err.response.data.title
				? err.response.data.title
				: customErrorMessage;
	console.log(fullErrorMessage);

	var response = {};
	response['error'] = fullErrorMessage;
	return response;
};

export const ValidateUser = async (data) => {
	try {
		const response = await instance
			.post(RWServiceHost + '/Services/UsersService.asmx/ValidateUser', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

//Projects -----------
export const GetAllProjects = async () => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/GetAllProjects', null, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		let jsonResponse = response.data.d;

		let responseObj = JSON.parse(jsonResponse.Data);

		return responseObj;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const GetProjectSettings = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/GetProjectSettings', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		let jsonResponse = response.data.d;

		let responseObj = JSON.parse(jsonResponse.Data);

		return responseObj;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const SaveProjectSettings = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/SaveProjectSettings', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const CopyProjectSettings = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/CopyProjectSettings', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const DeleteProject = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/DeleteProject', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const DownloadFile = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/DownloadFile', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const UploadFile = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/UploadFile', data, {
				headers : {
					'Content-Type' : 'application/json' //'multipart/form-data; boundary=---------------------------41184676334',
					//Accept         : 'application/json; charset=utf-8'
				}
			})
			.then((response) => {
				return response;
			});

		let response1XmlString = response.data;

		let parser = new DOMParser();
		let xmlDoc = parser.parseFromString(response1XmlString, 'text/xml');

		if (xmlDoc) {
			const nodeIsSuccessful = xmlDoc.getElementsByTagName('IsSuccessful');

			if (nodeIsSuccessful && nodeIsSuccessful[0] && nodeIsSuccessful[0].childNodes) {
				const isSuccessful = nodeIsSuccessful[0].childNodes[0].nodeValue;

				if (isSuccessful) {
					const filename = xmlDoc.getElementsByTagName('Data')[0].childNodes[0].nodeValue;

					const response1 = {};
					response1.IsSuccessful = true;
					response1.Data = filename;

					return response1;
				}
			}
		}

		const response2 = {};
		response2.IsSuccessful = false;
		response2.Message = 'Could not upload the file.';
		return response2;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const UploadExportedFile = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/UploadExportedFile', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		let response1XmlString = response.data;

		let parser = new DOMParser();
		let xmlDoc = parser.parseFromString(response1XmlString, 'text/xml');

		if (xmlDoc) {
			const nodeIsSuccessful = xmlDoc.getElementsByTagName('IsSuccessful');

			if (nodeIsSuccessful && nodeIsSuccessful[0] && nodeIsSuccessful[0].childNodes) {
				const isSuccessful = nodeIsSuccessful[0].childNodes[0].nodeValue;

				if (isSuccessful) {
					const filename = xmlDoc.getElementsByTagName('Data')[0].childNodes[0].nodeValue;

					const response1 = {};
					response1.IsSuccessful = true;
					response1.Data = filename;

					return response1;
				}
			}
		}

		const response2 = {};
		response2.IsSuccessful = false;
		response2.Message = 'Could not upload the file.';
		return response2;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const ExportBase64Data = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/ExportBase64Data', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const ExportProjectSettings = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/ExportProjectSettings', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const CreateFulfillmentRequest = async (data) => {
	try {
		const response = await instance
			.post(RWServiceHost + '/Services/UsersService.asmx/CreateFulfillmentRequest', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const CreateFulfillmentRequestV3 = async (data) => {
	try {
		const response = await instance
			.post(RWServiceHost + '/Services/UsersService.asmx/CreateFulfillmentRequestV3', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const ImportFulfillmentAppSettings = async (data) => {
	try {
		const response = await instance
			.post(RWServiceHost + '/Services/UsersService.asmx/ImportFulfillmentAppSettings', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const ImportSettingsOnWebsite = async (websiteUrl, data) => {
	try {
		const response = await instance
			.post(websiteUrl + websiteApiEndpoint + '/import', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (err) {
		return HandleAxiosException(err);
	}
};

export const OptimizeImage = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'Service.asmx/OptimizeImage', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const GetGoogleFonts = async () => {
	const response = await axios
		.get('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDlmkGsyYe6fgcjVwLEVjiLl2YCKztD3Mw', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			//return { data: { d: { IsSuccessful: false, Message: err } } };
			return 'ERROR: ' + err;
		});

	return response;
};

//DepositPhotos Images -----------------
export const GetDPImages = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'DepositPhotosService.asmx/GetPhotosByTerm', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const GetRelatedDPImages = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'DepositPhotosService.asmx/GetRelatedPhotos', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const GetDPImageByID = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'DepositPhotosService.asmx/GetPhotoById', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const DownloadDPImage = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'DepositPhotosService.asmx/DownloadPhoto', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const ReDownloadDPImage = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'DepositPhotosService.asmx/ReDownloadPhoto', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

//NounProject Icons ---------------------
export const GetIcons = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'NounProjectService.asmx/GetIconsByTerm', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};

export const GetIconById = async (data) => {
	try {
		const response = await instance
			.post(serviceHost + 'NounProjectService.asmx/GetIconById', data, {
				headers : {
					'Content-Type' : 'application/json'
				}
			})
			.then((response) => {
				return response;
			});

		return response.data.d;
	} catch (error) {
		return HandleAxiosException(error);
	}
};
