import { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from './../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { toast } from 'react-toastify';
import Spinner from '../../spinner/Spinner';
import {
	GetProjectSettings,
	SaveProjectSettings,
	ExportProjectSettings,
	UploadFile,
	UploadExportedFile,
	CreateFulfillmentRequest,
	CreateFulfillmentRequestV3
} from '../../../Service';
import ProjectPreview from '../../preview/projectPreview/ProjectPreview';
import ServicesList from '../../services/servicesList/ServicesList';
import BlogPostsList from '../../blogPosts/blogPostsList/BlogPostsList';
import TestimonialsList from '../../testimonials/testimonialsList/TestimonialsList';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ProjectExportDialog from '../projectExportDialog/ProjectExportDialog';
import ProjectDeleteDialog from '../projectDeleteDialog/ProjectDeleteDialog';
import ImagesPurchaseDialog from '../imagesPurchaseDialog/ImagesPurchaseDialog';
import CompanyDetails from '../../company/CompanyDetails';
import SEODetails from '../../SEO/SEODetails';
import AboutUsDetails from '../../aboutUs/AboutUsDetails';
import FaviconSelect from '../../favicon/FaviconSelect';
import FaviconPreview from '../../preview/favicon/FaviconPreview';
import { createGuid, heroPreviewCss, createSlug, webServiceUrl } from '../../../CommonFunctions';
import DomToImage from 'dom-to-image';
import './ProjectDetails.css';
import CallToAction from '../../callToAction/CallToAction';
import FormTitleContainer from '../../formTitleContainer/FormTitleContainer';
import CallToActionPreview from '../../preview/callToAction/CallToActionPreview';
import Gallery from '../../gallery/Gallery';
import AboutUsPreviewV3 from '../../preview/aboutUs/AboutUsPreviewV3';

const ProjectDetails = () => {
	const [
		{
			projectData,
			projectShowCompanyInfo,
			projectShowSEO,
			projectShowFavicons,
			projectShowAboutUs,
			projectShowCallToAction,
			projectShowGallery
		},
		dispatch
	] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isExporting, setIsExporting ] = useState(false);
	const [ isPublishing, setIsPublishing ] = useState(false);
	const [ publishCompleted, setPublishCompleted ] = useState(false);
	const [ faviconCompleted, setFaviconCompleted ] = useState(false);
	const [ websiteUrl, setWebsiteUrl ] = useState('');
	const [ primaryColorOutput, setPrimaryColorOutput ] = useState('');
	const [ secondaryColorOutput, setSecondaryColorOutput ] = useState('');
	const [ formData, setFormData ] = useState({});
	const [ submissionUrl, setSubmissionUrl ] = useState('');
	const [ exportProjectDialog, setExportProjectDialog ] = useState(false);
	const [ publishProjectDialog, setPublishProjectDialog ] = useState(false);
	const [ deleteProjectDialog, setDeleteProjectDialog ] = useState(false);
	const [ imagesPurchaseDialog, setImagesPurchaseDialog ] = useState(false);
	const [ itemsToPublish, setItemsToPublish ] = useState([]);
	const [ publishSelectedAll, setPublishSelectedAll ] = useState(true);
	const previewRef = useRef();
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	useEffect(
		() => {
			if (projectData) {
				setFormData({
					Color                : projectData.primaryColor,
					ColorOutput          : primaryColorOutput,
					SecondaryColor       : projectData.secondaryColor,
					SecondaryColorOutput : secondaryColorOutput,
					CompanyName          : projectData.company,
					Completed            : projectData.completed
				});

				setPrimaryColorOutput(projectData.primaryColor);
				setSecondaryColorOutput(projectData.secondaryColor);
				setSubmissionUrl(projectData.submissionUrl);

				// //set default items to publish
				// let tempItemsToPublish = [
				// 	{ name: 'PrimaryColor', text: 'Primary Color', checked: true },
				// 	{ name: 'SecondaryColor', text: 'Secondary Color', checked: true },
				// 	{ name: 'CompanyDetails', text: 'Company Details', checked: true },
				// 	{ name: 'Logo', text: 'Logo', checked: true },
				// 	{ name: 'FavIcon', text: 'Fav Icon', checked: true },
				// 	{
				// 		name    : 'Hero',
				// 		text    : 'Hero',
				// 		checked : true
				// 		// subitems : [
				// 		// 	{ name: 'HeroHeadline', text: 'Headline', checked: true },
				// 		// 	{ name: 'HeroImage', text: 'Image', checked: true }
				// 		// ]
				// 	},
				// 	{ name: 'SEO', text: 'SEO', checked: true },
				// 	{
				// 		name    : 'AboutUs',
				// 		text    : 'About Us',
				// 		checked : true
				// 		// subitems : [
				// 		// 	{ name: 'AboutUsTitle', text: 'Title', checked: true },
				// 		// 	{ name: 'AboutUsContent', text: 'Content', checked: true },
				// 		// 	{ name: 'AboutUsImage', text: 'Image', checked: true }
				// 		// ]
				// 	},
				// 	{
				// 		name    : 'CallToAction',
				// 		text    : 'Call To Action',
				// 		checked : true
				// 		// subitems : [
				// 		// 	{ name: 'CallToActionHeadline', text: 'Headline', checked: true },
				// 		// 	{ name: 'CallToActionButtonText', text: 'Button Text', checked: true },
				// 		// 	{ name: 'CallToActionButtonUrl', text: 'Button Url', checked: true },
				// 		// 	{ name: 'CallToActionHeadline', text: 'Headline', checked: true },
				// 		// 	{ name: 'CallToActionImage', text: 'Image', checked: true },
				// 		// 	{ name: 'CallToActionOverlayColor', text: 'Overlay Color', checked: true },
				// 		// 	{ name: 'CallToActionOverlayOpacity', text: 'Overlay Opacity', checked: true }
				// 		// ]
				// 	}
				// ];

				// if (projectData.services && projectData.services.length > 0) {
				// 	projectData.services.map((service, index) => {
				// 		let serviceName = service.name ? service.name : service.guid;
				// 		let serviceSlug = createSlug(serviceName);

				// 		let item = tempItemsToPublish.find((o) => o.name === 'Service_' + serviceSlug);

				// 		if (!item) {
				// 			let currentItemsToPublish = tempItemsToPublish;

				// 			tempItemsToPublish = [
				// 				...currentItemsToPublish,
				// 				{
				// 					name         : 'Service_' + serviceSlug,
				// 					text         : serviceName,
				// 					groupText    : index === 0 ? 'Services:' : '',
				// 					objectsCount : projectData.services.length,
				// 					checked      : true
				// 					// subitems     : [
				// 					// 	{ name: 'ServiceTitle', text: 'Title', checked: true },
				// 					// 	{ name: 'ServiceContent', text: 'Content', checked: true },
				// 					// 	{ name: 'ServiceHeroImage', text: 'Hero Image', checked: true },
				// 					// 	{ name: 'ServiceFeatImage', text: 'Featured Image', checked: true }
				// 					// ]
				// 				}
				// 			];
				// 		} else {
				// 			item.checked = true;
				// 		}
				// 	});
				// }

				// if (projectData.blogPosts && projectData.blogPosts.length > 0) {
				// 	projectData.blogPosts.map((blogPost, index) => {
				// 		let blogPostName = blogPost.title ? blogPost.title : blogPost.guid;
				// 		let blogPostSlug = createSlug(blogPostName);

				// 		let item = tempItemsToPublish.find((o) => o.name === 'BlogPost_' + blogPostSlug);

				// 		if (!item) {
				// 			let currentItemsToPublish = tempItemsToPublish;

				// 			tempItemsToPublish = [
				// 				...currentItemsToPublish,
				// 				{
				// 					name         : 'BlogPost_' + blogPostSlug,
				// 					text         : blogPostName,
				// 					groupText    : index === 0 ? 'Blog posts:' : '',
				// 					objectsCount : projectData.blogPosts.length,
				// 					checked      : true
				// 					// subitems     : [
				// 					// 	{ name: 'BlogPostTitle', text: 'Title', checked: true },
				// 					// 	{ name: 'BlogPostContent', text: 'Content', checked: true },
				// 					// 	{ name: 'BlogPostFeatImage', text: 'Image', checked: true }
				// 					// ]
				// 				}
				// 			];
				// 		} else {
				// 			item.checked = true;
				// 		}
				// 	});
				// }

				// if (projectData.testimonials && projectData.testimonials.length > 0) {
				// 	projectData.testimonials.map((testimonial, index) => {
				// 		let testimonialName = testimonial.title ? testimonial.title : testimonial.guid;
				// 		let testimonialSlug = createSlug(testimonialName);

				// 		let item = tempItemsToPublish.find((o) => o.name === 'Testimonial_' + testimonialSlug);

				// 		if (!item) {
				// 			let currentItemsToPublish = tempItemsToPublish;

				// 			tempItemsToPublish = [
				// 				...currentItemsToPublish,
				// 				{
				// 					name         : 'Testimonial_' + testimonialSlug,
				// 					text         : testimonialName,
				// 					groupText    : index === 0 ? 'Testimonials:' : '',
				// 					objectsCount : projectData.testimonials.length,
				// 					checked      : true
				// 					// subitems     : [
				// 					// 	{ name: 'TestimonialTitle', text: 'Title', checked: true },
				// 					// 	{ name: 'TestimonialImage', text: 'Image', checked: true },
				// 					// 	{ name: 'TestimonialStars', text: 'Stars', checked: true },
				// 					// 	{ name: 'TestimonialMessage', text: 'Message', checked: true },
				// 					// 	{ name: 'TestimonialPosition', text: 'Position', checked: true },
				// 					// 	{ name: 'TestimonialLocation', text: 'Location', checked: true }
				// 					// ]
				// 				}
				// 			];
				// 		} else {
				// 			item.checked = true;
				// 		}
				// 	});
				// }

				// if (projectData.gallery && projectData.gallery && projectData.gallery.length > 0) {
				// 	let galleryForPublish = {
				// 		name    : 'Gallery',
				// 		text    : 'Gallery',
				// 		checked : true
				// 	};

				// 	let imagesForPublish = [];

				// 	projectData.gallery.map((galleryImage, index) => {
				// 		let galleryImageName = galleryImage.title || createGuid();
				// 		let galleryImageThumb = galleryImage.thumb;
				// 		let galleryImageSlug = createSlug(galleryImageName);

				// 		let item = imagesForPublish.find((o) => o.name === galleryImageSlug);

				// 		if (!item) {
				// 			if (galleryImageThumb) {
				// 				imagesForPublish.push({
				// 					name    : galleryImageSlug,
				// 					text    : galleryImageThumb,
				// 					checked : true
				// 				});
				// 			}
				// 		} else {
				// 			item.checked = true;
				// 		}
				// 	});

				// 	galleryForPublish.subitems = imagesForPublish;

				// 	let currentItemsToPublish = tempItemsToPublish;

				// 	tempItemsToPublish = [ ...currentItemsToPublish, galleryForPublish ];
				// }

				//setItemsToPublish(tempItemsToPublish);

				//store items to publish in settings
				let tempProjectData = projectData;
				//tempProjectData.itemsToPublish = tempItemsToPublish;

				let tempItemsToPublish = [];
				let excludedItemsToPublish = [ 'itemsToPublish' ];

				for (let tempProjectDataKey in tempProjectData) {
					if (excludedItemsToPublish.includes(tempProjectDataKey)) {
						continue;
					}

					if (tempProjectData[tempProjectDataKey] instanceof Array) {
						let itemToPublish = {
							guid    : '',
							name    : tempProjectDataKey,
							title   : tempProjectDataKey,
							text    : tempProjectDataKey,
							checked : true
						};
						let subitemsToPublish = [];

						for (let i = 0; i < tempProjectData[tempProjectDataKey].length; i++) {
							if (
								tempProjectData[tempProjectDataKey][i].guid ||
								tempProjectData[tempProjectDataKey][i].title ||
								tempProjectData[tempProjectDataKey][i].name
							) {
								subitemsToPublish.push({
									guid    : tempProjectData[tempProjectDataKey][i].guid,
									name    :
										tempProjectData[tempProjectDataKey][i].title ||
										tempProjectData[tempProjectDataKey][i].name,
									title   :
										tempProjectData[tempProjectDataKey][i].title ||
										tempProjectData[tempProjectDataKey][i].name,
									thumb   : tempProjectData[tempProjectDataKey][i].thumb || '',
									image   : tempProjectData[tempProjectDataKey][i].urlBig || '',
									checked : true
								});
							}
						}

						itemToPublish.items = subitemsToPublish;
						tempItemsToPublish.push(itemToPublish);
					} else if (tempProjectData[tempProjectDataKey] instanceof Object) {
						tempItemsToPublish.push({
							id      : '',
							name    : tempProjectDataKey,
							title   : tempProjectDataKey,
							text    : tempProjectDataKey,
							checked : true
						});
					} else {
						if (tempProjectDataKey === 'primaryColor' || tempProjectDataKey === 'secondaryColor') {
							tempProjectData.primaryColorChecked = true;
							tempProjectData.secondaryColorChecked = true;

							tempItemsToPublish.push({
								id      : '',
								name    : tempProjectDataKey,
								title   : tempProjectDataKey,
								text    : tempProjectDataKey,
								checked : true
							});
						}
					}
				}

				setItemsToPublish(tempItemsToPublish);
				//tempProjectData.itemsToPublish = tempItemsToPublish;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		},
		[
			projectData,
			projectShowCompanyInfo,
			projectShowSEO,
			primaryColorOutput,
			secondaryColorOutput,
			submissionUrl,
			publishProjectDialog
		]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});

				setFormData({
					Color                : settings.primaryColor,
					ColorOutput          : settings.primaryColor,
					SecondaryColor       : settings.secondaryColor,
					SecondaryColorOutput : settings.secondaryColor,
					CompanyName          : settings.company,
					Completed            : settings.completed
				});

				setPrimaryColorOutput(settings.primaryColor);
				setSecondaryColorOutput(settings.secondaryColor);
				setSubmissionUrl(settings.submissionUrl);
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if ((target === 'Completed' || target.includes('Publish')) && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'Color' || target === 'ColorOutput') {
			tempProjectData.primaryColor = value;
			setPrimaryColorOutput(value);
		} else if (target === 'SecondaryColor' || target === 'SecondaryColorOutput') {
			tempProjectData.secondaryColor = value;
			setSecondaryColorOutput(value);
		} else if (target === 'CompanyName') {
			tempProjectData.company = value;
		} else if (target === 'Completed') {
			tempProjectData.completed = checked;
		} else if (target === 'selectAll') {
			let tempItemsToPublish = itemsToPublish.map((item) => {
				if (item.items) {
					item.items = item.items.map((subitem) => {
						return { ...subitem, checked: checked };
					});
				}

				return { ...item, checked: checked };
			});

			setItemsToPublish(tempItemsToPublish);
			setPublishSelectedAll(checked);
		} else if (target.includes('Publish')) {
			let tempItemsToPublish = itemsToPublish;
			let itemToPublish = tempItemsToPublish.find((o) => o.name === target.replace('Publish', ''));

			if (itemToPublish) {
				itemToPublish.checked = checked;
			} else {
				//check subitems
				itemsToPublish.map((item) => {
					if (item.items) {
						let subitemFound = item.items.find((o) => o.name === target.replace('Publish', ''));

						if (subitemFound) {
							subitemFound.checked = checked;
						}
					}
				});
			}

			if (!tempProjectData.itemsToPublish) {
				tempProjectData.itemsToPublish = {};
			}

			tempProjectData.itemsToPublish = tempItemsToPublish;
			setItemsToPublish(tempItemsToPublish);

			//select all checkbox state
			let tempPublishSelectedAll = true;

			if (checked) {
				let checkedCount = 1;
				tempItemsToPublish = itemsToPublish.map((item, index) => {
					if (item.checked) {
						checkedCount++;
					}
				});

				if (checkedCount < itemsToPublish.length - 1) {
					//not all individual checkboxes are checked, so we don't check Select All checkbox
					tempPublishSelectedAll = false;
				}
			} else {
				tempPublishSelectedAll = false;
			}

			setPublishSelectedAll(tempPublishSelectedAll);

			//console.log('on change ', tempItemsToPublish);
		}

		setSubmissionUrl(tempProjectData.submissionUrl);

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});

		if (projectData) {
			tempProjectData = heroPreviewCss(projectData);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		saveSettings(true);

		setIsSaving(false);
	};

	const saveSettings = async (showMessage) => {
		let tempProjectData = {};

		if (projectData) {
			tempProjectData = projectData;
		} else {
			let data = {};
			data.ProjectId = projectId;
			tempProjectData = await GetProjectSettings(data);
		}

		if (tempProjectData !== null) {
			tempProjectData.primaryColor = formData.Color;
			tempProjectData.secondaryColor = formData.SecondaryColor;
			tempProjectData.company = formData.CompanyName;
			tempProjectData.completed = formData.Completed;

			//update the json
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(tempProjectData);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				if (showMessage) {
					toast.success('Settings updated successfully.');
				}
			}
		}
	};

	const onLogoUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadLogo');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeLogoUpload = (e) => {
		e.preventDefault();

		if (projectData.logo.iconUploaded) {
			let tempProjectData = projectData;

			delete tempProjectData.logo.iconUploaded;

			//set logo icon as favicon
			if (tempProjectData.logo && tempProjectData.logo.layout) {
				tempProjectData.favicon.icon =
					tempProjectData.logo.layout === 'horizontal'
						? tempProjectData.logo.horizontal.icon
						: tempProjectData.logo.vertical.icon;
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const onLogoChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadLogo').files[0];

		if (file) {
			let tempProjectData = projectData;

			let data = new FormData();
			data.append('ProjectId', tempProjectData.guid);
			data.append('FileName', 'logo');
			data.append('UploadedFile', file);

			const response = await UploadFile(data);

			if (response.IsSuccessful) {
				let iconUrl = webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

				tempProjectData.logo.iconUploaded = iconUrl;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const showExportProjectDialog = () => {
		setExportProjectDialog(true);
	};

	const hideExportProjectDialog = () => {
		setExportProjectDialog(false);
	};

	const showPublishProjectDialog = () => {
		setPublishProjectDialog(true);
	};

	const showPublishProjectDialogV3 = () => {
		setPublishProjectDialog(true);
	};

	const hidePublishProjectDialog = () => {
		setPublishProjectDialog(false);
		setPublishCompleted(false);
	};

	const showDeleteProjectDialog = () => {
		setDeleteProjectDialog(true);
	};

	const hideDeleteProjectDialog = () => {
		setDeleteProjectDialog(false);
	};

	const showImagesPurchaseDialog = () => {
		setImagesPurchaseDialog(true);
	};

	const hideImagesPurchaseDialog = () => {
		setImagesPurchaseDialog(false);
	};

	const setProjectDataForPublish = (projectData) => {
		let tempProjectData = projectData;

		if (itemsToPublish) {
			//go through itemsToPublish and sync all settings for publishing
			itemsToPublish.map((itemToPublish) => {
				if (itemToPublish.items) {
					itemToPublish.items.map((subitemToPublish) => {
						let projectDataItem = tempProjectData[itemToPublish.name];

						if (projectDataItem && projectDataItem.length) {
							projectDataItem.map((projectDataSubitem) => {
								if (
									subitemToPublish.guid &&
									projectDataSubitem.guid &&
									subitemToPublish.guid === projectDataSubitem.guid
								) {
									projectDataSubitem.checked = subitemToPublish.checked;
								}
							});
						}
					});
				} else {
					let projectDataItem = tempProjectData[itemToPublish.name];

					if (projectDataItem) {
						if (itemToPublish.name === 'primaryColor' || itemToPublish.name === 'secondaryColor') {
							tempProjectData[itemToPublish.name + 'Checked'] = itemToPublish.checked;
						}

						if (projectDataItem instanceof Object) {
							projectDataItem.checked = itemToPublish.checked;

							if (itemToPublish.name === 'logo' && itemToPublish.checked) {
								//set icon url
								//projectDataItem.icon = `${webServiceUrl()}/projects/${tempProjectData.guid}/assets/export/logo.png?v=${createGuid()}`;
								projectDataItem.icon = `${webServiceUrl()}/projects/${tempProjectData.guid}/assets/export/logo.png`;
								exportLogo(true);
							}

							if (itemToPublish.name === 'favicon' && itemToPublish.checked) {
								//set favicon url
								projectDataItem.publishUrl = `${webServiceUrl()}/projects/${tempProjectData.guid}/assets/export/favicon.png`;
								exportFavicon();
							}
						}
					}
				}
			});
		}

		return tempProjectData;
	};

	const exportLogo = async (isExport) => {
		let processLogo = false;

		if (itemsToPublish) {
			let logoToPublish = itemsToPublish.find((o) => o.name === 'Logo');
			//console.log('logoToPublish 1 :', logoToPublish);

			if ((logoToPublish && logoToPublish.checked === true) || isExport) {
				processLogo = true;
			}
		}

		if (processLogo) {
			let iconContainer = document.getElementById('projectLogoPreview');

			if (iconContainer) {
				let icon = iconContainer.querySelector('.icon-preview img');

				if (icon) {
					DomToImage.toBlob(iconContainer)
						.then(async function(blob) {
							if (blob) {
								const file = new File([ blob ], 'logo', { type: blob.type });

								if (file) {
									let data = new FormData();
									data.append('ProjectId', projectId);
									data.append('FileName', 'logo.png');
									data.append('ExportPath', '');
									data.append('UploadedFile', file);

									const response = await UploadExportedFile(data);

									if (response.IsSuccessful) {
									}
								}
							}
						})
						.catch(function(error) {
							console.error('Could not convert logo. ', error);
						});
				}
			}
		}
	};

	const exportFavicon = async () => {
		let faviconContainer = document.getElementById('favicon_' + projectId);

		if (faviconContainer) {
			setTimeout(() => {
				DomToImage.toBlob(faviconContainer)
					.then(async function(blob) {
						if (blob) {
							const file = new File([ blob ], 'favicon', { type: blob.type });

							if (file) {
								let data = new FormData();
								data.append('ProjectId', projectId);
								data.append('FileName', 'favicon.png');
								data.append('ExportPath', '');
								data.append('UploadedFile', file);

								const response = await UploadExportedFile(data);

								if (response.IsSuccessful) {
									//console.log('Favicon coverted successfully!');
								} else {
									console.log('Could not convert favicon. Reason: ' + response.Message);
								}
							}
						} else {
							console.log('Could not convert favicon. Blob is null');
						}
					})
					.catch(function(error) {
						console.error('Could not convert favicon. ', error);
					});
			}, 500);
		}
	};

	const exportSettings = async (isExport) => {
		if (!isExport && (!websiteUrl || websiteUrl === '')) {
			toast.error('Website URL is required');
			return;
		}

		if (isExport) {
			setIsExporting(true);
		} else {
			setIsPublishing(true);
		}

		saveSettings(false);

		await exportLogo(isExport);

		if (previewRef.current) {
			previewRef.current.exportImages(isExport);
		}

		let data = { ProjectId: projectId, IsExport: isExport };

		const response = await ExportProjectSettings(data);

		if (response.IsSuccessful) {
			if (isExport) {
				setIsExporting(false);

				showExportProjectDialog();
			} else {
				let tempWebsiteUrl = websiteUrl;

				if (tempWebsiteUrl.endsWith('/')) {
					tempWebsiteUrl = tempWebsiteUrl.slice(0, -1);
				}

				//call RW web service to upload zip file on AmazonS3, and import on site
				let zipUrl = webServiceUrl() + '/projects/' + projectId + '/assets/' + projectId + '.zip';
				let token = createGuid();

				let data = { WebsiteUrl: tempWebsiteUrl, ZipUrl: zipUrl, Token: token };

				const response1 = await CreateFulfillmentRequest(data);

				console.log('response1', response1.IsSuccessful, response1.Result);

				if (response1.IsSuccessful) {
					setPublishCompleted(true);
				}

				setIsPublishing(false);
			}
		} else {
			if (isExport) {
				setIsExporting(false);
			} else {
				setIsPublishing(false);
			}

			toast.error((isExport ? 'Export' : 'Publish') + ' process failed. Reason: ' + response.Message);
		}
	};

	const exportSettingsV3 = async (isExport) => {
		if (!isExport && (!websiteUrl || websiteUrl === '')) {
			toast.error('Website URL is required');
			return;
		}

		if (isExport) {
			setIsExporting(true);
		} else {
			setIsPublishing(true);
		}

		saveSettings(false);

		let tempProjectData = setProjectDataForPublish(projectData);

		let tempWebsiteUrl = websiteUrl;

		if (tempWebsiteUrl.endsWith('/')) {
			tempWebsiteUrl = tempWebsiteUrl.slice(0, -1);
		}

		let data = { WebsiteUrl: tempWebsiteUrl, JsonData: JSON.stringify(tempProjectData), Token: createGuid() };

		const response = await CreateFulfillmentRequestV3(data);

		if (response.IsSuccessful) {
			if (isExport) {
				setIsExporting(false);

				showExportProjectDialog();
			} else {
				setPublishCompleted(true);
				setIsPublishing(false);
			}
		} else {
			if (isExport) {
				setIsExporting(false);
			} else {
				setIsPublishing(false);
			}

			toast.error((isExport ? 'Export' : 'Publish') + ' process failed. Reason: ' + response.Message);
		}
	};

	const openCompanyInfo = () => {
		dispatch({
			type                   : actionTypes.SHOW_PROJECT_COMPANY_INFO,
			projectShowCompanyInfo : true
		});
	};

	const openSEO = () => {
		dispatch({
			type           : actionTypes.SHOW_PROJECT_SEO,
			projectShowSEO : true
		});
	};

	const openAboutUs = () => {
		dispatch({
			type               : actionTypes.SHOW_PROJECT_ABOUT_US,
			projectShowAboutUs : true
		});
	};

	const openCallToAction = () => {
		dispatch({
			type                    : actionTypes.SHOW_PROJECT_CALL_TO_ACTION,
			projectShowCallToAction : true
		});
	};

	const openFaviconSelect = () => {
		dispatch({
			type                : actionTypes.SHOW_PROJECT_FAVICONS,
			projectShowFavicons : true
		});
	};

	const openGallery = () => {
		dispatch({
			type               : actionTypes.SHOW_PROJECT_GALLERY,
			projectShowGallery : true
		});
	};

	const handleWebsiteUrlChange = (e) => {
		setWebsiteUrl(e.target.value);
	};

	return (
		<Fragment>
			<div className="left-container">
				{projectShowCompanyInfo ? (
					<CompanyDetails />
				) : projectShowSEO ? (
					<SEODetails />
				) : projectShowAboutUs ? (
					<AboutUsDetails />
				) : projectShowCallToAction ? (
					<CallToAction />
				) : projectShowGallery ? (
					<Gallery />
				) : projectShowFavicons ? (
					<FaviconSelect />
				) : (
					formData && (
						<form onSubmit={handleSubmit}>
							<div className="form-fields-wrapper">
								<FormTitleContainer
									title={`Project ${projectData ? `- ${projectData.name}` : 'Details'}`}
								/>
								<div className="row mb-0 px-2">
									<div className="col-6 pe-0">
										<label htmlFor="Color">Primary Color</label>
									</div>
									<div className="col-6 pe-0">
										<label htmlFor="Color">Secondary Color</label>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-3 pe-0">
										<input
											type="color"
											className="form-control form-control-color w-100"
											name="Color"
											value={formData.Color ? formData.Color : '#000000'}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 ps-1 d-flex align-items-end">
										<label htmlFor="ColorOutput" />
										<input
											type="text"
											className="form-control"
											name="ColorOutput"
											value={primaryColorOutput ? primaryColorOutput : '#000000'}
											placeholder=""
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 pe-0">
										<input
											type="color"
											className="form-control form-control-color w-100"
											name="SecondaryColor"
											value={formData.SecondaryColor ? formData.SecondaryColor : '#000000'}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 ps-1 d-flex align-items-end">
										<label htmlFor="SecondaryColorOutput" />
										<input
											type="text"
											className="form-control"
											name="SecondaryColorOutput"
											value={secondaryColorOutput ? secondaryColorOutput : '#000000'}
											placeholder=""
											onChange={handleChange}
										/>
									</div>
								</div>
								{submissionUrl && (
									<Fragment>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="SubmissionUrl">Form Submission Url</label>
												<div>
													<a href={submissionUrl} target="_blank">
														{submissionUrl}
													</a>
												</div>
											</div>
										</div>
									</Fragment>
								)}

								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="CompanyName">Company Name</label>
										<input
											type="text"
											className="form-control"
											name="CompanyName"
											value={formData.CompanyName}
											placeholder="Enter company name"
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										{projectData &&
										projectData.companyInfo &&
										projectData.companyInfo.completed && (
											<div
												className="project-button-checkmark position-absolute m-2"
												title="Completed"
											>
												<i className="fas fa-check" />
											</div>
										)}
										<button onClick={openCompanyInfo} className="btn btn-primary w-100">
											<span>Company Details</span>
										</button>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-10 pe-0 position-relative">
										<Fragment>
											{projectData &&
											projectData.logo &&
											projectData.logo.completed && (
												<div
													className="project-button-checkmark position-absolute m-2"
													title="Completed"
												>
													<i className="fas fa-check" />
												</div>
											)}
											<Link to={`/project/${projectId}/logo`} className="btn btn-primary w-100">
												<span>Logo</span>
											</Link>
										</Fragment>
									</div>
									<div className="col-2">
										{projectData && projectData.logo && projectData.logo.iconUploaded ? (
											<button
												type="button"
												onClick={removeLogoUpload}
												className="btn btn-danger w-100"
											>
												<span>
													<i className="fas fa-trash-alt" />
												</span>
											</button>
										) : (
											<button
												type="button"
												onClick={onLogoUpload}
												className="btn btn-primary w-100"
											>
												<span>
													<i className="fas fa-cloud-upload-alt" />
												</span>
											</button>
										)}
										<input type="file" onChange={onLogoChange} className="d-none" id="uploadLogo" />
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										<Fragment>
											{projectData &&
											projectData.favicon &&
											projectData.favicon.completed && (
												<div
													className="project-button-checkmark position-absolute m-2"
													title="Completed"
												>
													<i className="fas fa-check" />
												</div>
											)}
											<a className="btn btn-primary w-100" onClick={openFaviconSelect}>
												<span>Favicon</span>
											</a>
										</Fragment>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										{projectData &&
										projectData.hero &&
										projectData.hero.completed && (
											<div
												className="project-button-checkmark position-absolute m-2"
												title="Completed"
											>
												<i className="fas fa-check" />
											</div>
										)}
										<Link to={`/project/${projectId}/hero`} className="btn btn-primary w-100">
											<span>Hero Section</span>
										</Link>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										{projectData &&
										projectData.seo &&
										projectData.seo.completed && (
											<div
												className="project-button-checkmark position-absolute m-2"
												title="Completed"
											>
												<i className="fas fa-check" />
											</div>
										)}
										<button onClick={openSEO} className="btn btn-primary w-100">
											<span>SEO</span>
										</button>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										{projectData &&
										projectData.aboutUs &&
										projectData.aboutUs.completed && (
											<div
												className="project-button-checkmark position-absolute m-2"
												title="Completed"
											>
												<i className="fas fa-check" />
											</div>
										)}
										<button onClick={openAboutUs} className="btn btn-primary w-100">
											<span>About Us</span>
										</button>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										{projectData &&
										projectData.callToAction &&
										projectData.callToAction.completed && (
											<div
												className="project-button-checkmark position-absolute m-2"
												title="Completed"
											>
												<i className="fas fa-check" />
											</div>
										)}
										<button onClick={openCallToAction} className="btn btn-primary w-100">
											<span>Call To Action</span>
										</button>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12 position-relative">
										{projectData &&
										projectData.gallery &&
										projectData.gallery.completed && (
											<div
												className="project-button-checkmark position-absolute m-2"
												title="Completed"
											>
												<i className="fas fa-check" />
											</div>
										)}
										<button onClick={openGallery} className="btn btn-primary w-100">
											<span>Gallery</span>
										</button>
									</div>
								</div>
								<div className="row mb-0 px-2">
									<div className="col-12">
										<nav>
											<div className="nav nav-tabs" id="navTabCustomTypes" role="tablist">
												<button
													className="nav-link active"
													id="nav-services-tab"
													data-bs-toggle="tab"
													data-bs-target="#nav-services"
													type="button"
													role="tab"
													aria-controls="nav-services"
													aria-selected="true"
												>
													Services
												</button>
												<button
													className="nav-link"
													id="nav-blogposts-tab"
													data-bs-toggle="tab"
													data-bs-target="#nav-blogposts"
													type="button"
													role="tab"
													aria-controls="nav-blogposts"
													aria-selected="false"
												>
													Blog Posts
												</button>
												<button
													className="nav-link"
													id="nav-testimonials-tab"
													data-bs-toggle="tab"
													data-bs-target="#nav-testimonials"
													type="button"
													role="tab"
													aria-controls="nav-testimonials"
													aria-selected="false"
												>
													Testimonials
												</button>
											</div>
										</nav>
										<div className="tab-content custom-types-tab border" id="tabCustomTypes">
											<div
												className="tab-pane fade active show"
												id="nav-services"
												role="tabpanel"
												aria-labelledby="nav-services-tab"
											>
												<ServicesList />
											</div>
											<div
												className="tab-pane fade"
												id="nav-blogposts"
												role="tabpanel"
												aria-labelledby="nav-blogposts-tab"
											>
												<BlogPostsList />
											</div>
											<div
												className="tab-pane fade"
												id="nav-testimonials"
												role="tabpanel"
												aria-labelledby="nav-testimonials-tab"
											>
												<TestimonialsList />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div className="row mb-0 px-2">
									<div className="col-12">
										<button
											type="submit"
											className="btn btn-primary btn-lg me-2"
											disabled={isSaving}
										>
											{!isSaving ? (
												<Fragment>
													<span>Save</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>Saving...</span>
												</Fragment>
											)}
										</button>
										<Link
											to="/"
											className="btn btn-outline-primary btn-lg me-2"
											disabled={isSaving || isPublishing || isExporting}
										>
											<span>Close</span>
										</Link>
										<button
											type="button"
											onClick={() => exportSettingsV3(true)}
											className="btn btn-primary btn-lg me-2"
											disabled={isExporting}
										>
											{!isExporting ? (
												<Fragment>
													<span>Export</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>Exporting...</span>
												</Fragment>
											)}
										</button>
										<button
											type="button"
											//onClick={showPublishProjectDialog}
											onClick={showPublishProjectDialogV3}
											className="btn btn-primary btn-lg me-2"
										>
											<span>Publish</span>
										</button>
										<button
											type="button"
											onClick={showImagesPurchaseDialog}
											className="btn btn-primary btn-lg me-2"
										>
											<span>Images</span>
										</button>
										<input
											type="checkbox"
											name="Completed"
											className="form-check-input check-completed mt-3 ms-2"
											defaultChecked={formData.Completed}
											onClick={handleChange}
											title="Is Project Completed?"
										/>
									</div>
								</div>
							</div>
						</form>
					)
				)}
			</div>
			<div className="right-container">
				{projectShowFavicons ? (
					<FaviconPreview />
				) : projectShowAboutUs ? (
					<AboutUsPreviewV3 />
				) : projectShowCallToAction ? (
					<CallToActionPreview iconColor="#ffffff" />
				) : (
					<Fragment>
						<div className="delete-project-wrapper">
							<button className="btn btn-danger btn-lg" onClick={showDeleteProjectDialog}>
								<span>Delete</span>
							</button>
						</div>
						<ProjectPreview ref={previewRef} />
					</Fragment>
				)}
			</div>
			{exportProjectDialog && <ProjectExportDialog closeDialog={hideExportProjectDialog} />}
			{publishProjectDialog && (
				<Fragment>
					<ModalDialog>
						<form autoComplete="off">
							<ModalDialogContent align="start">
								<h2 className="mb-4">Publish Project</h2>
								{isPublishing ? (
									<div className="text-center">
										<Spinner />
									</div>
								) : publishCompleted ? (
									<h5>Publish completed successfully!</h5>
								) : (
									<Fragment>
										<div className="row mb-3 px-2">
											<label htmlFor="WebsiteUrl" className="col-2 mt-2">
												Website URL
											</label>
											<div className="col-10">
												<input
													type="text"
													className="form-control"
													name="WebsiteUrl"
													value={websiteUrl}
													placeholder="Enter Website URL"
													onChange={handleWebsiteUrlChange}
												/>
											</div>
										</div>
										<div className="row mb-0 px-2">
											<label htmlFor="WebsiteUrl" className="col-12 mt-2">
												Select items to publish
											</label>
										</div>
										<div className="row mb-0 px-2">
											<div className="col-12">
												<div className="form-check">
													<input
														type="checkbox"
														name="selectAll"
														id="selectAll"
														className="form-check-input"
														defaultChecked={true}
														//checked={publishSelectedAll}
														onChange={handleChange}
													/>
													<label htmlFor="selectAll" className="form-check-label">
														Select All
													</label>
												</div>
											</div>
										</div>
										{itemsToPublish && (
											<div className="publish-items-wrapper">
												{itemsToPublish.map((item, index) => (
													<Fragment key={createGuid()}>
														{item.items ? (
															<Fragment>
																{item.items.length > 0 && (
																	<div className={`row mb-0 px-2`}>
																		<div className="col-12">
																			<div
																				className={`form-check form-check-inline ps-1 ${item.objectsCount
																					? 'mb-0'
																					: 'mb-1'}`}
																			>
																				{item.items.map((subitem, subindex) => (
																					<Fragment key={createGuid()}>
																						{subindex === 0 && (
																							<label
																								htmlFor={`Publish${item.name}`}
																								className={`form-check-label ${item.objectsCount
																									? 'mx-4'
																									: 'me-4'}`}
																							>
																								{`${item.title}:`}
																							</label>
																						)}

																						<div className="form-check form-check-inline">
																							<input
																								type="checkbox"
																								id={`Publish${index}_${subindex}`}
																								name={`Publish${subitem.name}`}
																								className={`form-check-input ${subitem.thumb
																									? 'checkbox-for-image'
																									: ''}`}
																								checked={
																									subitem.checked
																								}
																								onClick={handleChange}
																							/>
																							{subitem.thumb ? (
																								<img
																									src={subitem.thumb}
																									height={60}
																								/>
																							) : (
																								<label
																									htmlFor={`Publish${index}_${subindex}`}
																									className="form-check-label me-2"
																								>
																									{subitem.title}
																								</label>
																							)}
																						</div>
																					</Fragment>
																				))}
																			</div>
																		</div>
																	</div>
																)}
															</Fragment>
														) : (
															<div className={`row mb-0 px-2`}>
																<div className="col-12">
																	<div className="form-check mb-1">
																		<input
																			type="checkbox"
																			id={`Publish${index}`}
																			name={`Publish${item.name}`}
																			className="form-check-input"
																			checked={item.checked}
																			onClick={handleChange}
																		/>
																		<label
																			htmlFor={`Publish${index}`}
																			className="form-check-label"
																		>
																			{item.title}
																		</label>
																	</div>
																</div>
															</div>
														)}
													</Fragment>
												))}
											</div>
										)}

										{/* {itemsToPublish && (
											<div className="publish-items-wrapper">
												
											</div>
										)} */}
									</Fragment>
								)}
							</ModalDialogContent>
							<ModalDialogButtons align="start" shaded={true}>
								{!publishCompleted && (
									<button
										type="button"
										className="btn btn-primary btn-lg mr-4"
										onClick={() => exportSettingsV3(false)}
										disabled={isPublishing}
									>
										{!isPublishing ? (
											<Fragment>
												<span>Publish</span>
											</Fragment>
										) : (
											<Fragment>
												<span>Publishing...</span>
											</Fragment>
										)}
									</button>
								)}
								<button
									type="button"
									className="btn btn-outline-primary btn-lg"
									onClick={hidePublishProjectDialog}
								>
									{publishCompleted ? <span>Close</span> : <span>Cancel</span>}
								</button>
							</ModalDialogButtons>
						</form>
					</ModalDialog>
				</Fragment>
			)}
			{deleteProjectDialog && <ProjectDeleteDialog closeDialog={hideDeleteProjectDialog} />}
			{imagesPurchaseDialog && <ImagesPurchaseDialog closeDialog={hideImagesPurchaseDialog} />}
		</Fragment>
	);
};

export default ProjectDetails;
