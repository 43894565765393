import { Fragment, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import { DeleteProject } from '../../../Service';
import Spinner from '../../spinner/Spinner';
import { toast } from 'react-toastify';

const ProjectDeleteDialog = ({ closeDialog }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const history = useHistory();

	const deleteProject = async () => {
		setIsSaving(true);

		let data = { ProjectId: projectId };

		const response = await DeleteProject(data);

		if (response.IsSuccessful) {
			toast.success('Project deleted successfully.');

			history.push('/');
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<ModalDialog>
				<form autoComplete="off">
					<ModalDialogContent align="start">
						<h2 className="mb-4">Delete Project</h2>
						<div className="row mb-3 px-2">Are you sure that you want to delete the project?</div>
					</ModalDialogContent>
					<ModalDialogButtons align="start" shaded={true}>
						<button type="button" className="btn btn-primary btn-lg mr-4" onClick={deleteProject}>
							{!isSaving ? (
								<Fragment>
									<span>Yes</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Deleting...</span>
								</Fragment>
							)}
						</button>
						<button type="button" className="btn btn-outline-primary btn-lg" onClick={closeDialog}>
							<span>No</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default ProjectDeleteDialog;
