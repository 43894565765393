import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { Link } from 'react-router-dom';
import { actionTypes } from '../../../reducer';
import { GetProjectSettings } from '../../../Service';
import { SaveProjectSettings } from '../../../Service';
import { UploadFile } from '../../../Service';
import TinyMCEEditor from '../../tinyMCEEditor/TinyMCEEditor';
import { heroPreviewCss, webServiceUrl } from '../../../CommonFunctions';
import { toast } from 'react-toastify';
import './HeroForm.css';
import FormTitleContainer from '../../formTitleContainer/FormTitleContainer';
import HeroFonts from '../heroFonts/HeroFonts';
import ImageSelect from '../../imageSelect/ImageSelect';

const HeroForm = () => {
	const [ { projectData, showFontsSelect, showImageSelect }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ primaryColorOutput, setPrimaryColorOutput ] = useState('');
	const [ secondaryColorOutput, setSecondaryColorOutput ] = useState('');
	const [ fontType, setFontType ] = useState('');
	const [ formData, setFormData ] = useState({});
	const [ editorHeadlineContent, setEditorHeadlineContent ] = useState('');
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ showFontsSelect ]
	);

	useEffect(
		() => {
			if (projectData) {
				let tempProjectData = heroPreviewCss(projectData);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		},
		[ projectData ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		setFormData({
			Color                : projectData.primaryColor ? projectData.primaryColor : '',
			ColorOutput          : primaryColorOutput,
			SecondaryColor       : projectData.secondaryColor ? projectData.secondaryColor : '',
			SecondaryColorOutput : secondaryColorOutput,
			Headline             : projectData.hero.headline ? projectData.hero.headline : '',
			HeadlineFontType     : projectData.hero.headlineFontType ? projectData.hero.headlineFontType : '',
			ContentFontType      : projectData.hero.contentFontType ? projectData.hero.contentFontType : '',
			//Image                : projectData.hero.image ? projectData.hero.image : '',
			Completed            : projectData.hero.completed ? projectData.hero.completed : false
		});

		setPrimaryColorOutput(projectData.primaryColor);
		setSecondaryColorOutput(projectData.secondaryColor);
		setEditorHeadlineContent(projectData.hero.headline ? projectData.hero.headline : '');
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'Color' || target === 'ColorOutput') {
			tempProjectData.primaryColor = value;
			setPrimaryColorOutput(value);
		} else if (target === 'SecondaryColor' || target === 'SecondaryColorOutput') {
			tempProjectData.secondaryColor = value;
			setSecondaryColorOutput(value);
		} else if (target === 'HeadlineFontType') {
			tempProjectData.hero.headlinefontType = value;
		} else if (target === 'ContentFontType') {
			tempProjectData.hero.contentFontType = value;
		} else if (target === 'Completed') {
			tempProjectData.hero.completed = checked;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});

		if (projectData) {
			tempProjectData = heroPreviewCss(projectData);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const openGoogleFontsSelect = (type) => {
		dispatch({
			type            : actionTypes.SHOW_FONTS_SELECT,
			showFontsSelect : true
		});

		setFontType(type);
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;
		tempProjectData.primaryColor = formData.Color;
		tempProjectData.secondaryColor = formData.SecondaryColor;
		//tempProjectData.hero.image = formData.Image;
		tempProjectData.hero.headline = editorHeadlineContent;
		tempProjectData.hero.headlineFontType = formData.HeadlineFontType;
		tempProjectData.hero.contentFontType = formData.ContentFontType;
		tempProjectData.hero.completed = formData.Completed;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.primaryColor = tempProjectData.primaryColor;
			settings.secondaryColor = tempProjectData.secondaryColor;
			settings.hero = tempProjectData.hero;

			//update the json
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');
			}
		}

		setIsSaving(false);
	};

	const onHeroUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadHero');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeHeroUpload = (e) => {
		e.preventDefault();

		if (projectData.hero.imageUploaded) {
			let tempProjectData = projectData;

			delete tempProjectData.hero.imageUploaded;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const onHeroChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadHero').files[0];

		if (file) {
			let tempProjectData = projectData;

			let data = new FormData();
			data.append('ProjectId', tempProjectData.guid);
			data.append('FileName', 'hero');
			data.append('UploadedFile', file);

			const response = await UploadFile(data);

			if (response.IsSuccessful) {
				let fileUrl = webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

				tempProjectData.hero.imageUploaded = fileUrl;

				delete tempProjectData.hero.image;
				delete tempProjectData.hero.imagePreview;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const handleEditorHeadlineChange = (editorContent) => {
		setEditorHeadlineContent(editorContent);

		if (projectData && projectData.hero) {
			let tempProjectData = projectData;

			tempProjectData.hero.headline = editorContent;

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	return (
		<Fragment>
			{showFontsSelect ? (
				<HeroFonts type={fontType} />
			) : showImageSelect ? (
				<ImageSelect type="hero" />
			) : (
				<Fragment>
					{formData && (
						<form onSubmit={handleSubmit}>
							<div className="form-fields-wrapper">
								<FormTitleContainer title="Hero Section Details" />
								<div className="row mb-0 px-2">
									<div className="col-6 pe-0">
										<label htmlFor="Color">Primary Color</label>
									</div>
									<div className="col-6 pe-0">
										<label htmlFor="Color">Secondary Color</label>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-3 pe-0">
										<input
											type="color"
											className="form-control form-control-color w-100"
											name="Color"
											value={formData.Color}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 ps-1 d-flex align-items-end">
										<label htmlFor="ColorOutput" />
										<input
											type="text"
											className="form-control"
											name="ColorOutput"
											value={primaryColorOutput}
											placeholder=""
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 pe-0">
										<input
											type="color"
											className="form-control form-control-color w-100"
											name="SecondaryColor"
											value={formData.SecondaryColor}
											onChange={handleChange}
										/>
									</div>
									<div className="col-3 ps-1 d-flex align-items-end">
										<label htmlFor="SecondaryColorOutput" />
										<input
											type="text"
											className="form-control"
											name="SecondaryColorOutput"
											value={secondaryColorOutput}
											placeholder=""
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<label htmlFor="Color" className="mb-1">
										Hero Image
									</label>
									<div className="col-10 pe-0">
										<div>
											<button
												onClick={openImages}
												className="btn btn-primary w-100"
												title="Select Hero Image"
											>
												<span>Search</span>
											</button>
										</div>
									</div>
									<div className="col-2">
										{projectData && projectData.hero && projectData.hero.imageUploaded ? (
											<button
												type="button"
												onClick={removeHeroUpload}
												className="btn btn-danger w-100"
											>
												<span>
													<i className="fas fa-trash-alt" />
												</span>
											</button>
										) : (
											<button
												type="button"
												onClick={onHeroUpload}
												className="btn btn-primary w-100"
											>
												<span>
													<i className="fas fa-cloud-upload-alt" />
												</span>
											</button>
										)}
										<input type="file" onChange={onHeroChange} className="d-none" id="uploadHero" />
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-12">
										<label htmlFor="Headline">Headline</label>
										<TinyMCEEditor
											id="editorHeadline"
											name="Headline"
											value={formData.Headline}
											onEditorChange={handleEditorHeadlineChange}
										/>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<label htmlFor="HeadlineFontType">Headline Font Type</label>
									<div className="input-group mb-0">
										<input
											type="text"
											className="form-control"
											name="HeadlineFontType"
											value={formData.HeadlineFontType}
											onChange={handleChange}
										/>
										<button
											type="submit"
											className="btn btn-primary"
											onClick={() => openGoogleFontsSelect('headline')}
											title="Select Headline Font Type"
										>
											<i className="fas fa-font" />
										</button>
									</div>
								</div>
								<div className="row mb-3 px-2">
									<label htmlFor="ContentFontType">Content Font Type</label>
									<div className="input-group mb-0">
										<input
											type="text"
											className="form-control"
											name="ContentFontType"
											value={formData.ContentFontType}
											onChange={handleChange}
										/>
										<button
											type="submit"
											className="btn btn-primary"
											onClick={() => openGoogleFontsSelect('content')}
											title="Select Content Font Type"
										>
											<i className="fas fa-font" />
										</button>
									</div>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div className="row mb-0 px-2">
									<div className="col-10">
										<button type="submit" className="btn btn-primary btn-lg me-3">
											Save
										</button>
										<Link
											to={`/project/${projectId}`}
											className="btn btn-outline-primary btn-lg me-3"
										>
											<span>Close</span>
										</Link>
									</div>
									<div className="col-2 d-flex justify-content-end align-items-center">
										<input
											type="checkbox"
											name="Completed"
											className="form-check-input check-completed"
											defaultChecked={formData.Completed}
											onClick={handleChange}
											title="Is Hero Completed?"
										/>
									</div>
								</div>
							</div>
						</form>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default HeroForm;
