import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCEEditor = ({ onEditorChange, value, height, basicToolbar, inline, toolbarMode }) => {
	const editorRef = useRef(null);

	if (!basicToolbar) {
		basicToolbar = true;
	}

	let toolbarOptions = basicToolbar
		? 'formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | removeformat'
		: 'undo redo | fontselect fontsizeselect formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ' +
			'bullist numlist outdent indent | removeformat | link | code';

	return (
		<Editor
			apiKey="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
			onInit={(evt, editor) => (editorRef.current = editor)}
			initialValue={value}
			init={{
				height             : height ? height : 150,
				convert_urls       : false,
				relative_urls      : false,
				remove_script_host : false,
				menubar            : false,
				//inline             : inline,
				toolbar_mode       : toolbarMode ? toolbarMode : 'floating',
				plugins            : [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code fullscreen',
					'insertdatetime media table paste'
				],
				toolbar            : toolbarOptions,
				setup              : (editor) => {
					editor.on('keyup change', () => {
						if (onEditorChange) {
							const content = editor.getContent();
							onEditorChange(content);
						}
					});
				}
				// onEditorChange     : (newValue, editor) => {
				// 	const content = editor.getContent();
				// 	onEditorChange(newValue);
				// }
			}}
		/>
	);
};

export default TinyMCEEditor;
