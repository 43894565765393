export const initialState = {
	projectData              : null,
	projectIdNote            : null,
	projectShowCompanyInfo   : false,
	projectShowSEO           : false,
	projectShowAboutUs       : false,
	projectShowCallToAction  : false,
	projectShowGallery       : false,
	projectShowFavicons      : false,
	logoShowFonts            : false,
	logoShowIcons            : false,
	showFontsSelect          : false,
	serviceShowFeatImageForm : false,
	serviceShowContent       : false,
	serviceShowTabImages     : false,
	serviceShowIcons         : false,
	blogPostShowContent      : false,
	blogPostShowTabImages    : false,
	showImageSelect          : false
};

export const actionTypes = {
	SET_PROJECT_DATA             : 'SET_PROJECT_DATA',
	SET_PROJECT_ID_NOTE          : 'SET_PROJECT_ID_NOTE',
	SHOW_PROJECT_COMPANY_INFO    : 'SHOW_PROJECT_COMPANY_INFO',
	SHOW_PROJECT_SEO             : 'SHOW_PROJECT_SEO',
	SHOW_PROJECT_ABOUT_US        : 'SHOW_PROJECT_ABOUT_US',
	SHOW_PROJECT_CALL_TO_ACTION  : 'SHOW_PROJECT_CALL_TO_ACTION',
	SHOW_PROJECT_GALLERY         : 'SHOW_PROJECT_GALLERY',
	SHOW_PROJECT_FAVICONS        : 'SHOW_PROJECT_FAVICONS',
	SHOW_LOGO_FONTS              : 'SHOW_LOGO_FONTS',
	SHOW_LOGO_ICONS              : 'SHOW_LOGO_ICONS',
	SHOW_FONTS_SELECT            : 'SHOW_FONTS_SELECT',
	SHOW_SERVICE_FEAT_IMAGE_FORM : 'SHOW_SERVICE_FEAT_IMAGE_FORM',
	SHOW_SERVICE_CONTENT         : 'SHOW_SERVICE_CONTENT',
	SHOW_SERVICE_TAB_IMAGES      : 'SHOW_SERVICE_TAB_IMAGES',
	SHOW_SERVICE_ICONS           : 'SHOW_SERVICE_ICONS',
	SHOW_BLOGPOST_CONTENT        : 'SHOW_BLOGPOST_CONTENT',
	SHOW_BLOGPOST_TAB_IMAGES     : 'SHOW_BLOGPOST_TAB_IMAGES',
	SHOW_IMAGE_SELECT            : 'SHOW_IMAGE_SELECT'
};

const reducer = (state, action) => {
	//console.log(action);
	switch (action.type) {
		case actionTypes.SET_PROJECT_DATA:
			return {
				...state,
				projectData : action.projectData
			};
		case actionTypes.SET_PROJECT_ID_NOTE:
			return {
				...state,
				projectIdNote : action.projectIdNote
			};
		case actionTypes.SHOW_PROJECT_COMPANY_INFO:
			return {
				...state,
				projectShowCompanyInfo : action.projectShowCompanyInfo
			};
		case actionTypes.SHOW_PROJECT_SEO:
			return {
				...state,
				projectShowSEO : action.projectShowSEO
			};
		case actionTypes.SHOW_PROJECT_ABOUT_US:
			return {
				...state,
				projectShowAboutUs : action.projectShowAboutUs
			};
		case actionTypes.SHOW_PROJECT_CALL_TO_ACTION:
			return {
				...state,
				projectShowCallToAction : action.projectShowCallToAction
			};
		case actionTypes.SHOW_PROJECT_GALLERY:
			return {
				...state,
				projectShowGallery : action.projectShowGallery
			};
		case actionTypes.SHOW_PROJECT_FAVICONS:
			return {
				...state,
				projectShowFavicons : action.projectShowFavicons
			};
		case actionTypes.SHOW_LOGO_FONTS:
			return {
				...state,
				logoShowFonts : action.logoShowFonts
			};
		case actionTypes.SHOW_LOGO_ICONS:
			return {
				...state,
				logoShowIcons : action.logoShowIcons
			};
		case actionTypes.SHOW_FONTS_SELECT:
			return {
				...state,
				showFontsSelect : action.showFontsSelect
			};
		case actionTypes.SHOW_SERVICE_FEAT_IMAGE_FORM:
			return {
				...state,
				serviceShowFeatImageForm : action.serviceShowFeatImageForm
			};
		case actionTypes.SHOW_SERVICE_CONTENT:
			return {
				...state,
				serviceShowContent : action.serviceShowContent
			};
		case actionTypes.SHOW_SERVICE_TAB_IMAGES:
			return {
				...state,
				serviceShowTabImages : action.serviceShowTabImages
			};
		case actionTypes.SHOW_SERVICE_ICONS:
			return {
				...state,
				serviceShowIcons : action.serviceShowIcons
			};
		case actionTypes.SHOW_BLOGPOST_CONTENT:
			return {
				...state,
				blogPostShowContent : action.blogPostShowContent
			};
		case actionTypes.SHOW_BLOGPOST_TAB_IMAGES:
			return {
				...state,
				blogPostShowTabImages : action.blogPostShowTabImages
			};
		case actionTypes.SHOW_IMAGE_SELECT:
			return {
				...state,
				showImageSelect : action.showImageSelect
			};
		default:
			return state;
	}
};

export default reducer;
