import { Fragment, useEffect } from 'react';

const SessionLoader = ({ setSessionLoaded }) => {
	//const [ chatLoaded, setChatLoaded ] = useState(false);

	// useEffect(() => {
	// 	handleLCGroup();
	// }, []);

	useEffect(() => {
		handleLocationChange();
	}, []);

	// const handleLCGroup = () => {
	// 	if (!chatLoaded) {
	// 		// load LC script
	// 		window.__lc = window.__lc || {};
	// 		window.__lc.license = 10943002;

	// 		window.__lc.group = 2;

	// 		var lc = document.createElement('script');
	// 		lc.type = 'text/javascript';
	// 		lc.async = true;
	// 		lc.src =
	// 			('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
	// 		var s = document.getElementsByTagName('script')[0];
	// 		s.parentNode.insertBefore(lc, s);

	// 		setChatLoaded(true);
	// 	}
	// };

	const handleLocationChange = async () => {
		setSessionLoaded(true);
	};

	return <Fragment />;
};

export default SessionLoader;
