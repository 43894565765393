import { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { setSVGFilter } from '../../CommonFunctions';

const ImageSVG = ({ id, hexColor }) => {
	const [ filterValues, setFilterValues ] = useState('');

	useEffect(
		() => {
			if (hexColor) {
				let fValues = setSVGFilter(hexColor);
				setFilterValues(fValues);
			}
		},
		[ hexColor ]
	);

	return (
		<Fragment>
			<svg id={`svgImage${id}`} className="svg-image-preview">
				<filter colorInterpolationFilters="sRGB" id={id}>
					<feColorMatrix in="SourceGraphic" type="matrix" values={filterValues} />
				</filter>
			</svg>
		</Fragment>
	);
};

export default ImageSVG;
