import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import LogoForm from '../logoForm/LogoForm';
import LogoFonts from '../logoFonts/LogoFonts';
import LogoIcons from '../logoIcons/LogoIcons';
import Spinner from '../../spinner/Spinner';
import { GetProjectSettings } from '../../../Service';
import ImageSVG from '../../imageUtil/ImageSVG';
import { createGuid } from '../../../CommonFunctions';
import { toast } from 'react-toastify';
import './LogoMainPage.css';

const LogoMainPage = () => {
	const [ { projectData, logoShowFonts, logoShowIcons }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const logoColorTransformFilter = 'logoColorTransformFilter';

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	const loadProjectSettings = async () => {
		setIsLoading(true);

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					{projectData && projectData.logo ? (
						<Fragment>
							<div className="left-container">
								{logoShowFonts ? (
									<LogoFonts />
								) : logoShowIcons ? (
									<LogoIcons layout={projectData.logo.layout} />
								) : (
									<LogoForm />
								)}
							</div>
							<div className="right-container">
								<div className="logo-preview">
									<div className={`preview-area ${projectData.logo.layout}`}>
										{projectData.logo.layout === 'horizontal' ? (
											<div className="icon-preview" style={projectData.logo.cssStyleIcon}>
												{projectData.logo.horizontal.iconPreview ? (
													<img
														src={`${projectData.logo.horizontal
															.iconPreview}?v=${createGuid()}`}
														style={projectData.logo.cssStyleIconSize}
													/>
												) : projectData.logo.horizontal.icon ? (
													<img
														src={`${projectData.logo.horizontal.icon}?v=${createGuid()}`}
														style={projectData.logo.cssStyleIconSize}
													/>
												) : (
													''
												)}
											</div>
										) : (
											<div className="icon-preview" style={projectData.logo.cssStyleIcon}>
												{projectData.logo.vertical.iconPreview ? (
													<img
														src={`${projectData.logo.vertical
															.iconPreview}?v=${createGuid()}`}
														style={projectData.logo.cssStyleIconSize}
													/>
												) : projectData.logo.vertical.icon ? (
													<img
														src={`${projectData.logo.vertical.icon}?v=${createGuid()}`}
														style={projectData.logo.cssStyleIconSize}
													/>
												) : (
													''
												)}
											</div>
										)}
										{projectData.logo.fontTypePreview ? (
											<div
												className="text-preview"
												style={{
													...projectData.logo.cssStyleText,
													...{ fontFamily: projectData.logo.fontTypePreview }
												}}
											>
												{projectData.company}
											</div>
										) : (
											<div className="text-preview" style={projectData.logo.cssStyleText}>
												{projectData.company}
											</div>
										)}
									</div>
								</div>
							</div>
							<ImageSVG id={logoColorTransformFilter} hexColor={projectData.primaryColor} />
						</Fragment>
					) : (
						<div className="text-center">
							<Spinner />
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default LogoMainPage;
