import { Fragment } from 'react';
import './TestimonialPreviewItem.css';

const TestimonialPreviewItem = ({ item }) => {
	return (
		<Fragment>
			<div className="container-fluid section-testimonial backdrop px-5 pt-4 pb-5">
				<div className="container">
					{item && (
						<div className="testimonial-item-preview">
							<div
								className="img-testimonial img-rounded"
								style={
									item.imageUploaded ? (
										{
											backgroundImage : `url(${item.imagePreview
												? item.imagePreview
												: item.imageUploaded})`
										}
									) : (
										{
											backgroundImage : `url(${item.imagePreview
												? item.imagePreview
												: item.image})`
										}
									)
								}
							/>

							{item.title && <h5 className="testimonial-name">{item.title}</h5>}

							{item.stars &&
							!isNaN(item.stars) && (
								<div className="testimonial-stars">
									{Array.from(Array(5), (e, i) => {
										return i < item.stars ? (
											<i className="fas fa-star" key={i} />
										) : (
											<i className="far fa-star" key={i} />
										);
									})}
								</div>
							)}

							{item.position && <div className="testimonial-position">{item.position}</div>}
							{item.location && <div className="testimonial-location">{item.location}</div>}
							{item.message && <p className="testimonial-text">{item.message}</p>}
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default TestimonialPreviewItem;
