import { Fragment, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import TinyMCEEditor from '../../tinyMCEEditor/TinyMCEEditor';
import './ServiceContent.css';

const ServiceContent = () => {
	const [ { projectData, serviceShowContent }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ serviceContent, setServiceContent ] = useState('');
	const [ formData, setFormData ] = useState('');
	const match = useRouteMatch();
	const serviceId = match.params.serviceId;

	useEffect(() => {
		getContent();
	}, []);

	const getContent = () => {
		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

				if (serviceItem) {
					if (serviceItem.content) {
						setFormData({
							Content : serviceItem.content
						});

						setServiceContent(serviceItem.content);
					}

					// dispatch({
					// 	type        : actionTypes.SET_PROJECT_DATA,
					// 	projectData : tempProjectData
					// });
				}
			}
		}
	};

	const applyContent = (e) => {
		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

				if (serviceItem) {
					if (serviceItem.contentPreview) {
						serviceItem.content = serviceItem.contentPreview;
						delete serviceItem.contentPreview;
					}

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
					dispatch({
						type               : actionTypes.SHOW_SERVICE_CONTENT,
						serviceShowContent : false
					});
					dispatch({
						type                 : actionTypes.SHOW_SERVICE_TAB_IMAGES,
						serviceShowTabImages : false
					});
				}
			}
		}
	};

	const cancelContent = (e) => {
		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

			if (serviceItem) {
				if (serviceItem.contentPreview) {
					delete serviceItem.contentPreview;
				}

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
				dispatch({
					type               : actionTypes.SHOW_SERVICE_CONTENT,
					serviceShowContent : false
				});
				dispatch({
					type                 : actionTypes.SHOW_SERVICE_TAB_IMAGES,
					serviceShowTabImages : false
				});
			}
		}
	};

	const handleServiceContentChange = (editorContent) => {
		setServiceContent(editorContent);

		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

				if (serviceItem) {
					serviceItem.contentPreview = editorContent;

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
				}
			}
		}
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					<div className="content-wrapper">
						<div className="row mb-3 px-0">
							<div className="col-12">
								<label htmlFor="Title">Service Content</label>
								<TinyMCEEditor
									id="editorServiceContent"
									name="ServiceContent"
									value={formData.Content}
									onEditorChange={handleServiceContentChange}
									height="calc(100vh - 225px)"
								/>
							</div>
						</div>
					</div>
					<div className="form-buttons-wrapper">
						<div className="row mb-0 px-2">
							<div className="col-12">
								<button type="button" className="btn btn-primary btn-lg me-3" onClick={applyContent}>
									Apply
								</button>
								<button
									type="button"
									className="btn btn-outline-primary btn-lg me-3"
									onClick={cancelContent}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ServiceContent;
