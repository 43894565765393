import './FormTitleContainer.css';

const FormTitleContainer = ({ title }) => {
	return (
		<div className="form-title-container">
			<h5 className="form-title-text">{title}</h5>
		</div>
	);
};

export default FormTitleContainer;
