import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import { GetProjectSettings, SaveProjectSettings, UploadFile } from '../../Service';
import { createGuid, webServiceUrl } from '../../CommonFunctions';
import { toast } from 'react-toastify';
import FormTitleContainer from '../formTitleContainer/FormTitleContainer';
import ImageSelect from '../imageSelect/ImageSelect';
import './Gallery.css';

const Gallery = () => {
	const [ { projectData, showImageSelect }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ galleryImages, setGalleryImages ] = useState([]);
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectData, showImageSelect ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData.gallery instanceof Array === false) {
				tempProjectData.gallery = [];
			}

			setGalleryImages(tempProjectData.gallery);
		}
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (target === 'Completed' && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (target === 'Completed') {
			tempProjectData.gallery.completed = checked;
		}

		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : tempProjectData
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = projectData;
		tempProjectData.gallery.completed = formData.Completed;

		let data = {};
		data.ProjectId = projectId;
		const settings = await GetProjectSettings(data);

		if (settings !== null) {
			settings.gallery = tempProjectData.gallery;

			//update the json
			let data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(settings);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				toast.success('Settings updated successfully.');
			}
		}

		setIsSaving(false);
	};

	const openImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	const onImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const onImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			let data = new FormData();
			data.append('ProjectId', tempProjectData.guid);
			data.append('FileName', file.name.substring(0, file.name.lastIndexOf('.')) || file.name);
			data.append('UploadedFile', file);

			const response = await UploadFile(data);

			if (response.IsSuccessful) {
				let fileUrl = webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

				if (tempProjectData.gallery instanceof Array === false) {
					tempProjectData.gallery = [];
				}

				tempProjectData.gallery.push({
					guid       : createGuid(),
					dpid       : '',
					title      : response.Data,
					thumb      : fileUrl,
					thumbLarge : fileUrl,
					thumbMax   : fileUrl,
					urlBig     : fileUrl
				});

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const removeImage = (guid) => {
		if (guid && galleryImages) {
			let tempGalleryImages = galleryImages;

			let image = tempGalleryImages.find((x) => x.guid === guid);

			if (image) {
				tempGalleryImages = tempGalleryImages.filter((x) => x.guid !== guid);

				setGalleryImages(tempGalleryImages);

				let tempProjectData = projectData;
				tempProjectData.gallery = tempGalleryImages;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const closeGallery = () => {
		dispatch({
			type               : actionTypes.SHOW_PROJECT_GALLERY,
			projectShowGallery : false
		});
	};

	return (
		<Fragment>
			{showImageSelect ? (
				<ImageSelect type="gallery" />
			) : (
				<Fragment>
					{formData && (
						<form onSubmit={handleSubmit}>
							<div className="form-fields-wrapper">
								<FormTitleContainer title="Gallery Images" />
								<div className="row mb-3 px-4">
									<div className="images-container border">
										{galleryImages &&
											galleryImages.length > 0 &&
											galleryImages.map((image, index) => (
												<div className="image-container" key={index}>
													<img className="border" src={image.thumbLarge} />
													<button
														className="btn btn-link p-0 close-image"
														onClick={() => removeImage(image.guid)}
													>
														<i className="fas fa-times" />
													</button>
												</div>
											))}
									</div>
								</div>
								<div className="row mb-3 px-2">
									<div className="col-10 pe-0">
										<div>
											<button
												onClick={openImages}
												className="btn btn-primary w-100"
												title="Select Hero Image"
											>
												<span>Search</span>
											</button>
										</div>
									</div>
									<div className="col-2">
										<button type="button" onClick={onImageUpload} className="btn btn-primary w-100">
											<span>
												<i className="fas fa-cloud-upload-alt" />
											</span>
										</button>
										<input
											type="file"
											onChange={onImageChange}
											className="d-none"
											id="uploadImage"
										/>
									</div>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div className="row mb-0 px-2">
									<div className="col-10">
										<button type="submit" className="btn btn-primary btn-lg me-3">
											<span>Save</span>
										</button>
										<button
											type="button"
											onClick={closeGallery}
											className="btn btn-outline-primary btn-lg me-3"
										>
											<span>Close</span>
										</button>
									</div>
									<div className="col-2 d-flex justify-content-end align-items-center">
										<input
											type="checkbox"
											name="Completed"
											className="form-check-input check-completed"
											defaultChecked={formData.Completed}
											onClick={handleChange}
											title="Is Completed?"
										/>
									</div>
								</div>
							</div>
						</form>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default Gallery;
