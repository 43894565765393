import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import NoDataMessage from '../../noDataMessage/NoDataMessage';
import AddProjectDialog from '../projectNew/AddProjectDialog';
import ProjectAddNoteDialog from '../projectAddNoteDialog/ProjectAddNoteDialog';
import { GetAllProjects, GetProjectSettings, SaveProjectSettings } from '../../../Service';
import { toast } from 'react-toastify';
import './ProjectsList.css';

const ProjectsList = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isPageLoaded, setIsPageLoaded ] = useState(false);
	const history = useHistory();
	const [ activeProjectsList, setActiveProjectsList ] = useState([]);
	const [ archivedProjectsList, setArchivedProjectsList ] = useState([]);
	const [ addProjectDialog, setAddProjectDialog ] = useState(false);
	const [ addNoteDialog, setAddNoteDialog ] = useState(false);
	const [ projectNote, setProjectNote ] = useState('');
	const [ searchTerm, setSearchTerm ] = useState('');

	useEffect(
		() => {
			getProjects();
		},
		[ searchTerm ]
	);

	const getProjects = async () => {
		const response = await GetAllProjects();

		if (response && response.error) {
			toast.error(response.error);
			setIsLoading(false);

			return;
		}

		if (response) {
			let tempProjectsList = response;
			let tempActiveProjectsList = [];
			let tempArchivedProjectsList = [];

			if (searchTerm !== undefined && searchTerm !== null && searchTerm !== '') {
				tempProjectsList = tempProjectsList.filter((item) => {
					return (
						item.Name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
						item.Note.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
						item.Industry.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
						item.Guid.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
					);
				});
			}

			if (tempProjectsList) {
				tempProjectsList = tempProjectsList.forEach((item) => {
					if (item.Archived && item.Archived === true) {
						tempArchivedProjectsList.push(item);
					} else {
						tempActiveProjectsList.push(item);
					}
				});
			}

			setActiveProjectsList(tempActiveProjectsList);
			setArchivedProjectsList(tempArchivedProjectsList);
			setIsPageLoaded(true);
		}

		setIsLoading(false);
	};

	const showAddProjectDialog = () => {
		setAddProjectDialog(true);
	};

	const hideAddProjectDialog = () => {
		setAddProjectDialog(false);
	};

	const showAddNoteDialog = () => {
		setAddNoteDialog(true);
	};

	const hideAddNoteDialog = () => {
		setAddNoteDialog(false);
	};

	const onSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const onSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			setSearchTerm(e.target.value);
		}
	};

	const editProject = (projectId) => {
		dispatch({
			type        : actionTypes.SET_PROJECT_DATA,
			projectData : null
		});

		history.push(`/project/${projectId}`);
	};

	const getProjectNote = async (projectId) => {
		dispatch({
			type          : actionTypes.SET_PROJECT_ID_NOTE,
			projectIdNote : projectId
		});

		let tempProjectData = {};

		let data = {};
		data.ProjectId = projectId;
		tempProjectData = await GetProjectSettings(data);

		if (tempProjectData !== null) {
			setProjectNote(tempProjectData.note);
		}

		showAddNoteDialog();
	};

	const archiveProject = async (projectId) => {
		let tempProjectData = {};

		let data = {};
		data.ProjectId = projectId;
		tempProjectData = await GetProjectSettings(data);

		if (tempProjectData !== null) {
			tempProjectData.archived = true;

			//update the json
			data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(tempProjectData);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				//toast.success('Settings updated successfully.');
				getProjects();
			}
		}
	};

	const unarchiveProject = async (projectId) => {
		let tempProjectData = {};

		let data = {};
		data.ProjectId = projectId;
		tempProjectData = await GetProjectSettings(data);

		if (tempProjectData !== null) {
			tempProjectData.archived = false;

			//update the json
			data = {};
			data.ProjectId = projectId;
			data.ProjectJson = JSON.stringify(tempProjectData);

			const serviceResponse = await SaveProjectSettings(data);

			if (serviceResponse.IsSuccessful) {
				//toast.success('Settings updated successfully.');
				getProjects();
			}
		}
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					<div className="container-fluid projects-table-container px-0">
						<div className="container px-0">
							<div className="row">
								<div className="col-lg-2 col-sm-12 offset-lg-7 text-end">
									<a className="btn btn-primary" onClick={showAddProjectDialog}>
										<span>New Project</span>
									</a>
								</div>
								<div className="col-lg-3 col-sm-12 text-end">
									<span className="input-group-append">
										<button
											type="button"
											className="btn btn-outline-secondary border-start-0 border btn-input-search"
											onClick={getProjects}
											title="Search Projects"
										>
											<i className="fa fa-search" />
										</button>
									</span>
									<input
										type="search"
										className="form-control border"
										onChange={onSearchChange}
										onKeyDown={onSearchKeyDown}
										value={searchTerm}
										placeholder="Search Projects"
									/>
								</div>
							</div>
						</div>
					</div>
					<nav>
						<div className="nav nav-tabs" id="navTabProjects" role="tablist">
							<button
								className="nav-link active"
								id="nav-active-tab"
								data-bs-toggle="tab"
								data-bs-target="#nav-active"
								type="button"
								role="tab"
								aria-controls="nav-active"
								aria-selected="true"
							>
								Active
							</button>
							<button
								className="nav-link"
								id="nav-archived-tab"
								data-bs-toggle="tab"
								data-bs-target="#nav-archived"
								type="button"
								role="tab"
								aria-controls="nav-archived"
								aria-selected="false"
							>
								Archived
							</button>
						</div>
					</nav>
					<div className="tab-content projects-tab border" id="tabProjects">
						<div
							className="tab-pane fade active show"
							id="nav-active"
							role="tabpanel"
							aria-labelledby="nav-active-tab"
						>
							<div className="container-fluid projects-table-container px-0 py-4">
								<div className="container">
									<div className="row">
										<div className="col-12 text-center">
											<Fragment>
												{activeProjectsList && activeProjectsList.length > 0 ? (
													<Fragment>
														<div className="projects-list">
															<div className="table-projects-list rw-table border">
																<div className="rw-table-header">
																	<div className="rw-th name">Name</div>
																	<div className="rw-th industry">Industry</div>
																	<div className="rw-th guid">Guid</div>
																	<div className="rw-th submitted">Submitted</div>
																	<div className="rw-th completed">Completed</div>
																	<div className="rw-th action" />
																</div>

																<div className="rw-table-data">
																	{activeProjectsList.map((project) => (
																		<div className="rw-row" key={project.Guid}>
																			<div className="rw-td name text-truncate">
																				<span>{project.Name}</span>
																			</div>
																			<div className="rw-td industry">
																				<span>
																					{project.Industry ? (
																						project.Industry
																					) : (
																						''
																					)}
																				</span>
																			</div>
																			<div className="rw-td guid">
																				<span>{project.Guid}</span>
																			</div>
																			<div className="rw-td submitted">
																				<span>
																					<input
																						className="check-completed list mt-2 disabled"
																						type="checkbox"
																						defaultChecked={
																							project.SubmissionUrl ? (
																								true
																							) : (
																								false
																							)
																						}
																						title="Completed"
																					/>
																				</span>
																			</div>
																			<div className="rw-td completed">
																				<div className="position-relative">
																					<div className="progress-number">{`${project.CompletedPercentage}%`}</div>
																					<div className="progress">
																						<div
																							className="progress-bar"
																							role="progressbar"
																							// aria-valuenow="75"
																							aria-valuemin="0"
																							aria-valuemax="100"
																							style={{
																								width : isPageLoaded
																									? `${project.CompletedPercentage}%`
																									: 0
																							}}
																						/>
																					</div>
																				</div>
																			</div>
																			<div className="rw-td action">
																				<button
																					type="button"
																					onClick={() =>
																						editProject(project.Guid)}
																					className="btn btn-primary me-2"
																					title="Edit Project"
																				>
																					<span>
																						<i className="fas fa-pencil-alt" />
																					</span>
																				</button>
																				<button
																					type="button"
																					onClick={() =>
																						getProjectNote(project.Guid)}
																					className={
																						project.Note ? (
																							'btn btn-success me-2'
																						) : (
																							'btn btn-primary me-2'
																						)
																					}
																					title="Add/Edit Note"
																				>
																					<span>
																						<i className="far fa-file-alt" />
																					</span>
																				</button>
																				<button
																					type="button"
																					onClick={() =>
																						archiveProject(project.Guid)}
																					className="btn btn-primary"
																					title="Archive Project"
																				>
																					<span>
																						<i className="fas fa-folder-plus" />
																					</span>
																				</button>
																			</div>
																		</div>
																	))}
																</div>
															</div>
														</div>
													</Fragment>
												) : (
													<NoDataMessage message="No projects found" />
												)}
											</Fragment>
										</div>
									</div>
								</div>
								{addProjectDialog && (
									<AddProjectDialog closeDialog={hideAddProjectDialog} callback={getProjects} />
								)}
								{addNoteDialog && (
									<ProjectAddNoteDialog
										note={projectNote}
										closeDialog={hideAddNoteDialog}
										callback={getProjects}
									/>
								)}
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="nav-archived"
							role="tabpanel"
							aria-labelledby="nav-archived-tab"
						>
							<div className="container-fluid projects-table-container px-0 py-4">
								<div className="container">
									<div className="row">
										<div className="col-12 text-center">
											<Fragment>
												{archivedProjectsList && archivedProjectsList.length > 0 ? (
													<Fragment>
														<div className="projects-list">
															<div className="table-projects-list rw-table">
																<div className="rw-table-header">
																	<div className="rw-th name">Name</div>
																	<div className="rw-th industry">Industry</div>
																	<div className="rw-th guid">Guid</div>
																	<div className="rw-th completed">Completed</div>
																	<div className="rw-th action" />
																</div>

																{archivedProjectsList.map((project) => (
																	<div className="rw-row" key={project.Guid}>
																		<div className="rw-td name text-truncate">
																			<span>{project.Name}</span>
																		</div>
																		<div className="rw-td industry">
																			<span>
																				{project.Industry ? (
																					project.Industry
																				) : (
																					''
																				)}
																			</span>
																		</div>
																		<div className="rw-td guid">
																			<span>{project.Guid}</span>
																		</div>
																		<div className="rw-td completed">
																			<div className="position-relative">
																				<div className="progress-number">{`${project.CompletedPercentage}%`}</div>
																				<div className="progress">
																					<div
																						className="progress-bar"
																						role="progressbar"
																						// aria-valuenow="75"
																						aria-valuemin="0"
																						aria-valuemax="100"
																						style={{
																							width : isPageLoaded
																								? `${project.CompletedPercentage}%`
																								: 0
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																		<div className="rw-td action">
																			<button
																				type="button"
																				onClick={() =>
																					editProject(project.Guid)}
																				className="btn btn-primary me-2"
																				title="Edit Project"
																			>
																				<span>
																					<i className="fas fa-pencil-alt" />
																				</span>
																			</button>
																			<button
																				type="button"
																				onClick={() =>
																					getProjectNote(project.Guid)}
																				className={
																					project.Note ? (
																						'btn btn-success me-2'
																					) : (
																						'btn btn-primary me-2'
																					)
																				}
																				title="Add/Edit Note"
																			>
																				<span>
																					<i className="far fa-file-alt" />
																				</span>
																			</button>
																			<button
																				type="button"
																				onClick={() =>
																					unarchiveProject(project.Guid)}
																				className="btn btn-primary"
																				title="Unarchive Project"
																			>
																				<span>
																					<i className="fas fa-folder-minus" />
																				</span>
																			</button>
																		</div>
																	</div>
																))}
															</div>
														</div>
													</Fragment>
												) : (
													<NoDataMessage message="No projects found" />
												)}
											</Fragment>
										</div>
									</div>
								</div>
								{addProjectDialog && (
									<AddProjectDialog closeDialog={hideAddProjectDialog} callback={getProjects} />
								)}
								{addNoteDialog && (
									<ProjectAddNoteDialog
										note={projectNote}
										closeDialog={hideAddNoteDialog}
										callback={getProjects}
									/>
								)}
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ProjectsList;
