import { Fragment, useState, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { GetIcons } from '../../../Service';
import Spinner from '../../spinner/Spinner';
import { toast } from 'react-toastify';

const ServiceIcons = () => {
	const [ { projectData, logoShowIcons }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ iconsList, setIconsList ] = useState([]);
	const [ iconsListPage, setIconsListPage ] = useState(1);
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ scrollPosition, setScrollPosition ] = useState(0);
	const divIconsListRef = useRef();
	const match = useRouteMatch();
	const serviceId = match.params.serviceId;

	useEffect(
		() => {
			//set scroll position
			if (divIconsListRef.current) {
				setTimeout(() => {
					divIconsListRef.current.scroll(0, scrollPosition);
				}, 50);
			}
		},
		[ iconsList ]
	);

	const getIcons = async (pageNumber) => {
		if (searchTerm && searchTerm !== '') {
			let currentIconsList = iconsList;
			setIsLoading(true);

			if (pageNumber === undefined || pageNumber === null || pageNumber <= 1) {
				pageNumber = 1;
				setIconsListPage(1);
				setIconsList([]);
				currentIconsList = [];
			}

			let data = {};
			data.Key = 'e9g3d4af64bd434fa95f43f64469e418';
			data.SearchTerm = searchTerm;
			data.Limit = 50;
			data.Page = pageNumber;
			data.Offset = 0;

			const response = await GetIcons(data);

			if (response.IsSuccessful) {
				let tempIconsList = JSON.parse(response.Data);

				if (currentIconsList) {
					tempIconsList = [ ...currentIconsList, ...tempIconsList ];
				}

				setIconsList(tempIconsList);
			} else {
				toast.error('Error: ' + response.Message);
			}

			setIsLoading(false);
		}
	};

	const viewMoreIcons = () => {
		let tempIconsListPage = iconsListPage;
		tempIconsListPage++;
		setIconsListPage(tempIconsListPage);

		getIcons(tempIconsListPage);
	};

	const handleIconsListScroll = (e) => {
		if (e.target.scrollTop > 0) {
			setScrollPosition(e.target.scrollTop);
		}
	};

	const selectIcon = async (e) => {
		Array.from(document.querySelectorAll('.icon-card')).forEach(function(el) {
			el.classList.remove('active');
		});

		e.currentTarget.classList.add('active');

		let selectedIcon = e.currentTarget.querySelector('.icon-image');

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

			if (serviceItem) {
				serviceItem.feat.iconPreview = selectedIcon.src;
				serviceItem.feat.iconPreviewOriginal = selectedIcon.src;

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const applyIcon = async (e) => {
		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

				if (serviceItem) {
					if (serviceItem.feat.iconPreview) {
						serviceItem.feat.icon = serviceItem.feat.iconPreview;
						delete serviceItem.feat.iconPreview;
						delete serviceItem.feat.iconUploaded;
					}

					if (serviceItem.feat.iconPreviewOriginal) {
						serviceItem.feat.iconOriginal = serviceItem.feat.iconPreviewOriginal;
						delete serviceItem.feat.iconPreviewOriginal;
					}

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
					dispatch({
						type             : actionTypes.SHOW_SERVICE_ICONS,
						serviceShowIcons : false
					});
				}
			}
		}
	};

	const cancelIcon = (e) => {
		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let serviceItem = tempProjectData.services.find((x) => x.guid === serviceId);

			if (serviceItem) {
				if (serviceItem.feat.iconPreview) {
					delete serviceItem.feat.iconPreview;
				}

				if (serviceItem.feat.iconOriginal) {
					delete serviceItem.feat.iconOriginal;
				}

				if (serviceItem.feat.iconPreviewOriginal) {
					delete serviceItem.feat.iconPreviewOriginal;
				}

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
				dispatch({
					type             : actionTypes.SHOW_SERVICE_ICONS,
					serviceShowIcons : false
				});
			}
		}
	};

	const onSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const onSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			setSearchTerm(e.target.value);
			getIcons(1);
		}
	};

	return (
		<Fragment>
			<div className="search-list-wrapper">
				<div className="search-icons-wrapper input-group mb-3">
					<input
						type="search"
						className="form-control border-end-0 border"
						onKeyDown={onSearchKeyDown}
						onChange={onSearchChange}
						value={searchTerm}
						placeholder="Enter search term"
					/>
					<span className="input-group-append">
						<button
							type="button"
							className="btn btn-primary border btn-searh-icons"
							onClick={() => getIcons(1)}
							title="Search Icons"
						>
							<i className="fa fa-search" />
						</button>
					</span>
				</div>
				<div className="icons-list-wrapper" onScroll={handleIconsListScroll} ref={divIconsListRef}>
					{isLoading ? (
						<div className="text-center pt-4">
							<Spinner />
						</div>
					) : (
						<Fragment>
							{iconsList && (
								<div className="icons-list">
									<Fragment>
										{iconsList.map((icon) => (
											<a
												className="icon-card"
												onClick={selectIcon}
												key={icon.Id}
												id={`icon${icon.Id}`}
											>
												<div className="icon-card-wrapper">
													<div className="icon-preview text-center">
														<img className="icon-image" src={icon.PreviewUrl} />
													</div>
												</div>
											</a>
										))}
									</Fragment>
								</div>
							)}
							{iconsList &&
							iconsList.length >= 50 && (
								<div className="icons-list-view-more">
									<a className="btn btn-link" onClick={viewMoreIcons}>
										View More
									</a>
								</div>
							)}
						</Fragment>
					)}
				</div>
			</div>
			<div className="form-buttons-wrapper">
				<div className="row mb-0 px-2">
					<div className="col-12">
						<button type="button" className="btn btn-primary btn-lg me-3" onClick={applyIcon}>
							Apply
						</button>
						<button type="button" className="btn btn-outline-primary btn-lg me-3" onClick={cancelIcon}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ServiceIcons;
