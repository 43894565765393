import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { callToActionPreviewCss } from '../../../CommonFunctions';
import './CallToActionPreview.css';

const CallToActionPreview = ({ iconColor }) => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ tempProjectData, setTempProjectData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			if (projectData) {
				setTempProjectData(projectData);

				let tempProjectData1 = callToActionPreviewCss(projectData, iconColor);

				setTempProjectData(tempProjectData1);
			}
		},
		[ projectData, tempProjectData ]
	);

	return (
		<Fragment>
			{tempProjectData &&
			tempProjectData.callToAction && (
				<div className="container-fluid">
					<style
						dangerouslySetInnerHTML={{
							__html : [
								'.section-call-to-action:before { ' +
									'background: ' +
									(tempProjectData.callToAction.overlayColor || '#000000') +
									'; opacity: ' +
									(tempProjectData.callToAction.overlayOpacity
										? tempProjectData.callToAction.overlayOpacity / 100
										: '35') +
									'; }'
							]
						}}
					/>
					<div
						className="section-call-to-action text-center"
						id={`callToActionPreview_${projectId}`}
						style={
							tempProjectData.callToAction && tempProjectData.callToAction.imageUploaded ? (
								{
									backgroundImage : `url(${tempProjectData.callToAction.imagePreview
										? tempProjectData.callToAction.imagePreview
										: tempProjectData.callToAction.imageUploaded})`
								}
							) : (
								{
									backgroundImage : `url(${tempProjectData.callToAction.imagePreview
										? tempProjectData.callToAction.imagePreview
										: tempProjectData.callToAction.image})`
								}
							)
						}
					>
						{tempProjectData.callToAction.headline && (
							<h2 className="headline">
								<span>{tempProjectData.callToAction.headline}</span>
							</h2>
						)}
						<a
							className="btn btn-lg btn-primary cta-button mt-3"
							style={{
								backgroundColor : projectData.primaryColor ? projectData.primaryColor : '#000000'
							}}
							href={`tel:${tempProjectData.callToAction.buttonUrl || ''}`}
						>
							<span>
								<i className="fas fa-phone-square-alt me-2" aria-hidden="true" />
								{tempProjectData.callToAction.buttonText || ''}
							</span>
						</a>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default CallToActionPreview;
