import { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ProjectsList from '../projects/projectsList/ProjectsList';
import './DefaultPage.css';

const DefaultPage = () => {
	const history = useHistory();
	const [ isLoggedIn, setIsLoggedIn ] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('ff_app')) {
			setIsLoggedIn(true);
		} else {
			history.push('/login');
		}
	}, []);

	return (
		<Fragment>
			<div className="container-fluid projects-list-container">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<div className="row">
								<div className="col-lg-12 col-sm-12 text-center">
									<ProjectsList />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default DefaultPage;
