import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useStateValue } from './../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { toast } from 'react-toastify';
import { GetProjectSettings, SaveProjectSettings } from '../../../Service';
import { UploadFile } from '../../../Service';
import { createGuid, servicePreviewCss, webServiceUrl } from '../../../CommonFunctions';
import './ServiceFeatImage.css';
import ImageSelect from '../../imageSelect/ImageSelect';

const ServiceFeatImage = () => {
	const [ { projectData, showImageSelect, serviceShowIcons }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ overlayColorOutput, setOverlayColorOutput ] = useState('');
	const [ formData, setFormData ] = useState({});
	const [ serviceItem, setServiceItem ] = useState(null);
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const serviceId = match.params.serviceId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ serviceId ]
	);

	useEffect(
		() => {
			if (projectData && projectData.services) {
				let item = projectData.services.find((x) => x.guid === serviceId);

				if (item) {
					if (item.feat === undefined || !item.feat) {
						item.feat = {};
					}

					setFormData({
						Name                 : item.name,
						FeatInheritHeroImage : item.feat ? item.feat.inheritHeroImage : false,
						ShowOverlay          : item.feat ? item.feat.showOverlay : false,
						OverlayColor         :
							item.feat && item.feat.overlayColor ? item.feat.overlayColor : projectData.primaryColor,
						OverlayColorOutput   : overlayColorOutput,
						OverlayOpacity       : item.feat && item.feat.overlayOpacity ? item.feat.overlayOpacity : '80',
						ShowIcon             : item.feat ? item.feat.showIcon : false,
						Icon                 : item.feat ? item.feat.icon : '',
						IconSize             : item.feat && item.feat.iconSize ? item.feat.iconSize : '150',
						BorderType           : item.feat ? item.feat.borderType : 'None',
						BorderWidth          : item.feat && item.feat.borderWidth ? item.feat.borderWidth : '1'
					});

					setServiceItem(item);
					setOverlayColorOutput(item.feat.overlayColor);
				}
			}
		},
		[ projectData, serviceItem, overlayColorOutput ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if (e.target.type === 'checkbox') {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let item = tempProjectData.services.find((x) => x.guid === serviceId);

			if (item) {
				if (item.feat === undefined || !item.feat) {
					item.feat = {};
				}

				if (target === 'Name') {
					item.name = value;
				} else if (target === 'FeatInheritHeroImage') {
					item.feat.inheritHeroImage = checked;
				} else if (target === 'ShowOverlay') {
					item.feat.showOverlay = checked;
				} else if (target === 'OverlayColor' || target === 'OverlayColorOutput') {
					item.feat.overlayColor = value;
					setOverlayColorOutput(value);
				} else if (target === 'OverlayOpacity') {
					item.feat.overlayOpacity = value;
				} else if (target === 'ShowIcon') {
					item.feat.showIcon = checked;
				} else if (target === 'Icon') {
					item.feat.icon = value;
				} else if (target === 'IconSize') {
					item.feat.iconSize = value;
				} else if (target === 'BorderType') {
					item.feat.borderType = value;
				} else if (target === 'BorderWidth') {
					item.feat.borderWidth = value;
				}

				item = servicePreviewCss(tempProjectData, item);
			}

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = {};

		if (projectData) {
			tempProjectData = projectData;
		} else {
			let data = {};
			data.ProjectId = projectId;
			tempProjectData = await GetProjectSettings(data);
		}

		if (tempProjectData) {
			if (tempProjectData && tempProjectData.services) {
				let item = tempProjectData.services.find((x) => x.guid === serviceId);

				if (item) {
					item.name = formData.Name;

					if (item.feat === undefined || !item.feat) {
						item.feat = {};
					}

					item.feat.inheritHeroImage = formData.FeatInheritHeroImage;
					item.feat.showOverlay = formData.ShowOverlay;
					item.feat.overlayColor = formData.OverlayColor;
					item.feat.overlayOpacity = formData.OverlayOpacity;
					item.feat.showIcon = formData.ShowIcon;
					item.feat.icon = formData.Icon;
					item.feat.iconSize = formData.IconSize;
					item.feat.borderType = formData.BorderType;
					item.feat.borderWidth = formData.BorderWidth;

					//update the json
					let data = {};
					data.ProjectId = projectId;
					data.ProjectJson = JSON.stringify(tempProjectData);

					const serviceResponse = await SaveProjectSettings(data);

					if (serviceResponse.IsSuccessful) {
						dispatch({
							type        : actionTypes.SET_PROJECT_DATA,
							projectData : tempProjectData
						});

						toast.success('Settings updated successfully.');
					}
				}
			}
		}

		setIsSaving(false);
	};

	const closeFeatImage = () => {
		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let item = tempProjectData.services.find((x) => x.guid === serviceId);

			if (item) {
				if (item.feat && item.feat.imagePreview) {
					delete item.feat.imagePreview;
				}

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
				dispatch({
					type                     : actionTypes.SHOW_SERVICE_FEAT_IMAGE_FORM,
					serviceShowFeatImageForm : false
				});
			}
		}
	};

	const onFeaturedImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadFeaturedImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeFeaturedImageUpload = (e) => {
		e.preventDefault();

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let item = tempProjectData.services.find((x) => x.guid === serviceId);

			if (item && item.feat && item.feat.imageUploaded) {
				delete item.feat.imageUploaded;

				setServiceItem(item);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const onFeaturedImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadFeaturedImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let item = tempProjectData.services.find((x) => x.guid === serviceId);

				if (item) {
					let data = new FormData();
					data.append('ProjectId', tempProjectData.guid);
					data.append('FileName', 'service-feat-' + tempProjectData.guid);
					data.append('UploadedFile', file);

					const response = await UploadFile(data);

					if (response.IsSuccessful) {
						let imageUrl =
							webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

						item.feat.imageUploaded = imageUrl;

						delete item.feat.image;
						delete item.feat.imagePreview;

						dispatch({
							type        : actionTypes.SET_PROJECT_DATA,
							projectData : tempProjectData
						});
					}
				}
			}
		}
	};

	const openIcons = () => {
		dispatch({
			type             : actionTypes.SHOW_SERVICE_ICONS,
			serviceShowIcons : true
		});
	};

	const openFeatImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
	};

	const onIconSizeInput = (e) => {
		document.getElementById('iconSizeOutput').value = e.currentTarget.value + 'px';
	};

	const onBorderWidthInput = (e) => {
		document.getElementById('borderWidthOutput').value = e.currentTarget.value + 'px';
	};

	const onOverlayOpacityInput = (e) => {
		document.getElementById('overlayOpacityOutput').value = e.currentTarget.value + '%';
	};

	return (
		<Fragment>
			{formData && (
				<Fragment>
					{showImageSelect ? (
						<ImageSelect type="service" subtype="featured" />
					) : (
						<Fragment>
							<form onSubmit={handleSubmit}>
								<div className="form-fields-wrapper">
									<div className="row mb-3 px-2">
										<div className="col-12">
											<label htmlFor="Name">Service Name</label>
											<input
												type="text"
												className="form-control"
												name="Name"
												value={formData.Name}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="row mb-3 px-2">
										<div className="col-12">
											<div className="form-check">
												<input
													type="checkbox"
													name="FeatInheritHeroImage"
													className="form-check-input"
													defaultChecked={formData.FeatInheritHeroImage}
													onClick={handleChange}
												/>
												<label htmlFor="FeatInheritHeroImage" className="form-check-label">
													Inherit Home Hero Image
												</label>
											</div>
										</div>
									</div>
									<div className="row mb-4 px-2">
										<div className="col-10 pe-0">
											<button
												type="button"
												onClick={openFeatImages}
												className="btn btn-primary w-100"
												title="Select Featured Image"
											>
												<span>Featured Image</span>
											</button>
										</div>
										<div className="col-2">
											{serviceItem && serviceItem.feat.imageUploaded ? (
												<button
													type="button"
													onClick={removeFeaturedImageUpload}
													className="btn btn-danger w-100"
												>
													<span>
														<i className="fas fa-trash-alt" />
													</span>
												</button>
											) : (
												<button
													type="button"
													onClick={onFeaturedImageUpload}
													className="btn btn-primary w-100"
												>
													<span>
														<i className="fas fa-cloud-upload-alt" />
													</span>
												</button>
											)}
											<input
												type="file"
												onChange={onFeaturedImageChange}
												className="d-none"
												id="uploadFeaturedImage"
											/>
										</div>
									</div>
									<div className="row mb-1 px-2">
										<div className="col-12">
											<div className="form-check">
												<input
													type="checkbox"
													name="ShowOverlay"
													className="form-check-input"
													defaultChecked={formData.ShowOverlay}
													onClick={handleChange}
												/>
												<label htmlFor="ShowOverlay" className="form-check-label">
													Show Overlay
												</label>
											</div>
										</div>
									</div>
									<div className="row mb-0 px-2">
										<div className="col-6 pe-0">
											<label htmlFor="OverlayColor">Overlay Color</label>
										</div>
										<div className="col-6 pe-0">
											<label htmlFor="OverlayOpacity">Overlay Opacity</label>
										</div>
									</div>
									<div className="row mb-4 px-2">
										<div className="col-3 pe-0">
											<input
												type="color"
												className="form-control form-control-color w-100"
												name="OverlayColor"
												value={formData.OverlayColor}
												onChange={handleChange}
											/>
										</div>
										<div className="col-3 ps-1 d-flex align-items-end">
											<label htmlFor="OverlayColorOutput" />
											<input
												type="text"
												className="form-control"
												name="OverlayColorOutput"
												value={overlayColorOutput}
												placeholder=""
												onChange={handleChange}
											/>
										</div>
										<div className="col-4 pe-2">
											<input
												type="range"
												className="form-range mt-2"
												name="OverlayOpacity"
												min="0"
												max="100"
												value={formData.OverlayOpacity}
												onChange={handleChange}
												onInput={onOverlayOpacityInput}
											/>
										</div>
										<div className="col-2 ps-1 d-flex align-items-end">
											<input
												type="text"
												className="form-control text-center px-0"
												id="overlayOpacityOutput"
												value={`${formData.OverlayOpacity}%`}
												disabled
											/>
										</div>
									</div>
									<div className="row mb-1 px-2">
										<div className="col-12">
											<div className="form-check">
												<input
													type="checkbox"
													name="ShowIcon"
													className="form-check-input"
													defaultChecked={formData.ShowIcon}
													onClick={handleChange}
												/>
												<label htmlFor="ShowIcon" className="form-check-label">
													Show Icon
												</label>
											</div>
										</div>
									</div>
									<div className="row mb-3 px-2">
										<div className="col-5">
											<label htmlFor="Icon">Icon</label>
											<div>
												<a onClick={openIcons} title="Select Icon">
													{serviceItem &&
													serviceItem.feat &&
													serviceItem.feat.iconOriginal ? (
														<img
															className="form-field-image"
															src={`${serviceItem.feat.iconOriginal}?v=${createGuid()}`}
															width="64px"
														/>
													) : serviceItem && serviceItem.feat && serviceItem.feat.icon ? (
														<img
															className="form-field-image"
															src={`${serviceItem.feat.icon}?v=${createGuid()}`}
															width="64px"
														/>
													) : (
														<img
															className="form-field-image"
															src="/img/no_image.png"
															width="64px"
														/>
													)}
												</a>
											</div>
										</div>
										<div className="col-7">
											<div className="row mb-3 px-2">
												<label htmlFor="IconSize">Icon Size</label>
												<div className="col-9">
													<input
														type="range"
														className="form-range mt-2"
														name="IconSize"
														min="20"
														max="200"
														value={formData.IconSize}
														onChange={handleChange}
														onInput={onIconSizeInput}
													/>
												</div>
												<div className="col-3 p-0">
													<input
														type="text"
														className="form-control text-center px-0"
														id="iconSizeOutput"
														value={`${formData.IconSize}px`}
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="row mb-0 px-2">
										<div className="col-5">
											<label htmlFor="BorderType">Border</label>
											<select
												name="BorderType"
												className="form-select"
												value={formData.BorderType}
												onChange={handleChange}
											>
												<option defaultValue>None</option>
												<option value="Squared">Squared</option>
												<option value="Rounded">Rounded</option>
												<option value="Circled">Circled</option>
											</select>
										</div>
										<div className="col-7">
											<div className="row mb-0 px-2">
												<label htmlFor="BorderWidth">Border Width</label>
												<div className="col-9">
													<input
														type="range"
														className="form-range mt-2"
														name="BorderWidth"
														min="1"
														max="20"
														value={formData.BorderWidth}
														onChange={handleChange}
														onInput={onBorderWidthInput}
													/>
												</div>
												<div className="col-3 p-0">
													<input
														type="text"
														className="form-control text-center px-0"
														id="borderWidthOutput"
														value={`${formData.BorderWidth}px`}
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="form-buttons-wrapper">
									<div className="row mb-0 px-2">
										<div className="col-12">
											<button type="submit" className="btn btn-primary btn-lg me-3">
												Save
											</button>
											<button
												type="button"
												className="btn btn-outline-primary btn-lg me-3"
												onClick={closeFeatImage}
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</form>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default ServiceFeatImage;
