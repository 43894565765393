import { Fragment, useState } from 'react';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import { createGuid } from '../../../CommonFunctions';
import Spinner from '../../spinner/Spinner';
import { SaveProjectSettings } from '../../../Service';
import { CopyProjectSettings } from '../../../Service';
import { getCurrentDate } from '../../../CommonFunctions';
import { toast } from 'react-toastify';
import './AddProjectDialog.css';

const AddProjectDialog = ({ closeDialog, callback }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let guid = createGuid();

		//update the json
		let data = {};
		let serviceResponse;

		if (formData.CopyFrom && formData.CopyFrom !== '') {
			data.SourceProjectId = formData.CopyFrom;
			data.NewProjectId = guid;
			data.ProjectName = formData.ProjectName;
			data.FirstName = formData.FirstName;
			data.LastName = formData.LastName;
			data.Company = formData.Company;
			data.Industry = formData.Industry;

			serviceResponse = await CopyProjectSettings(data);
		} else {
			let newProjectObject = {};
			newProjectObject.name = formData.ProjectName;
			newProjectObject.firstName = formData.FirstName;
			newProjectObject.lastName = formData.LastName;
			newProjectObject.company = formData.Company;
			newProjectObject.industry = formData.Industry;
			newProjectObject.guid = guid;
			newProjectObject.primaryColor = '#333333';
			newProjectObject.dateCreated = getCurrentDate();
			newProjectObject.logo = {};
			newProjectObject.logo.fontType = 'Roboto';
			newProjectObject.logo.fontSize = '32';
			newProjectObject.logo.fontWeight = 'normal';
			newProjectObject.logo.icon = '';
			newProjectObject.logo.iconSize = '64';
			newProjectObject.logo.borderType = 'None';
			newProjectObject.logo.borderWidth = '1';
			newProjectObject.hero = {};
			newProjectObject.hero.image = '';
			newProjectObject.hero.headline = 'This Is The Website Headline';
			newProjectObject.hero.headlineFontType = 'Roboto';
			newProjectObject.hero.contentFontType = 'Roboto';

			data.ProjectId = guid;
			data.ProjectJson = JSON.stringify(newProjectObject);

			serviceResponse = await SaveProjectSettings(data);
		}

		if (serviceResponse.IsSuccessful) {
			toast.success('Project created successfully.');
		}

		if (callback) {
			callback();
		}

		closeDialog();

		setIsSaving(false);
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	return (
		<Fragment>
			<ModalDialog>
				<form onSubmit={handleSubmit} autoComplete="off">
					<ModalDialogContent align="start">
						<h2 className="mb-4">New Project</h2>
						<div className="row mb-3 px-2">
							<label htmlFor="ProjectName" className="col-2 mt-2">
								Project Name
							</label>
							<div className="col-10">
								<input
									type="text"
									className="form-control"
									name="ProjectName"
									value={formData.ProjectName}
									placeholder="Enter project name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-3 px-2">
							<label htmlFor="FirstName" className="col-2 mt-2">
								First Name
							</label>
							<div className="col-10">
								<input
									type="text"
									className="form-control"
									name="FirstName"
									value={formData.FirstName}
									placeholder="Enter first name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-3 px-2">
							<label htmlFor="LastName" className="col-2 mt-2">
								Last Name
							</label>
							<div className="col-10">
								<input
									type="text"
									className="form-control"
									name="LastName"
									value={formData.LastName}
									placeholder="Enter last name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-3 px-2">
							<label htmlFor="Company" className="col-2 mt-2">
								Company
							</label>
							<div className="col-10">
								<input
									type="text"
									className="form-control"
									name="Company"
									value={formData.Company}
									placeholder="Enter company name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-3 px-2">
							<label htmlFor="Industry" className="col-2 mt-2">
								Industry
							</label>
							<div className="col-10">
								<input
									type="text"
									className="form-control"
									name="Industry"
									value={formData.Industry}
									placeholder="Enter industry name"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="row mb-3 px-2">
							<label htmlFor="CopyFrom" className="col-2 mt-2">
								Copy From
							</label>
							<div className="col-10">
								<input
									type="text"
									className="form-control"
									name="CopyFrom"
									value={formData.CopyFrom}
									placeholder="Enter project guid to copy from"
									onChange={handleChange}
								/>
							</div>
						</div>
					</ModalDialogContent>
					<ModalDialogButtons align="start" shaded={true}>
						<button type="submit" className="btn btn-primary btn-lg mr-4" disabled={isSaving}>
							{!isSaving ? (
								<Fragment>
									<span>Save</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Saving...</span>
								</Fragment>
							)}
						</button>
						<button
							type="button"
							className="btn btn-outline-primary btn-lg"
							disabled={isSaving}
							onClick={closeDialog}
						>
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default AddProjectDialog;
