import { Fragment, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useStateValue } from './../../../StateProvider';
import { actionTypes } from '../../../reducer';
import { toast } from 'react-toastify';
import { GetProjectSettings, SaveProjectSettings } from '../../../Service';
import { UploadFile } from '../../../Service';
import TinyMCEEditor from '../../tinyMCEEditor/TinyMCEEditor';
import ServiceFeatImage from '../serviceFeatImage/ServiceFeatImage';
import ServiceContent from '../serviceContent/ServiceContent';
import ServiceIcons from '../serviceIcons/ServiceIcons';
import ServiceImagePreview from '../../preview/service/ImagePreview';
import { servicePreviewCss, webServiceUrl } from '../../../CommonFunctions';
import './ServiceDetails.css';
import ImageSelect from '../../imageSelect/ImageSelect';
import FormTitleContainer from '../../formTitleContainer/FormTitleContainer';

const ServiceDetails = () => {
	const [
		{ projectData, showImageSelect, serviceShowFeatImageForm, serviceShowContent, serviceShowIcons },
		dispatch
	] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ serviceItem, setServiceItem ] = useState(null);
	const [ editorTitleContent, setEditorTitleContent ] = useState('');
	const match = useRouteMatch();
	const projectId = match.params.projectId;
	const serviceId = match.params.serviceId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ serviceId ]
	);

	const loadProjectSettings = async () => {
		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		if (projectData && projectData.services) {
			let item = projectData.services.find((x) => x.guid === serviceId);

			setFormData({
				Name             : item.name,
				Keywords         : item.keywords,
				Title            : item.title,
				InheritHeroImage : item.inheritHeroImage,
				Completed        : item.completed
			});

			setEditorTitleContent(item.title);
			setServiceItem(item);
		}
	};

	const handleChange = async (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		if ((target === 'InheritHeroImage' || target === 'Completed') && checked !== undefined) {
			setFormData({
				...formData,
				[target] : checked
			});
		} else {
			setFormData({
				...formData,
				[target] : value
			});
		}

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let item = tempProjectData.services.find((x) => x.guid === serviceId);

			if (target === 'Name') {
				item.name = value;
			} else if (target === 'Keywords') {
				item.keywords = value;
			} else if (target === 'InheritHeroImage') {
				item.inheritHeroImage = checked;
			} else if (target === 'Completed') {
				item.completed = checked;
			}

			item = servicePreviewCss(tempProjectData, item);

			dispatch({
				type        : actionTypes.SET_PROJECT_DATA,
				projectData : tempProjectData
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let tempProjectData = {};

		if (projectData) {
			tempProjectData = projectData;
		} else {
			let data = {};
			data.ProjectId = projectId;
			tempProjectData = await GetProjectSettings(data);
		}

		if (tempProjectData !== null) {
			if (tempProjectData && tempProjectData.services) {
				let item = tempProjectData.services.find((x) => x.guid === serviceId);

				if (item) {
					item.name = formData.Name;
					item.keywords = formData.Keywords;
					item.title = formData.Name; //editorTitleContent;
					item.inheritHeroImage = formData.InheritHeroImage;
					item.completed = formData.Completed;

					//update the json
					let data = {};
					data.ProjectId = projectId;
					data.ProjectJson = JSON.stringify(tempProjectData);

					const serviceResponse = await SaveProjectSettings(data);

					if (serviceResponse.IsSuccessful) {
						toast.success('Settings updated successfully.');
					}
				}
			}
		}

		setIsSaving(false);
	};

	const onHeroImageUpload = (e) => {
		e.preventDefault();

		let uploadButton = document.getElementById('uploadHeroImage');

		if (uploadButton) {
			uploadButton.click();
		}
	};

	const removeHeroImageUpload = (e) => {
		e.preventDefault();

		let tempProjectData = projectData;

		if (tempProjectData && tempProjectData.services) {
			let item = tempProjectData.services.find((x) => x.guid === serviceId);

			if (item && item.heroImageUploaded) {
				delete item.heroImageUploaded;

				setServiceItem(item);

				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : tempProjectData
				});
			}
		}
	};

	const onHeroImageChange = async (e) => {
		//upload file on server
		let file = document.getElementById('uploadHeroImage').files[0];

		if (file) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let item = tempProjectData.services.find((x) => x.guid === serviceId);

				if (item) {
					let data = new FormData();
					data.append('ProjectId', tempProjectData.guid);
					data.append('FileName', 'service-hero-' + tempProjectData.guid);
					data.append('UploadedFile', file);

					const response = await UploadFile(data);

					if (response.IsSuccessful) {
						let imageUrl =
							webServiceUrl() + '/projects/' + tempProjectData.guid + '/assets/' + response.Data;

						item.heroImageUploaded = imageUrl;

						delete item.heroImage;
						delete item.heroImagePreview;

						dispatch({
							type        : actionTypes.SET_PROJECT_DATA,
							projectData : tempProjectData
						});
					}
				}
			}
		}
	};

	const handleEditorTitleChange = (editorContent) => {
		setEditorTitleContent(editorContent);

		if (projectData) {
			let tempProjectData = projectData;

			if (tempProjectData && tempProjectData.services) {
				let item = tempProjectData.services.find((x) => x.guid === serviceId);

				if (item) {
					item.title = editorContent;

					dispatch({
						type        : actionTypes.SET_PROJECT_DATA,
						projectData : tempProjectData
					});
				}
			}
		}
	};

	const openHeroImages = () => {
		dispatch({
			type            : actionTypes.SHOW_IMAGE_SELECT,
			showImageSelect : true
		});
		dispatch({
			type                 : actionTypes.SHOW_SERVICE_TAB_IMAGES,
			serviceShowTabImages : true
		});
	};

	const openFeatImages = () => {
		dispatch({
			type                     : actionTypes.SHOW_SERVICE_FEAT_IMAGE_FORM,
			serviceShowFeatImageForm : true
		});
		dispatch({
			type                 : actionTypes.SHOW_SERVICE_TAB_IMAGES,
			serviceShowTabImages : true
		});
	};

	const openContent = () => {
		dispatch({
			type               : actionTypes.SHOW_SERVICE_CONTENT,
			serviceShowContent : true
		});
	};

	return (
		<Fragment>
			<div className="left-container">
				{formData && (
					<Fragment>
						{showImageSelect ? (
							<ImageSelect type="service" subtype="hero" />
						) : serviceShowContent ? (
							<ServiceContent />
						) : serviceShowIcons ? (
							<ServiceIcons />
						) : serviceShowFeatImageForm ? (
							<ServiceFeatImage />
						) : (
							<Fragment>
								<form onSubmit={handleSubmit}>
									<div className="form-fields-wrapper">
										<FormTitleContainer title="Service Details" />
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Name">Service Name</label>
												<input
													type="text"
													className="form-control"
													name="Name"
													value={formData.Name}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Keywords">Keywords</label>
												<textarea
													className="form-control"
													name="Keywords"
													value={formData.Keywords}
													placeholder="Enter keywords (one per line)"
													onChange={handleChange}
													rows="3"
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<label htmlFor="Title">Title</label>
												<TinyMCEEditor
													id="editorTitle"
													name="Title"
													value={formData.Title}
													onEditorChange={handleEditorTitleChange}
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<div className="form-check">
													<input
														type="checkbox"
														name="InheritHeroImage"
														className="form-check-input"
														defaultChecked={formData.InheritHeroImage}
														onClick={handleChange}
													/>
													<label htmlFor="InheritHeroImage" className="form-check-label">
														Inherit Home Hero Image
													</label>
												</div>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-10 pe-0">
												<button
													type="button"
													onClick={openHeroImages}
													className="btn btn-primary w-100"
													title="Select Hero Image"
												>
													<span>Hero Image</span>
												</button>
											</div>
											<div className="col-2">
												{serviceItem && serviceItem.heroImageUploaded ? (
													<button
														type="button"
														onClick={removeHeroImageUpload}
														className="btn btn-danger w-100"
													>
														<span>
															<i className="fas fa-trash-alt" />
														</span>
													</button>
												) : (
													<button
														type="button"
														onClick={onHeroImageUpload}
														className="btn btn-primary w-100"
													>
														<span>
															<i className="fas fa-cloud-upload-alt" />
														</span>
													</button>
												)}
												<input
													type="file"
													onChange={onHeroImageChange}
													className="d-none"
													id="uploadHeroImage"
												/>
											</div>
										</div>
										<div className="row mb-3 px-2">
											<div className="col-12">
												<button
													type="button"
													onClick={openFeatImages}
													className="btn btn-primary w-100"
													title="Select Featured Image"
												>
													<span>Featured Image</span>
												</button>
											</div>
										</div>
										<div className="row mb-0 px-2">
											<div className="col-12">
												<button
													type="button"
													onClick={openContent}
													className="btn btn-primary w-100"
													title="Build Content"
												>
													<span>Content</span>
												</button>
											</div>
										</div>
									</div>
									<div className="form-buttons-wrapper">
										<div className="row mb-0 px-2">
											<div className="col-10">
												<button type="submit" className="btn btn-primary btn-lg me-3">
													Save
												</button>
												<Link
													to={`/project/${projectId}`}
													className="btn btn-outline-primary btn-lg me-3"
												>
													<span>Close</span>
												</Link>
											</div>
											<div className="col-2 d-flex justify-content-end align-items-center">
												<input
													type="checkbox"
													name="Completed"
													className="form-check-input check-completed"
													defaultChecked={formData.Completed}
													onClick={handleChange}
													title="Is Service Completed?"
												/>
											</div>
										</div>
									</div>
								</form>
							</Fragment>
						)}
					</Fragment>
				)}
			</div>
			<div className="right-container">
				<ServiceImagePreview />
			</div>
		</Fragment>
	);
};

export default ServiceDetails;
