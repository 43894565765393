import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import ImageSVG from '../../imageUtil/ImageSVG';
import { createGuid, aboutUsPreviewCss } from '../../../CommonFunctions';
import './AboutUsPreview.css';

const AboutUsPreview = ({ iconColor }) => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ tempProjectData, setTempProjectData ] = useState({});
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			if (projectData) {
				setTempProjectData(projectData);

				let tempProjectData1 = aboutUsPreviewCss(projectData, iconColor);

				setTempProjectData(tempProjectData1);
			}
		},
		[ projectData, tempProjectData ]
	);

	return (
		<Fragment>
			{tempProjectData &&
			tempProjectData.aboutUs && (
				<div className="aboutus-preview-container h-100 d-flex justify-content-center align-items-center">
					<Fragment>
						<div className="container-fluid section-aboutus w-50 h-50">
							<div
								className="container-fluid section-aboutus w-100 h-100 d-flex justify-content-center align-items-center m-0 p-0"
								id={`aboutUsPreview_${projectId}`}
								style={
									tempProjectData.aboutUs.imageUploaded ? (
										{
											...{
												backgroundImage : `url(${tempProjectData.aboutUs.imageUploaded})`,
												...tempProjectData.aboutUs.cssStyleOverlay
											}
										}
									) : tempProjectData.hero && tempProjectData.hero.image ? (
										{
											...{
												backgroundImage : `url(${tempProjectData.hero.image})`,
												...tempProjectData.aboutUs.cssStyleOverlay
											}
										}
									) : (
										{}
									)
								}
							>
								{tempProjectData &&
								tempProjectData.logo &&
								tempProjectData.logo.layout && (
									<div className={`preview-area ${tempProjectData.logo.layout}`}>
										{tempProjectData.logo.layout === 'horizontal' ? (
											<div className="icon-preview" style={tempProjectData.aboutUs.cssStyleIcon}>
												{tempProjectData.logo.horizontal.icon ? (
													<img
														src={`${tempProjectData.logo.horizontal
															.icon}?v=${createGuid()}`}
														style={tempProjectData.logo.cssStyleIconSize}
														crossOrigin="anonymous"
													/>
												) : (
													''
												)}
											</div>
										) : (
											<div className="icon-preview" style={tempProjectData.aboutUs.cssStyleIcon}>
												{tempProjectData.logo.vertical.icon ? (
													<img
														src={`${tempProjectData.logo.vertical.icon}?v=${createGuid()}`}
														style={tempProjectData.logo.cssStyleIconSize}
														crossOrigin="anonymous"
													/>
												) : (
													''
												)}
											</div>
										)}
										<div className="text-preview" style={tempProjectData.aboutUs.cssStyleText}>
											{tempProjectData.company}
										</div>
										<ImageSVG id="aboutUsColorTransformFilter" hexColor="#ffffff" />
									</div>
								)}
							</div>
						</div>
					</Fragment>
				</div>
			)}
		</Fragment>
	);
};

export default AboutUsPreview;
