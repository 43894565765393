import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import Spinner from '../../spinner/Spinner';
import { GetProjectSettings } from '../../../Service';
import ImageSVG from '../../imageUtil/ImageSVG';
import { createGuid } from '../../../CommonFunctions';
import { toast } from 'react-toastify';
import './FaviconPreview.css';

const FaviconPreview = () => {
	const [ { projectData }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const match = useRouteMatch();
	const projectId = match.params.projectId;

	useEffect(
		() => {
			loadProjectSettings();
		},
		[ projectId ]
	);

	const loadProjectSettings = async () => {
		setIsLoading(true);

		if (!projectData) {
			let data = {};
			data.ProjectId = projectId;
			const settings = await GetProjectSettings(data);

			if (settings !== null) {
				dispatch({
					type        : actionTypes.SET_PROJECT_DATA,
					projectData : settings
				});
			} else {
				toast.error('Could not load project settings.');
			}
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<Fragment>
					{projectData && (
						<Fragment>
							<div className="favicon-preview" id={`faviconPreview_${projectId}`}>
								<div className="preview-area">
									<div className="icon-preview" id={`favicon_${projectId}`}>
										{projectData.favicon && projectData.favicon.iconPreview ? (
											<img
												src={`${projectData.favicon.iconPreview}?v=${createGuid()}`}
												crossOrigin="anonymous"
											/>
										) : projectData.favicon && projectData.favicon.icon ? (
											<img
												src={`${projectData.favicon.icon}?v=${createGuid()}`}
												crossOrigin="anonymous"
											/>
										) : projectData.logo &&
										projectData.logo.layout &&
										projectData.logo.layout === 'horizontal' &&
										projectData.logo.horizontal.icon ? (
											<img
												src={`${projectData.logo.horizontal.icon}?v=${createGuid()}`}
												crossOrigin="anonymous"
											/>
										) : projectData.logo &&
										projectData.logo.layout &&
										projectData.logo.layout === 'vertical' &&
										projectData.logo.vertical.icon ? (
											<img
												src={`${projectData.logo.vertical.icon}?v=${createGuid()}`}
												crossOrigin="anonymous"
											/>
										) : (
											''
										)}
										<ImageSVG
											id="faviconColorTransformFilter"
											hexColor={projectData.primaryColor}
										/>
									</div>
								</div>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default FaviconPreview;
